define("discourse/plugins/discourse-global-communities/discourse/helpers/update-episode-watched-timestamp", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.updateEpisodePartiallyWatchedTimestamp = _exports.updateEpisodeFullyWatchedTimestamp = void 0;
  const VIDEO_EPISODE_MEDIA_ENTITY_TYPE = "VIDEO_EPISODE";
  const DISCOURSE_CONTENT_PROVIDER = "DISCOURSE";

  /**
   * Mark episode as partially watched in the AGC MediaService database.
   *
   * @param {string} topicId The topic/content ID of the episode.
   */
  const updateEpisodePartiallyWatchedTimestamp = async topicId => {
    updateEpisodeWatchedTimestamp(topicId, false);
  };

  /**
   * Mark episode as fully watched in the AGC MediaService database.
   *
   * @param {string} topicId The topic/content ID of the episode.
   */
  _exports.updateEpisodePartiallyWatchedTimestamp = updateEpisodePartiallyWatchedTimestamp;
  const updateEpisodeFullyWatchedTimestamp = async topicId => {
    updateEpisodeWatchedTimestamp(topicId, true);
  };

  /**
   * POST an episode timestamp update to the OMS
   * UpdateMediaWatchedTimestamp endpoint through a Discourse proxy.
   * https://code.amazon.com/packages/OceanMediaServiceModel/blobs/mainline/--/model/operations/v1/mediaWatchedTimestamps/updateMediaWatchedTimestamps.smithy
   *
   * @param {string} topicId
   * @param {bool} isFullyWatched
   */
  _exports.updateEpisodeFullyWatchedTimestamp = updateEpisodeFullyWatchedTimestamp;
  const updateEpisodeWatchedTimestamp = async (topicId, isFullyWatched) => {
    const body = {
      contentProvider: DISCOURSE_CONTENT_PROVIDER,
      contentId: topicId,
      mediaEntityType: VIDEO_EPISODE_MEDIA_ENTITY_TYPE,
      isFullyWatched
    };
    (0, _ajax.ajax)("/proxy/media/updateMediaWatchedTimestamp.json", {
      contentType: "application/json",
      dataType: "json",
      type: "POST",
      data: JSON.stringify(body)
    });
  };
});