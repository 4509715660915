define("discourse/plugins/discourse-global-communities/discourse/templates/admin/plugins-agc", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <AdminNav>
    {{#each this.model as |tab|}}
      <NavItem @route={{tab.route}} @label={{tab.label}} />
    {{/each}}
  </AdminNav>
  
  <div class="agc__admin-container">
    {{outlet}}
  </div>
  */
  {
    "id": "NjVPmsSB",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"model\"]]],null]],null],null,[[[1,\"    \"],[8,[39,3],null,[[\"@route\",\"@label\"],[[30,1,[\"route\"]],[30,1,[\"label\"]]]],null],[1,\"\\n\"]],[1]],null]],[]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"agc__admin-container\"],[12],[1,\"\\n  \"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n\"],[13]],[\"tab\"],false,[\"admin-nav\",\"each\",\"-track-array\",\"nav-item\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/admin/plugins-agc.hbs",
    "isStrictMode": false
  });
});