define("discourse/plugins/discourse-global-communities/discourse/routes/admin-plugins-agc-emails-notifications-summary", ["exports", "discourse/routes/discourse", "discourse/plugins/discourse-global-communities/discourse/models/email-preview-notifications-summary"], function (_exports, _discourse, _emailPreviewNotificationsSummary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminEmailPreviewDigestRoute extends _discourse.default {
    model() {
      return _emailPreviewNotificationsSummary.default.findNotificationsSummary(this.currentUser.get("username"));
    }
    afterModel(model) {
      const controller = this.controllerFor("adminPluginsAgcEmailsNotificationsSummary");
      controller.setProperties({
        model,
        username: this.currentUser.get("username"),
        lastSeen: (0, _emailPreviewNotificationsSummary.oneWeekAgo)(),
        lastAttempt: (0, _emailPreviewNotificationsSummary.oneWeekAgo)(),
        frequency: "weekly",
        showHtml: true
      });
    }
  }
  _exports.default = AdminEmailPreviewDigestRoute;
});