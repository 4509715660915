define("discourse/plugins/discourse-global-communities/discourse/components/modal/deprioritize-topic", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/modifier", "@ember/object", "truth-helpers", "discourse/components/d-button", "discourse/components/d-modal", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _modifier, _object, _truthHelpers, _dButton, _dModal, _ajax, _ajaxError, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DeprioritizeTopicModal extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "initialValue", [_tracking.tracked], function () {
      return this.args.model.agc_deprioritized;
    }))();
    #initialValue = (() => (dt7948.i(this, "initialValue"), void 0))();
    get isSaveDisabled() {
      return this.args.model.get("agc_deprioritized") === this.initialValue;
    }
    save() {
      const data = {
        topic_id: this.args.model.id,
        deprioritized: this.args.model.agc_deprioritized
      };
      (0, _ajax.ajax)("/deprioritize-topic.json", {
        type: "POST",
        data
      }).then(() => {
        this.args.closeModal();
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_2 = (() => dt7948.n(this.prototype, "save", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          @closeModal={{@closeModal}}
          @title={{i18n "agc.topic_visibility"}}
          class="agc-deprioritize-topic-modal"
        >
          <:body>
            <div class="agc-deprioritize-topic-container">
              <div class="agc-deprioritize-topic-button-group">
                <label>
                  <input
                    type="radio"
                    class="agc-deprioritize-topic-radio-button__input"
                    checked={{@model.agc_deprioritized}}
                    {{on "change" (fn (mut @model.agc_deprioritized) true)}}
                  />
                  {{i18n "discourse_gc.deprioritize_topic"}}
                </label>
              </div>
              <div class="agc-deprioritize-topic-button-group">
                <label>
                  <input
                    type="radio"
                    class="agc-deprioritize-topic-radio-button__input"
                    checked={{not @model.agc_deprioritized}}
                    {{on "change" (fn (mut @model.agc_deprioritized) false)}}
                  />
                  {{i18n "discourse_gc.reprioritize_topic"}}
                </label>
              </div>
            </div>
          </:body>
    
          <:footer>
            <div class="modal-footer">
              <DButton
                class="btn-primary agc-deprioritize-save-btn"
                @action={{this.save}}
                @label="discourse_gc.save"
                @disabled={{this.isSaveDisabled}}
              />
              <DButton @action={{@closeModal}} @label="discourse_gc.cancel" />
            </div>
          </:footer>
        </DModal>
      
    */
    {
      "id": "Ed/kECfR",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"agc-deprioritize-topic-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[32,1],[\"agc.topic_visibility\"],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"agc-deprioritize-topic-container\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"agc-deprioritize-topic-button-group\"],[12],[1,\"\\n            \"],[10,\"label\"],[12],[1,\"\\n              \"],[11,\"input\"],[24,0,\"agc-deprioritize-topic-radio-button__input\"],[16,\"checked\",[30,2,[\"agc_deprioritized\"]]],[24,4,\"radio\"],[4,[32,2],[\"change\",[28,[32,3],[[28,[31,0],[[30,2,[\"agc_deprioritized\"]]],null],true],null]],null],[12],[13],[1,\"\\n              \"],[1,[28,[32,1],[\"discourse_gc.deprioritize_topic\"],null]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"agc-deprioritize-topic-button-group\"],[12],[1,\"\\n            \"],[10,\"label\"],[12],[1,\"\\n              \"],[11,\"input\"],[24,0,\"agc-deprioritize-topic-radio-button__input\"],[16,\"checked\",[28,[32,4],[[30,2,[\"agc_deprioritized\"]]],null]],[24,4,\"radio\"],[4,[32,2],[\"change\",[28,[32,3],[[28,[31,0],[[30,2,[\"agc_deprioritized\"]]],null],false],null]],null],[12],[13],[1,\"\\n              \"],[1,[28,[32,1],[\"discourse_gc.reprioritize_topic\"],null]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[10,0],[14,0,\"modal-footer\"],[12],[1,\"\\n          \"],[8,[32,5],[[24,0,\"btn-primary agc-deprioritize-save-btn\"]],[[\"@action\",\"@label\",\"@disabled\"],[[30,0,[\"save\"]],\"discourse_gc.save\",[30,0,[\"isSaveDisabled\"]]]],null],[1,\"\\n          \"],[8,[32,5],null,[[\"@action\",\"@label\"],[[30,1],\"discourse_gc.cancel\"]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@model\"],false,[\"mut\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/modal/deprioritize-topic.js",
      "scope": () => [_dModal.default, _i18n.default, _modifier.on, _helper.fn, _truthHelpers.not, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = DeprioritizeTopicModal;
});