define("discourse/plugins/discourse-global-communities/discourse/connectors/above-footer/mobile-tab-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(args, context) {
      return context.siteSettings.enable_mobile_tab_bar && context.site.mobileView && !!context.currentUser;
    }
  };
});