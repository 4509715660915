define("discourse/plugins/discourse-global-communities/discourse/components/agc/mobile-tab-bar", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/owner", "@ember/service", "@ember/template", "discourse/lib/url", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _controller, _object, _owner, _service, _template, _url, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.highlight = highlight;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="d-tab-bar-theme">
    {{#each this.tabs as |tab|}}
      <Agc::MobileTabBar::TabButton
        @tab={{tab}}
        @onClick={{action "navigate" tab}}
        @width={{this.width}}
      />
    {{/each}}
  
    {{#if this.showExploreDropdown}}
      <div
        role="button"
        class="agc-mobile-drop-bg"
        onclick={{action "dismissDropdown"}}
      ></div>
      <div class="agc-mobile-drop-contents agc-mobile-drop-explore">
        {{#each this.exploreItems as |item|}}
          <a
            role="button"
            data-destination={{item.destination}}
            onclick={{action "navigate" item}}
            type="button"
          >
            {{item.rawLabel}}
          </a>
        {{/each}}
      </div>
  
    {{/if}}
  </div>
  */
  {
    "id": "mcq/Lgiy",
    "block": "[[[10,0],[14,0,\"d-tab-bar-theme\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"tabs\"]]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@tab\",\"@onClick\",\"@width\"],[[30,1],[28,[37,3],[[30,0],\"navigate\",[30,1]],null],[30,0,[\"width\"]]]],null],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[41,[30,0,[\"showExploreDropdown\"]],[[[1,\"    \"],[10,0],[14,\"role\",\"button\"],[14,0,\"agc-mobile-drop-bg\"],[15,\"onclick\",[28,[37,3],[[30,0],\"dismissDropdown\"],null]],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"agc-mobile-drop-contents agc-mobile-drop-explore\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"exploreItems\"]]],null]],null],null,[[[1,\"        \"],[10,3],[14,\"role\",\"button\"],[15,\"data-destination\",[30,2,[\"destination\"]]],[15,\"onclick\",[28,[37,3],[[30,0],\"navigate\",[30,2]],null]],[14,4,\"button\"],[12],[1,\"\\n          \"],[1,[30,2,[\"rawLabel\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\\n\"]],[]],null],[13]],[\"tab\",\"item\"],false,[\"each\",\"-track-array\",\"agc/mobile-tab-bar/tab-button\",\"action\",\"if\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/agc/mobile-tab-bar.hbs",
    "isStrictMode": false
  });
  function highlight(destination) {
    const tabElements = document.querySelectorAll(".d-tab-bar-theme .tab");
    tabElements.forEach(element => {
      if (element.dataset.destination === destination) {
        element.classList.add("active");
        return;
      }
      element.classList.remove("active");
    });
  }
  class AgcMobileTabBar extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "circles", [_service.service]))();
    #circles = (() => (dt7948.i(this, "circles"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "currentCircle", [_service.service]))();
    #currentCircle = (() => (dt7948.i(this, "currentCircle"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "application", [_controller.inject]))();
    #application = (() => (dt7948.i(this, "application"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "showExploreDropdown", [_tracking.tracked], function () {
      return false;
    }))();
    #showExploreDropdown = (() => (dt7948.i(this, "showExploreDropdown"), void 0))();
    constructor() {
      super(...arguments);
      this.appEvents.on("page:changed", this, this.highlightSelectedTab);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off("page:changed", this, this.highlightSelectedTab);
    }
    get router() {
      // ideally we should inject service:router above, but some methods used by this component
      // are not available in the service
      // eslint-disable-next-line ember/no-private-routing-service
      return (0, _owner.getOwner)(this).lookup("router:main");
    }
    get exploreItems() {
      const items = [];
      try {
        const exploreDropdownLinks = JSON.parse(this.siteSettings.explore_dropdown_links);
        const categories = this.site.get("categoriesList");
        exploreDropdownLinks.forEach(l => {
          const link = {
            rawLabel: l.text,
            destination: l.url
          };
          if (l.class) {
            link.agcAnalyticsRef = `nav_explore_${l.class.replace(/-/g, "_")}`;
          }

          // If it is a category, try to fill the missing fields using its
          // data. This is an effort to maintain the old behaviour.
          if (l.url.startsWith("/c/") || l.url.startsWith("/tags/c/")) {
            const parts = l.url.split("/").filter(Boolean);
            const categoryId = l.url.startsWith("/tags/c/") ? parseInt(parts[parts.length - 2], 10) : parseInt(parts[parts.length - 1], 10);
            const category = categories.find(c => c.id === categoryId);
            if (!category) {
              return;
            }
            link.destination = category.url;
            if (!link.rawLabel) {
              link.rawLabel = category.name;
            }
            if (!link.agcAnalyticsRef) {
              link.agcAnalyticsRef = `nav_explore_${category.slug.replace(/-/g, "_")}`;
            }
          }
          items.push(link);
        });
      } catch (e) {
        // fail quietly if site setting is empty
      }
      return items;
    }
    get tabs() {
      return this.application.get("agcMobileTabs");
    }
    static #_9 = (() => dt7948.n(this.prototype, "tabs", [_object.computed]))();
    get width() {
      if (this.tabs) {
        const length = this.tabs.length;
        const percentage = length ? 100 / length : length;
        return (0, _template.htmlSafe)(`width: ${percentage}%;`);
      }
    }
    static #_10 = (() => dt7948.n(this.prototype, "width", [(0, _object.computed)("tabs")]))();
    routeToURL(route) {
      // eslint-disable-next-line ember/no-private-routing-service
      const needParams = this.router._routerMicrolib.recognizer.names[route].handlers.some(handler => handler.names.length > 0);
      let url;
      if (needParams) {
        // assume the param it needs is username... very difficult to guess
        // the params correctly let alone passing the correct data
        url = this.router.generate(route, {
          username: this.currentUser.username
        });
      } else {
        url = this.router.generate(route);
      }
      return url;
    }
    compareURLs(url1, url2) {
      if (url1 === url2) {
        return true;
      }
      return url1 && url2 && url1.replace(/(\?|#).*/g, "") === url2.replace(/(\?|#).*/g, "");
    }
    compareRoutes(route1, route2) {
      return route1 === route2.replace(".index", "");
    }

    // Because the program-pillars base route redirects to a region specific route,
    // we need to handle route matching separately.
    isProgramPillarsRoute(data, tab) {
      const programPillarsRoute = "program-pillars";
      const isProgramPillarsRoute = data.currentRouteName.includes(programPillarsRoute);
      return isProgramPillarsRoute && tab.destination.includes(programPillarsRoute);
    }
    highlightSelectedTab(data) {
      const selectedTab = this.tabs.find(tab => {
        return !this.router.hasRoute(tab.destination) ? this.compareURLs(tab.destination, data.url) || this.isProgramPillarsRoute(data, tab) : this.compareRoutes(tab.destination, data.currentRouteName) && this.compareURLs(this.routeToURL(tab.destination), data.url);
      });
      if (selectedTab) {
        highlight(selectedTab.destination);
      } else {
        highlight("#none"); // reset
      }
    }
    navigate(tab) {
      let destination = tab.destination;
      if (destination.indexOf("drop-") !== -1) {
        highlight(tab.destination);
        if (destination.includes("explore")) {
          this.showExploreDropdown = !this.showExploreDropdown;
          if (!this.showExploreDropdown) {
            this.dismissDropdown();
          }
        }
        return false;
      } else {
        this.dismissDropdown();
      }
      if (destination === "toggleNotificationsMenu") {
        if (this.currentUser.agcFeatureFlags["enhanced_notifications_enabled"]) {
          destination = "/u/" + this.currentUser.username + "/notifications";
        } else {
          if (this.appEvents.has("header:toggle-notifications-menu")) {
            this.appEvents.trigger("header:toggle-notifications-menu");
            return false;
          } else {
            destination = "/u/" + this.currentUser.username + "/notifications";
          }
        }
      }
      if (destination.indexOf("new-topic") !== -1) {
        const container = (0, _owner.getOwner)(this);
        if (container.lookup("service:current-circle")?.viewingCircle) {
          // if the post button is clicked inside a circle page
          container.lookup("route:circle").send("clickCreateTopicButton");
        } else {
          container.lookup("service:composer").openNewTopic({
            category: container.lookup("service:router").currentRoute?.attributes?.category
          });
        }
        return false;
      }
      if (tab.agcAnalyticsRef) {
        window.localStorage.lastAgcAnalyticsRef = tab.agcAnalyticsRef;
      }
      let url = destination;

      // the router service does not have a method to check if a route exists
      // so we have to check if the route desired route is registered
      //if (getOwner(this).lookup("route:" + destination)) {
      if (this.router.hasRoute(destination)) {
        url = this.routeToURL(destination);
      }
      _url.default.routeTo(url);
      window.scrollTo(0, 0);

      // highlight tab if hitting on currently active tab
      // this is needed due to "fake" tabs (explore, post)
      if (destination === this.router.currentRouteName.replace(".index", "") || destination === this.router.currentURL) {
        highlight(destination);
      }
    }
    static #_11 = (() => dt7948.n(this.prototype, "navigate", [_object.action]))();
    dismissDropdown() {
      highlight(this.router.currentRouteName);
      this.showExploreDropdown = false;
    }
    static #_12 = (() => dt7948.n(this.prototype, "dismissDropdown", [_object.action]))();
  }
  _exports.default = AgcMobileTabBar;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AgcMobileTabBar);
});