define("discourse/plugins/discourse-global-communities/discourse/widgets/tv-episode-widget", ["virtual-dom", "discourse/widgets/raw-html", "discourse/widgets/widget", "discourse-i18n"], function (_virtualDom, _rawHtml, _widget, _discourseI18n) {
  "use strict";

  (0, _widget.createWidget)("tv-episode", {
    tagName: "div.episode",
    html(attrs) {
      const episode = attrs.episode;
      return (0, _virtualDom.h)("div.tv-episode-container", [(0, _virtualDom.h)("a.episode-thumbnail", {
        attributes: {
          href: episode.url
        }
      }, [episode.horizontal_image_url ? (0, _virtualDom.h)("img.thumbnail-img", {
        attributes: {
          src: episode.horizontal_image_url
        }
      }) : (0, _virtualDom.h)("div.placeholder")]), (0, _virtualDom.h)("div.episode-details", [(0, _virtualDom.h)("a", {
        attributes: {
          href: episode.url
        }
      }, [(0, _virtualDom.h)("h5.episode-title", episode.title)]), this.attach("unwatched-label", {
        topic: episode
      }), this.attach("topic-list-actions", {
        topic: episode
      })]), (0, _virtualDom.h)("a", {
        attributes: {
          href: episode.url
        }
      }, new _rawHtml.default({
        html: `<p class="episode-description">${episode.excerpt}</p>`
      }))]);
    }
  });
  (0, _widget.createWidget)("unwatched-label", {
    html(attrs) {
      if (!attrs.topic.watched) {
        return (0, _virtualDom.h)("div.unwatched", _discourseI18n.default.t("agc.tv.unwatched"));
      }
    }
  });
});