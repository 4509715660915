define("discourse/plugins/discourse-global-communities/discourse/components/agc/header/explore-dropdown", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/modifiers/close-on-click-outside", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/components/agc/header/link", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _object, _service, _dButton, _closeOnClickOutside, _discourseI18n, _link, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcHeaderExploreDropdown extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "dropdownVisible", [_tracking.tracked], function () {
      return false;
    }))();
    #dropdownVisible = (() => (dt7948.i(this, "dropdownVisible"), void 0))();
    get items() {
      const exploreDropdownLinks = JSON.parse(this.siteSettings.explore_dropdown_links);
      const categories = this.site.get("categoriesList");
      const locale = _discourseI18n.default.currentLocale();
      return exploreDropdownLinks?.map(l => {
        let rawLabel;
        if (locale === "de") {
          rawLabel = l.text_de;
        }
        if (locale === "fr") {
          rawLabel = l.text_fr;
        }
        if (!rawLabel) {
          rawLabel = l.text;
        }
        const link = {
          rawLabel,
          href: l.url
        };
        if (l.class) {
          link.className = l.class;
          link.agcAnalyticsRef = `nav_explore_${l.class.replace(/-/g, "_")}`;
        }
        // If it is a category, try to fill the missing fields using its
        // data. This is an effort to maintain the old behaviour.
        if (l.url.startsWith("/c/") || l.url.startsWith("/tags/c/")) {
          const parts = l.url.split("/").filter(Boolean);
          const categoryId = l.url.startsWith("/tags/c/") ? parseInt(parts[parts.length - 2], 10) : parseInt(parts[parts.length - 1], 10);
          const category = categories.find(c => c.id === categoryId);
          if (!category) {
            return;
          }
          link.href = category.url;
          if (!link.rawLabel) {
            link.rawLabel = category.name;
          }
          if (!link.className) {
            link.className = `agc-f-${category.slug}`;
          }
          if (!link.agcAnalyticsRef) {
            link.agcAnalyticsRef = `nav_explore_${category.slug.replace(/-/g, "_")}`;
          }
        }
        return link;
      });
    }
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    }
    static #_4 = (() => dt7948.n(this.prototype, "toggleDropdown", [_object.action]))();
    clickOutside() {
      this.toggleDropdown();
    }
    static #_5 = (() => dt7948.n(this.prototype, "clickOutside", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="agc-dropdown explore {{if this.dropdownVisible 'active'}}">
          <DButton
            class="btn-flat"
            @label="agc.navs.explore"
            @icon="chevron-down"
            @action={{this.toggleDropdown}}
          />
          {{#if this.dropdownVisible}}
            <ul
              class="agc-dropdown-links agc-explore-dropdown-links"
              {{closeOnClickOutside
                this.clickOutside
                (hash
                  targetSelector=".user-menu-panel"
                  secondaryTargetSelector=".user-menu-panel"
                )
              }}
            >
              {{#each this.items as |item|}}
                <li>
                  <AgcHeaderLink
                    @label={{item.rawLabel}}
                    @href={{item.href}}
                    @className={{item.className}}
                    @agcAnalyticsRef={{item.agcAnalyticsRef}}
                    @onClick={{this.clickOutside}}
                  />
                </li>
              {{/each}}
            </ul>
          {{/if}}
        </div>
      
    */
    {
      "id": "PX9ylto4",
      "block": "[[[1,\"\\n    \"],[10,0],[15,0,[29,[\"agc-dropdown explore \",[52,[30,0,[\"dropdownVisible\"]],\"active\"]]]],[12],[1,\"\\n      \"],[8,[32,0],[[24,0,\"btn-flat\"]],[[\"@label\",\"@icon\",\"@action\"],[\"agc.navs.explore\",\"chevron-down\",[30,0,[\"toggleDropdown\"]]]],null],[1,\"\\n\"],[41,[30,0,[\"dropdownVisible\"]],[[[1,\"        \"],[11,\"ul\"],[24,0,\"agc-dropdown-links agc-explore-dropdown-links\"],[4,[32,1],[[30,0,[\"clickOutside\"]],[28,[32,2],null,[[\"targetSelector\",\"secondaryTargetSelector\"],[\".user-menu-panel\",\".user-menu-panel\"]]]],null],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"items\"]]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[12],[1,\"\\n              \"],[8,[32,3],null,[[\"@label\",\"@href\",\"@className\",\"@agcAnalyticsRef\",\"@onClick\"],[[30,1,[\"rawLabel\"]],[30,1,[\"href\"]],[30,1,[\"className\"]],[30,1,[\"agcAnalyticsRef\"]],[30,0,[\"clickOutside\"]]]],null],[1,\"\\n            \"],[13],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"item\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/header/explore-dropdown.js",
      "scope": () => [_dButton.default, _closeOnClickOutside.default, _helper.hash, _link.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcHeaderExploreDropdown;
});