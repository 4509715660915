define("discourse/plugins/discourse-global-communities/discourse/initializers/chat", ["exports", "@ember/service", "discourse/lib/plugin-api", "discourse-common/lib/get-url", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/components/agc/chat/drawer/start-chatting"], function (_exports, _service, _pluginApi, _getUrl, _discourseI18n, _startChatting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "agc-base-component";
  var _default = _exports.default = {
    name: "agc-chat",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      (0, _pluginApi.withPluginApi)("0.8.36", api => {
        if (siteSettings?.chat_enabled) {
          addBodyClass();
          replaceIcons(api);
          hideChatComposerSecondaryActions(api);
          hidePublicChannelsList(api);
          tweakChatRedirecting(api);
          addNewMessageToProfileMenu(api);
          trackAnalytics(api);
        }
      });
    }
  };
  function addBodyClass() {
    // the chat plugin adds a class to the body to indicate that chat is enabled but the class is not
    // added if the user disables chat in the preferences.
    // since some customizations may depend exclusively on the fact that the chat plugin itself is
    // enabled we add a customized class to the body to indicate that the chat plugin is enabled for
    // agc cus
    document.body.classList.add("agc-chat-enabled");
  }
  function replaceIcons(api) {
    const icon = "chat-squid";
    api.replaceIcon("d-chat", icon);
  }
  function hideChatComposerSecondaryActions(api) {
    api.removeChatComposerSecondaryActions("copyLink", "select");
  }
  function hidePublicChannelsList(api) {
    // prevent the public channels list from being displayed
    api.modifyClass("component:channels-list", {
      pluginId: PLUGIN_ID,
      get displayPublicChannels() {
        return false;
      }
    });
  }
  function tweakChatRedirecting(api) {
    api.modifyClass("route:chat", {
      pluginId: PLUGIN_ID,
      appEvents: (0, _service.service)(),
      chat: (0, _service.service)(),
      chatChannelsManager: (0, _service.service)(),
      chatStateManager: (0, _service.service)(),
      currentUser: (0, _service.service)(),
      router: (0, _service.service)(),
      // AGC wants to display a call to action page the user has disabled chat in the preferences,
      // or when the user does not have any chat conversation active yet. We will intercept the calls
      // to the chat pages if the user disabled chat or when there are no chat channels and redirect
      // to the page containing the call to action
      async beforeModel(transition) {
        if (!this.currentUser.has_chat_enabled) {
          this.router.replaceWith("start-chatting");
          return;
        }
        await this.chat.loadChannels();
        if (this.chatChannelsManager.channels.length === 0) {
          if (transition.from &&
          // don't intercept when directly loading chat
          this.chatStateManager.isDrawerPreferred) {
            transition.abort();
            let url = this.router.urlFor("start-chatting");
            this.appEvents.trigger("chat:open-url", url);
          } else {
            this.router.replaceWith("start-chatting");
          }
          return;
        }
        this._super(...arguments);
      }
    });
    api.modifyClass("route:chat.index", {
      pluginId: PLUGIN_ID,
      chatChannelsManager: (0, _service.service)(),
      router: (0, _service.service)(),
      site: (0, _service.service)(),
      // Since AGC wants to disable chat channels we need to get an ideal DM channel to redirect to.
      // This method adapts the algorithm from `getIdealFirstChannelId` on service:chat and removes
      // the public channel options from the choice that the algorithm makes.
      getIdealDMChannelId() {
        // Best is a DM channel with unread messages.
        // Failing that the first DM channel.
        let dmChannelWithUnread, dmChannel;
        this.chatChannelsManager.channels.forEach(channel => {
          if (channel.isDirectMessageChannel) {
            if (!dmChannelWithUnread && channel.tracking.unreadCount > 0) {
              dmChannelWithUnread = channel.id;
            } else if (!dmChannel) {
              dmChannel = channel.id;
            }
          }
        });
        return dmChannelWithUnread || dmChannel;
      },
      // AGC wants to disable chat channels. We can't redirect to a public channel available
      redirect() {
        // Always want the channel index on mobile.
        if (this.site.mobileView) {
          return;
        }

        // On desktop as AGC wants to disable chat channels, we'll try to redirect to a DM channel
        // available
        const id = this.getIdealDMChannelId();
        if (id) {
          return this.chatChannelsManager.find(id).then(c => {
            return this.router.transitionTo("chat.channel", ...c.routeModels);
          });
        }
      }
    });
    api.modifyClass("service:chat-drawer-router", {
      pluginId: PLUGIN_ID,
      stateFor(route) {
        if (route.name === "start-chatting") {
          const drawerRouter = {
            name: _startChatting.default
          };
          this.drawerRoute = drawerRouter;
          this.component = drawerRouter.name;
          return;
        }
        return this._super(...arguments);
      }
    });
  }
  function addNewMessageToProfileMenu(api) {
    const currentUser = api.getCurrentUser();
    if (currentUser?.admin) {
      api.addQuickAccessProfileItem({
        className: "start-new-message",
        icon: "envelope",
        content: _discourseI18n.default.t("user.new_private_message"),
        href: (0, _getUrl.default)("/new-message")
      });
    }
  }
  function trackAnalytics(api) {
    const agcAnalyticsApi = api.agcAnalytics;

    // test if the analytics plugin api is available
    if (agcAnalyticsApi) {
      agcAnalyticsApi.registerClickAnalytics(".agc-header-icon.chat", {
        eventType: "chat:clicked-chat-header-icon",
        eventData: element => {
          const unreadIndicatorNumber = element.querySelector(".chat-channel-unread-indicator__number")?.innerText;
          return {
            unread_messages_count: unreadIndicatorNumber ? parseInt(unreadIndicatorNumber, 10) : 0
          };
        }
      });
      agcAnalyticsApi.registerClickAnalytics(".btn.open-new-message-btn", {
        eventType: "chat:clicked-add-chat-btn"
      });
      agcAnalyticsApi.registerClickAnalytics(".btn.chat-drawer-header__full-screen-btn", {
        eventType: "chat:clicked-chat-full-screen-btn"
      });
      agcAnalyticsApi.registerClickAnalytics(".btn.chat-drawer-header__close-btn", {
        eventType: "chat:clicked-close-drawer-btn"
      });
      agcAnalyticsApi.registerClickAnalytics(".chat-full-page-header__right-actions .btn.open-drawer-btn", {
        eventType: "chat:clicked-open-drawer-btn"
      });
      agcAnalyticsApi.registerClickAnalytics(".chat-composer-button.-send", {
        eventType: "chat:clicked-composer-send-btn"
      });
      agcAnalyticsApi.registerClickAnalytics(".user-nav__preferences-chat > a", () => {
        localStorage.lastAgcAnalyticsRef = "user_nav_preferences:chat";
      });

      // Capture user card chat btn clicks
      agcAnalyticsApi.registerClickAnalytics(".user-card-below-message-button-outlet.chat-button", {
        eventType: "chat:clicked-user-card-chat-btn",
        eventData: element => {
          const card = element.closest("#user-card");
          let username;
          card.classList.forEach(className => {
            if (className.startsWith("user-card-")) {
              return username = className.replace("user-card-", "");
            }
          });
          return {
            username
          };
        }
      });

      // Capture user profile chat btn clicks
      agcAnalyticsApi.registerClickAnalytics(".user-profile-controls-outlet.chat-button", {
        eventType: "chat:clicked-user-profile-chat-btn",
        eventData: () => {
          return {
            // pathname is /u/milton_kshlerin/activity. Splitting and accessing [2] gets username
            username: window.location.pathname.split("/")[2]
          };
        }
      });

      // Capture members directory chat btn clicks
      agcAnalyticsApi.registerClickAnalytics(".member-link .chat-direct-message-btn", {
        eventType: "chat:clicked-directory-member-chat-btn",
        eventData: element => {
          return {
            username: element.parentElement.dataset.username
          };
        }
      });
    }
    api.addChatDrawerStateCallback(state => {
      api.container.lookup("service:app-events").trigger("agc-analytics:log-event", "chat:changed-chat-drawer-state", state);
    });
    api.modifyClass("route:chat.channel", {
      pluginId: PLUGIN_ID,
      appEvents: (0, _service.service)(),
      afterModel(model, transition) {
        const result = this._super(...arguments);
        if (!transition.isAborted) {
          this.appEvents.trigger("agc-analytics:log-event", "chat:opened-channel", {
            channel_id: model?.id,
            users: model?.chatable?.users?.map(u => ({
              id: u.id,
              username: u.username
            }))
          });
        }
        return result;
      }
    });
  }
});