define("discourse/plugins/discourse-global-communities/discourse/services/agc-see-more-tracking", ["exports", "@ember/service", "discourse/lib/ajax", "discourse/plugins/discourse-global-communities/discourse/lib/agc-user-session"], function (_exports, _service, _ajax, _agcUserSession) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcSeeMoreTrackingService extends _service.default {
    timers = {};
    startTimerFor(topicId) {
      this.timers[topicId] = {
        ms: this.timers[topicId]?.ms || 0,
        timestamp: new Date()
      };
    }
    stopTimerFor(topicId) {
      if (!this.timers[topicId]) {
        return;
      }
      this.timers[topicId] = {
        ms: this.timers[topicId].ms + (new Date() - this.timers[topicId].timestamp),
        timestamp: null
      };
    }

    // When we flush the events, we may have timers still running (i.e. user pressed "show more" and didn't
    // scroll away so timer is still running when page is unloaded or page changed.
    _stopRunningTimers() {
      for (const [topicId, timingDetails] of Object.entries(this.timers)) {
        if (timingDetails.timestamp) {
          this.stopTimerFor(topicId);
        }
      }
    }
    async flush() {
      if (!Object.keys(this.timers).length) {
        return;
      }
      try {
        this._stopRunningTimers();
        const agcUserSession = _agcUserSession.default.current();
        const response = await (0, _ajax.ajax)("/agc-timings.json", {
          data: {
            agc_transit_id: agcUserSession._transitId(),
            agc_session_id: agcUserSession._sessionId(),
            timings: this._transformedTimings
          },
          type: "POST",
          cache: false
        });
        agcUserSession.updateIds(response.session_id, response.transit_id);
      } catch {} finally {
        this.timers = {};
      }
    }
    get _transformedTimings() {
      const transformed = {};
      for (const [topicId, timingDetails] of Object.entries(this.timers)) {
        // We are faking the post timing structure here. The 1 refers to the first post.
        transformed[topicId] = {
          1: timingDetails.ms
        };
      }
      return transformed;
    }
  }
  _exports.default = AgcSeeMoreTrackingService;
});