define("discourse/plugins/discourse-global-communities/discourse/components/tv-hero", ["exports", "@glimmer/component", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "@ember/template", "discourse-common/helpers/i18n", "discourse-common/utils/decorators", "discourse/plugins/discourse-global-communities/discourse/helpers/videojs-initializer", "discourse/plugins/discourse-global-communities/discourse/components/agc-topic-list-actions", "@ember/component", "@ember/template-factory"], function (_exports, _component, _didInsert, _service, _template, _i18n, _decorators, _videojsInitializer, _agcTopicListActions, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TvHero extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    initializeVideoJs(container) {
      const videoEl = container.querySelector(".tv-hero-video-container video");
      if (videoEl) {
        (0, _videojsInitializer.default)(videoEl, this.appEvents);
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "initializeVideoJs", [_decorators.bind]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div {{didInsert this.initializeVideoJs}} class="tv-hero">
          {{#if @model.video_html}}
            <article
              data-post-id={{@model.post_id}}
              data-topic-id={{@model.id}}
              class="tv-video-container tv-hero-video-container"
            >
              {{htmlSafe @model.video_html}}
            </article>
          {{else}}
            {{#if @model.horizontal_image_url}}
              <img class="tv-image" src={{@model.horizontal_image_url}} />
            {{/if}}
          {{/if}}
    
          <div class="hero-bottom">
            <a href={{@model.url}}>
              <h2 class="topic-title">{{htmlSafe @model.fancy_title}}</h2>
              <p class="topic-desc">
                {{htmlSafe @model.excerpt}}
                <span class="read-more">{{i18n "agc.tv.read_more"}}</span>
              </p>
            </a>
            <AgcTopicListActions
              @topic={{@model}}
              @showViews={{true}}
              @hideNotifyButton={{true}}
            />
          </div>
        </div>
      
    */
    {
      "id": "vtJFvbqx",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"tv-hero\"],[4,[32,0],[[30,0,[\"initializeVideoJs\"]]],null],[12],[1,\"\\n\"],[41,[30,1,[\"video_html\"]],[[[1,\"        \"],[10,\"article\"],[15,\"data-post-id\",[30,1,[\"post_id\"]]],[15,\"data-topic-id\",[30,1,[\"id\"]]],[14,0,\"tv-video-container tv-hero-video-container\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[[30,1,[\"video_html\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[41,[30,1,[\"horizontal_image_url\"]],[[[1,\"          \"],[10,\"img\"],[14,0,\"tv-image\"],[15,\"src\",[30,1,[\"horizontal_image_url\"]]],[12],[13],[1,\"\\n\"]],[]],null]],[]]],[1,\"\\n      \"],[10,0],[14,0,\"hero-bottom\"],[12],[1,\"\\n        \"],[10,3],[15,6,[30,1,[\"url\"]]],[12],[1,\"\\n          \"],[10,\"h2\"],[14,0,\"topic-title\"],[12],[1,[28,[32,1],[[30,1,[\"fancy_title\"]]],null]],[13],[1,\"\\n          \"],[10,2],[14,0,\"topic-desc\"],[12],[1,\"\\n            \"],[1,[28,[32,1],[[30,1,[\"excerpt\"]]],null]],[1,\"\\n            \"],[10,1],[14,0,\"read-more\"],[12],[1,[28,[32,2],[\"agc.tv.read_more\"],null]],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[32,3],null,[[\"@topic\",\"@showViews\",\"@hideNotifyButton\"],[[30,1],true,true]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@model\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/tv-hero.js",
      "scope": () => [_didInsert.default, _template.htmlSafe, _i18n.default, _agcTopicListActions.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TvHero;
});