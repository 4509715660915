define("discourse/plugins/discourse-global-communities/discourse/initializers/comment-tagging", ["exports", "discourse/lib/plugin-api", "discourse/widgets/render-glimmer", "@ember/template-factory"], function (_exports, _pluginApi, _renderGlimmer, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    after: "comments",
    name: "comment-tagging",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.36", api => {
        const currentUser = api.getCurrentUser();
        const addCustomSearchToTagChooser = TagChooserSuperclass => {
          return class extends TagChooserSuperclass {
            search(query) {
              if (this.useAgcCustomSearch) {
                const selectedTags = this.value || this.tags || [];
                const data = {
                  q: query,
                  selected_tags: selectedTags,
                  limit: this.siteSettings.max_tag_search_results
                };
                return this.searchTags("/admin/plugins/agc/posts/tags/search.json", data, this._transformJson);
              } else {
                return super.search(query);
              }
            }
          };
        };

        // emit `post_tags_exist_for_non_admin` rum event if post tags exist for non-admins
        api.onAppEvent("topic:current-post-scrolled", () => {
          if (!currentUser?.admin) {
            const topicController = api.container.lookup("controller:topic");
            const posts = topicController?.model?.postStream?.posts;
            const hasPostTags = !!posts?.some(post => post.post_tags?.length > 0);
            if (hasPostTags) {
              window.cwr("recordEvent", {
                type: "post_tags_exist_for_non_admin",
                data: {}
              });
            }
          }
        });
        api.decorateWidget("poster-name:after", dec => {
          const shouldRender = currentUser?.admin && currentUser?.agcFeatureFlags?.agc_comment_tagging_enabled;
          if (dec.attrs?.post_number > 1 && shouldRender) {
            return new _renderGlimmer.default(dec.widget, "div.post-tagging", (0, _templateFactory.createTemplateFactory)(
            /*
              
                                <PostTagging @postId={{@data.postId}} @postTags={{@data.postTags}} />
                                  
            */
            {
              "id": "9r6/pnIg",
              "block": "[[[1,\"\\n                    \"],[8,[39,0],null,[[\"@postId\",\"@postTags\"],[[30,1,[\"postId\"]],[30,1,[\"postTags\"]]]],null],[1,\"\\n                      \"]],[\"@data\"],false,[\"post-tagging\"]]",
              "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/initializers/comment-tagging.js",
              "isStrictMode": false
            }), {
              postId: dec.attrs.id,
              postTags: dec.attrs.post_tags
            });
          }
        });
        api.decorateWidget("post-meta-data:after", dec => {
          const shouldRender = currentUser?.admin && currentUser?.agcFeatureFlags?.agc_comment_tagging_enabled;
          if (dec.attrs?.post_number > 1 && shouldRender) {
            return new _renderGlimmer.default(dec.widget, "div.post-tags", (0, _templateFactory.createTemplateFactory)(
            /*
              <PostTags @postId={{@data.postId}} @postTags={{@data.postTags}} />
            */
            {
              "id": "xs11TQpk",
              "block": "[[[8,[39,0],null,[[\"@postId\",\"@postTags\"],[[30,1,[\"postId\"]],[30,1,[\"postTags\"]]]],null]],[\"@data\"],false,[\"post-tags\"]]",
              "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/initializers/comment-tagging.js",
              "isStrictMode": false
            }), {
              postId: dec.attrs.id,
              postTags: dec.attrs.post_tags
            });
          }
        });
        api.decorateWidget("post-contents:before", dec => {
          const shouldRender = currentUser?.admin && currentUser?.agcFeatureFlags?.agc_comment_tagging_enabled;
          if (shouldRender && dec.attrs?.post_number > 1) {
            return new _renderGlimmer.default(dec.widget, "div.agc-post-selector", (0, _templateFactory.createTemplateFactory)(
            /*
              
                            <PostSelector @postId={{@data.postId}} />
                            
            */
            {
              "id": "iKdQY6x3",
              "block": "[[[1,\"\\n                \"],[8,[39,0],null,[[\"@postId\"],[[30,1,[\"postId\"]]]],null],[1,\"\\n                \"]],[\"@data\"],false,[\"post-selector\"]]",
              "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/initializers/comment-tagging.js",
              "isStrictMode": false
            }), {
              postId: dec.attrs.id
            });
          }
        });
        api.decorateWidget("post-links:after", dec => {
          const shouldRender = currentUser?.admin && currentUser?.agcFeatureFlags?.agc_comment_tagging_enabled;
          if (shouldRender && dec.attrs?.post_number === 1) {
            const allPostIds = dec.attrs?.topic?.regular_post_ids?.filter(postId => postId !== dec.attrs.id);
            return new _renderGlimmer.default(dec.widget, "div.agc-bulk-posts-actions", (0, _templateFactory.createTemplateFactory)(
            /*
              
                            <BulkPostsActionsBar @allPostIds={{@data.allPostIds}}/>
                            
            */
            {
              "id": "N6clvWcg",
              "block": "[[[1,\"\\n                \"],[8,[39,0],null,[[\"@allPostIds\"],[[30,1,[\"allPostIds\"]]]],null],[1,\"\\n                \"]],[\"@data\"],false,[\"bulk-posts-actions-bar\"]]",
              "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/initializers/comment-tagging.js",
              "isStrictMode": false
            }), {
              allPostIds
            });
          }
        });

        // Custom tag choosers to sort tags according to the number of comments they are used on
        api.modifyClass("component:mini-tag-chooser", addCustomSearchToTagChooser);
        api.modifyClass("component:tag-chooser", addCustomSearchToTagChooser);
      });
    }
  };
});