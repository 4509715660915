define("discourse/plugins/discourse-global-communities/discourse/templates/preferences/agc-home-feed", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="control-group controls-dropdown"
    data-setting-name="agc_default_home_feed_sorting"
  >
    <label for="user_agc_default_home_feed_sorting">
      {{i18n "user.agc_preferences.home_feed.default_sorting"}}
    </label>
    <ComboBox
      @valueProperty="value"
      @content={{this.defaultSortingOptions}}
      @value={{this.model.user_option.agc_default_home_feed_sorting}}
      @id="user_agc_default_home_feed_sorting"
      @onChange={{this.onChangeDefaultSorting}}
      @options={{hash
        none="user.agc_preferences.home_feed.default_sorting_options.default"
      }}
    />
  </div>
  
  <SaveControls
    @id="user_agc_preferences_home_feed_save"
    @model={{this.model}}
    @action={{action "save"}}
    @saved={{this.saved}}
  />
  */
  {
    "id": "UFTk3EtD",
    "block": "[[[10,0],[14,0,\"control-group controls-dropdown\"],[14,\"data-setting-name\",\"agc_default_home_feed_sorting\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,\"for\",\"user_agc_default_home_feed_sorting\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"user.agc_preferences.home_feed.default_sorting\"],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,1],null,[[\"@valueProperty\",\"@content\",\"@value\",\"@id\",\"@onChange\",\"@options\"],[\"value\",[30,0,[\"defaultSortingOptions\"]],[30,0,[\"model\",\"user_option\",\"agc_default_home_feed_sorting\"]],\"user_agc_default_home_feed_sorting\",[30,0,[\"onChangeDefaultSorting\"]],[28,[37,2],null,[[\"none\"],[\"user.agc_preferences.home_feed.default_sorting_options.default\"]]]]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@id\",\"@model\",\"@action\",\"@saved\"],[\"user_agc_preferences_home_feed_save\",[30,0,[\"model\"]],[28,[37,4],[[30,0],\"save\"],null],[30,0,[\"saved\"]]]],null]],[],false,[\"i18n\",\"combo-box\",\"hash\",\"save-controls\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/preferences/agc-home-feed.hbs",
    "isStrictMode": false
  });
});