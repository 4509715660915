define("discourse/plugins/discourse-global-communities/discourse/connectors/user-activity-bottom/agc-archived-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(args, context) {
      return !!context.siteSettings.chat_enabled;
    }
  };
});