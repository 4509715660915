define("discourse/plugins/discourse-global-communities/discourse/lib/timeout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.clearTimeoutEx = clearTimeoutEx;
  _exports.setTimeoutEx = setTimeoutEx;
  const MAX_TIMEOUT = 0x7fffffff;
  function setTimeoutEx(opts) {
    if (opts.timeout <= MAX_TIMEOUT) {
      opts.handle = setTimeout(opts.handler, opts.timeout);
      return opts;
    }
    const nextTimeout = Math.min(opts.timeout, MAX_TIMEOUT);
    opts.timeout -= nextTimeout;
    opts.handle = setTimeout(() => setTimeoutEx(opts), nextTimeout);
    return opts;
  }
  function clearTimeoutEx(opts) {
    return clearTimeout(opts.handle);
  }
});