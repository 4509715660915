define("discourse/plugins/discourse-global-communities/discourse/initializers/agc-category-experts-search", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-global-communities/discourse/components/agc/search/category-experts-options"], function (_exports, _pluginApi, _categoryExpertsOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "agc-base-component";
  function initialize(api) {
    const REGEXP_WITH_GUIDE_RESPONSE = /^with:guide_response/gi;
    const REGEXP_WITH_AMAZON_RESPONSE = /^with:amazon_response/gi;
    api.modifyClass("component:search-advanced-options", {
      pluginId: PLUGIN_ID,
      init() {
        this._super(...arguments);
      },
      didReceiveAttrs() {
        this._super(...arguments);
        [{
          regex: REGEXP_WITH_GUIDE_RESPONSE,
          attr: "searchedTerms.withGuideResponse"
        }, {
          regex: REGEXP_WITH_AMAZON_RESPONSE,
          attr: "searchedTerms.withAmazonResponse"
        }].forEach(search => {
          if (this.filterBlocks(search.regex).length !== 0) {
            this.set(search.attr, true);
          }
        });
      },
      updateWithGuideResponse() {
        this._updateCategoryExpertTerm(this.searchedTerms.withGuideResponse, "with:guide_response");
      },
      updateWithAmazonResponse() {
        this._updateCategoryExpertTerm(this.searchedTerms.withAmazonResponse, "with:amazon_response");
      }
    });
    api.renderInOutlet("inside-category-experts-search-fields", _categoryExpertsOptions.default);
  }
  var _default = _exports.default = {
    name: "agc-category-experts-search",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", initialize);
    }
  };
});