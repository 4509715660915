define("discourse/plugins/discourse-global-communities/discourse/components/agc/discovery/hero", ["exports", "@glimmer/component", "discourse/lib/utilities", "discourse/plugins/discourse-global-communities/discourse/components/hero-v2", "@ember/component", "@ember/template-factory"], function (_exports, _component, _utilities, _heroV, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcDiscoveryHero extends _component.default {
    static shouldRender(args, context, owner) {
      return owner.lookup("service:agc-discovery").isHomepage && (0, _utilities.defaultHomepage)() !== "idf";
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <HeroV2 />
      
    */
    {
      "id": "P/maih8p",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,null,null],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/discovery/hero.js",
      "scope": () => [_heroV.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcDiscoveryHero;
});