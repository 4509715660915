define("discourse/plugins/discourse-global-communities/discourse/services/agc-bookmarks", ["exports", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/models/bookmark", "discourse/plugins/discourse-global-communities/discourse/components/agc-toasts/saved-post-toast"], function (_exports, _service, _ajax, _ajaxError, _bookmark, _savedPostToast) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcBookmarksService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "toasts", [_service.service]))();
    #toasts = (() => (dt7948.i(this, "toasts"), void 0))();
    _activeToast;
    bookmarkPost(topic, post) {
      const postId = post ? post.id : topic.topic_first_post_id;
      return (0, _ajax.ajax)(`/bookmarks.json`, {
        type: "POST",
        data: {
          bookmarkable_id: postId,
          bookmarkable_type: "Post"
        }
      }).then(response => {
        if (post) {
          post.set("bookmarked", true);
        }
        topic.set("bookmarked", true);
        topic.bookmarks.pushObject(_bookmark.default.create({
          id: response.id,
          bookmarkable_id: postId,
          bookmarkable_type: "Post"
        }));
        topic.set("bookmarked", true);
        this.showBookmarkToast({
          saved: true,
          firstPost: !post || post.post_number === 1
        });
      }).catch(_ajaxError.popupAjaxError);
    }
    clearBookmarkForPost(post) {
      const bookmark = post.topic.bookmarks.find(b => {
        return b.bookmarkable_id === post.id && b.bookmarkable_type === "Post";
      });
      if (!bookmark) {
        return;
      }
      return this.clearBookmark(bookmark.id).then(() => {
        post.topic.removeBookmark(bookmark.id);
        post.set("bookmarked", false);
        this.showBookmarkToast({
          saved: false,
          firstPost: post.post_number === 1
        });
      });
    }
    clearBookmark(bookmarkId) {
      return (0, _ajax.ajax)(`/bookmarks/${bookmarkId}`, {
        type: "DELETE"
      });
    }
    showBookmarkToast() {
      let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        saved: true,
        firstPost: true
      };
      if (this._activeToast) {
        this.toasts.close(this._activeToast);
      }
      this._activeToast = this.toasts.show({
        duration: 6000,
        autoClose: true,
        forceAutoClose: true,
        class: `agc-toast  saved-post-toast ${opts.saved ? "-success" : "-info"}`,
        component: _savedPostToast.default,
        data: {
          saved: opts.saved,
          firstPost: opts.firstPost
        }
      });
    }
  }
  _exports.default = AgcBookmarksService;
});