define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/user-activity-bottom/agc-archived-messages", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo
    @route="userPrivateMessages"
    data-agc-analytics-ref="user_activity_archived_messages"
  >
    {{i18n "agc.user.archived_messages"}}
  </LinkTo>
  */
  {
    "id": "9++V3kUl",
    "block": "[[[8,[39,0],[[24,\"data-agc-analytics-ref\",\"user_activity_archived_messages\"]],[[\"@route\"],[\"userPrivateMessages\"]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,1],[\"agc.user.archived_messages\"],null]],[1,\"\\n\"]],[]]]]]],[],false,[\"link-to\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/user-activity-bottom/agc-archived-messages.hbs",
    "isStrictMode": false
  });
});