define("discourse/plugins/discourse-global-communities/lib/discourse-markdown/media-no-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  // same as videoHTML, audioHTML in discourse-markdown-it
  // but with the addition of controlslist="nodownload"

  function videoNoDownloadHTML(token, opts) {
    const src = token.attrGet("src");
    const origSrc = token.attrGet("data-orig-src");
    const preloadType = opts.secureMedia ? "none" : "metadata";
    return `<div class="video-container">
    <video width="100%" height="100%" preload="${preloadType}" controls controlslist="nodownload">
      <source src="${src}" data-orig-src="${origSrc}">
      <a href="${src}">${src}</a>
    </video>
  </div>`;
  }
  function audioNoDownloadHTML(token, opts) {
    const src = token.attrGet("src");
    const origSrc = token.attrGet("data-orig-src");
    const preloadType = opts.secureMedia ? "none" : "metadata";
    const dataOrigSrcAttr = origSrc !== null ? `data-orig-src="${origSrc}"` : "";
    return `<audio preload="${preloadType}" controls controlslist="nodownload">
    <source src="${src}" ${dataOrigSrcAttr}>
    <a href="${src}">${src}</a>
  </audio>`;
  }
  function setup(helper) {
    helper.allowList(["audio[controlslist]", "video[controlslist]"]);
    helper.registerPlugin(md => {
      let defaultRender = md.renderer.rules.image;
      md.renderer.rules.image = function (tokens, idx, options, env, slf) {
        const token = tokens[idx];
        const alt = slf.renderInlineAsText(token.children, options, env);
        const split = alt.split("|");
        if (split[1] === "video") {
          return videoNoDownloadHTML(token, {});
        } else if (split[1] === "audio") {
          return audioNoDownloadHTML(token, {});
        }
        return defaultRender(tokens, idx, options, env, slf);
      };
    });
  }
});