define("discourse/plugins/discourse-global-communities/discourse/templates/explore", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="explore-wrapper">
    {{#if model.categories.length}}
      <div class="directory-body">
        {{#each model.categories as |category|}}
          {{category-title-link category=category}}
        {{/each}}
      </div>
    {{else}}
      <div class="no-categories">
        {{i18n "agc.explore.no_categories"}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "HJdqo1WB",
    "block": "[[[10,0],[14,0,\"explore-wrapper\"],[12],[1,\"\\n\"],[41,[30,0,[\"model\",\"categories\",\"length\"]],[[[1,\"    \"],[10,0],[14,0,\"directory-body\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"model\",\"categories\"]]],null]],null],null,[[[1,\"        \"],[1,[28,[35,3],null,[[\"category\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"no-categories\"],[12],[1,\"\\n      \"],[1,[28,[35,4],[\"agc.explore.no_categories\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-global-communities/discourse/templates/explore.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/discourse-global-communities/discourse/templates/explore.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"category\"],false,[\"if\",\"each\",\"-track-array\",\"category-title-link\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/explore.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});