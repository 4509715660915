define("discourse/plugins/discourse-global-communities/discourse/config/streamline-posting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.streamlinePostingConfigs = void 0;
  /**
   * Config values for streamline posting
   */
  const streamlinePostingConfigs = _exports.streamlinePostingConfigs = {
    // Toolbar formatting button order
    buttonOrder: {
      bold: 1,
      italic: 2,
      bullet: 3,
      list: 4,
      blockquote: 5,
      quote: 6,
      emoji: 7,
      media: 8,
      upload: 9,
      link: 10,
      poll: 11
    }
  };
});