define("discourse/plugins/discourse-global-communities/discourse/routes/admin-plugins-agc-index", ["exports", "@ember/service", "discourse/routes/discourse", "discourse/plugins/discourse-global-communities/discourse/routes/admin-plugins-agc"], function (_exports, _service, _discourse, _adminPluginsAgc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsAgcRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    beforeModel() {
      this.router.transitionTo(_adminPluginsAgc.TABS[0].route);
    }
  }
  _exports.default = AdminPluginsAgcRoute;
});