define("discourse/plugins/discourse-global-communities/discourse/feature-flags/home-feed-user-option-default-sorting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HomeFeedUserOptionDefaultSorting {
    static enabledSetting = "enable_user_option_default_sorting";
    static groupsSetting = "enable_user_option_default_sorting_groups";
    static afterEnabled(api) {
      const DEFAULT_SORTING = "agc_default_home_feed_sorting";
      api.addSaveableUserOptionField(DEFAULT_SORTING);
    }
  }
  _exports.default = HomeFeedUserOptionDefaultSorting;
});