define("discourse/plugins/discourse-global-communities/discourse/connectors/before-docs-search/banner", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(args, context) {
      return !(0, _utils.isEmpty)(context.siteSettings.docs_banner_title) && !(0, _utils.isEmpty)(context.siteSettings.docs_banner_description);
    }
  };
});