define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/admin-customize-theme-before-controls/enable-warning", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <ThemeEnableWarning @theme={{@outletArgs.theme}} />
  */
  {
    "id": "R+sNNPvW",
    "block": "[[[8,[39,0],null,[[\"@theme\"],[[30,1,[\"theme\"]]]],null]],[\"@outletArgs\"],false,[\"theme-enable-warning\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/admin-customize-theme-before-controls/enable-warning.hbs",
    "isStrictMode": false
  });
});