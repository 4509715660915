define("discourse/plugins/discourse-global-communities/discourse/feature-flags/program-pillars", ["exports", "discourse/plugins/discourse-global-communities/discourse/components/program-pillars/load-more-button"], function (_exports, _loadMoreButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ProgramPillarsFeatureFlag {
    static enabledSetting = "program_pillars_enabled";
    static className = "program-pillars-enabled";
    static afterEnabled(api) {
      const programPillarsService = api.container.lookup("service:agc-program-pillars");
      const router = api.container.lookup("service:router");
      programPillarsService.startTrackingState();

      // change the load more behavior for the program pillars route
      api.registerBehaviorTransformer("discovery-topic-list-load-more", _ref => {
        let {
          context,
          next
        } = _ref;
        const {
          model
        } = context;

        // fallback to default load more behavior when outside the program pillars route
        if (router.currentRouteName !== "program-pillars") {
          return next();
        }
        if (!model.canLoadMore || programPillarsService.loadMorePressed || model.topic_list?.topics?.length > model.topic_list.per_page) {
          return next();
        }

        // if one of the conditions above is not satisfied, do not do anything
        // to prevent the page from entering infinite scrolling mode
      });

      // render the load more button component after the topic list on the program pillars route
      api.renderInOutlet("after-topic-list", _loadMoreButton.default);
    }
  }
  _exports.default = ProgramPillarsFeatureFlag;
});