define("discourse/plugins/discourse-global-communities/discourse/initializers/surveys", ["exports", "jquery", "discourse/lib/plugin-api"], function (_exports, _jquery, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "event-survey-integration",
    _timer: null,
    refreshAt(time, fn) {
      if (this._timer) {
        clearTimeout(this._timer);
        this._timer = null;
      }
      time = time - +new Date();
      if (time <= 0) {
        return;
      } else if (time > 1728000000) {
        // timeout should not be more than 20 days.
        time = 1728000000;
      }
      this._timer = setTimeout(fn, time);
    },
    updateSurveyVisibility($survey, model) {
      const showSurveyAfterEventDelayMinutes = parseInt(model.event.custom_fields.show_survey_after_event_delay_minutes, 10) || 0;
      const surveyStartTime = +new Date(model.event.ends_at) + showSurveyAfterEventDelayMinutes * 60 * 1000;
      const hideSurveyAfterEventDelayHours = parseInt(model.event.custom_fields.hide_survey_after_event_delay_hours, 10) || 730;
      const surveyEndTime = +new Date(model.event.ends_at) + hideSurveyAfterEventDelayHours * 60 * 60 * 1000;
      const now = +new Date();
      if (surveyStartTime > now) {
        $survey.hide();
        this.refreshAt(surveyStartTime, () => this.updateSurveyVisibility($survey, model));
      } else if (surveyStartTime <= now && now <= surveyEndTime) {
        $survey.show();
        this.refreshAt(surveyEndTime, () => this.updateSurveyVisibility($survey, model));
      } else if (now > surveyEndTime) {
        $survey.hide();
      }
    },
    initializeWithApi(api) {
      api.decorateCookedElement((element, decoratorHelper) => {
        const model = decoratorHelper ? decoratorHelper.getModel() : null;
        const $survey = (0, _jquery.default)(element).find("div[data-survey-wrapper]");
        if (model && model.event && $survey.length > 0) {
          this.updateSurveyVisibility($survey, model);
        }
      }, {
        id: "event-survey-integration"
      });
    },
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.7", api => this.initializeWithApi(api));
    }
  };
});