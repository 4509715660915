define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/user-main-nav/amazon-contacts-nav-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo @route="user.amazon-contacts">
    {{d-icon "amazon-contacts"}}
    <span>{{i18n "user.amazon_contacts"}}</span>
  </LinkTo>
  */
  {
    "id": "0ywuxqaJ",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"user.amazon-contacts\"]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,1],[\"amazon-contacts\"],null]],[1,\"\\n  \"],[10,1],[12],[1,[28,[35,2],[\"user.amazon_contacts\"],null]],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"link-to\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/user-main-nav/amazon-contacts-nav-item.hbs",
    "isStrictMode": false
  });
});