define("discourse/plugins/discourse-global-communities/discourse/components/gc-combo-box", ["exports", "select-kit/components/combo-box"], function (_exports, _comboBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _comboBox.default.extend({
    classNames: ["gc-combo-box"],
    headerComponent: "gc-combo-box-header"
  });
});