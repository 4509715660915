define("discourse/plugins/discourse-global-communities/discourse/initializers/block-admin-on-mobile-app", ["exports", "discourse/lib/utilities"], function (_exports, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "agc-block-admin-on-mobile",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      const isAppWebview = container.lookup("service:capabilities").isAppWebview;

      // Add a class to the body when in mobile app regardless of site setting
      if (isAppWebview) {
        document.body.classList.add("agc-app-webview");
      }

      // Check to make sure we are in the mobile app, and the site setting is enabled.
      // If either one is false, return.
      if (!isAppWebview || !siteSettings.block_admin_on_mobile_app) {
        return;
      }
      document.body.classList.add("agc-hide-admin");
      const router = container.lookup("service:router");

      // Before route change, intercept any transitions to `/admin`
      router.on("routeWillChange", transition => {
        if (transition.to?.name?.startsWith("admin")) {
          transition.abort();
          return router.transitionTo(`discovery.${(0, _utilities.defaultHomepage)()}`);
        }
      });
    }
  };
});