define("discourse/plugins/discourse-global-communities/discourse/services/agc-analytics-events", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcAnalyticsEventsService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    logUserDefaultHomeFeedSorting(user, value, analyticsOrigin) {
      this.appEvents.trigger("agc-analytics:log-event", "preferences:agc-default-home-feed-sorting:changed", {
        origin: analyticsOrigin,
        user: user.username,
        value
      });
    }
  }
  _exports.default = AgcAnalyticsEventsService;
});