define("discourse/plugins/discourse-global-communities/discourse/controllers/tv-tag", ["exports", "@ember/controller", "discourse/models/topic", "discourse-common/utils/decorators", "discourse-i18n"], function (_exports, _controller, _topic, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    topics(unprocessedTopics) {
      return unprocessedTopics.map(topic => {
        return _topic.default.create(topic);
      });
    },
    title(label, showingNew) {
      return showingNew ? _discourseI18n.default.t("agc.tv.new_and_unwatched") : label;
    }
  }, [["method", "topics", [(0, _decorators.default)("model.topics")]], ["method", "title", [(0, _decorators.default)("model.label", "model.new")]]]));
});