define("discourse/plugins/discourse-global-communities/discourse/connectors/above-user-profile/preferences", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AGCPreferencesTitle extends _component.default {
    static shouldRender(args, context, owner) {
      const isPreferencesPage = owner.lookup("service:router").currentRouteName.includes("preferences.");
      return isPreferencesPage && context.currentUser?.agcFeatureFlags?.enhanced_notifications_enabled;
    }
  }
  _exports.default = AGCPreferencesTitle;
});