define("discourse/plugins/discourse-global-communities/discourse/initializers/streamline-posting", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "streamline-posting",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.8.36", () => {
        this.appEvents = container.lookup("service:app-events");
        this.streamlinePostingAnalyticsService = container.lookup("service:agc-streamline-posting-analytics");
        this.composerService = container.lookup("service:composer");

        // ***********************************
        // Composer toolbar analytic capturing
        // ***********************************
        this.appEvents.on("d-editor:toolbar-button-clicked", button => {
          if (this._userIsCreatingNewTopic) {
            this._storeToolbarClickInAnalytics(button.id);
          }
        });
        this.appEvents.on("composer:toolbar-popup-menu-button-clicked", button => {
          if (this._userIsCreatingNewTopic && button.name) {
            this._storeToolbarClickInAnalytics(button.name);
          }
        });
        this.appEvents.on("category-experts:is-question-checkbox-toggled", _ref => {
          let {
            checked
          } = _ref;
          this.appEvents.trigger("agc-custom:composer_question_for_dsp_toggled", {
            checked
          });
        });

        // ***********************************
        // Composer open/close/save new topic timing analytics
        // the goal here is to capture -
        //    Total time from:
        //      1) user clicks new post icon to
        //      2) user clicks ‘Post’. Closing post stops counter, resuming editing post resumes counter.
        // ***********************************
        this.appEvents.on("composer:open", _ref2 => {
          let {
            model
          } = _ref2;
          if (model.action === "createTopic") {
            // Check if the composer was closed and opened back up within 3 seconds. If not we need to start a new timer.
            // Using the existing timer is good for short jitters of opening/closing. Occationally on save this will happen
            // temporarily and it's nice to use the existing timer to make sure we don't lose anything.
            if (!this.streamlinePostingAnalyticsService.creatingTopicTimer || new Date() - this.streamlinePostingAnalyticsService.creatingTopicTimer > 3000) {
              // We are creating a new topic. Start the timer over again!
              this.streamlinePostingAnalyticsService.creatingTopicTimer = new Date();
            }
          } else {
            // We are not creating a new topic. Clear timer and reset stored MSs.
            this.streamlinePostingAnalyticsService.creatingTopicTimer = null;
            this.streamlinePostingAnalyticsService.storedMsCreatingTopic = 0;
          }
        });
        this.appEvents.on("composer:cancelled", () => {
          let currentTimer = this.streamlinePostingAnalyticsService.creatingTopicTimer;
          if (currentTimer) {
            // We may have previously stored MS if the user keeps closing the composer and re-opening.
            // Add 'storedMs' onto the previous value (if their are none it'll be adding to 0).
            this.streamlinePostingAnalyticsService.storedMsCreatingTopic += new Date() - currentTimer;
          }
        });
        this.appEvents.on("composer:created-post", () => {
          if (this.streamlinePostingAnalyticsService.creatingTopicTimer) {
            const lastTimerMs = new Date() - this.streamlinePostingAnalyticsService.creatingTopicTimer;
            const totalMs = lastTimerMs + this.streamlinePostingAnalyticsService.storedMsCreatingTopic;
            this.appEvents.trigger("agc-custom:composer_post_creation_timer", {
              ms: totalMs
            });
          }
        });
      });
    },
    _storeToolbarClickInAnalytics(buttonName) {
      this.appEvents.trigger("agc-custom:composer_toolbar_button_clicked", {
        type: buttonName
      });
    },
    _userIsCreatingNewTopic() {
      if (!this.composerService.model) {
        return false;
      }
      return this.composerService.model.action === "createTopic" && this.composerService.model.archetypeId === "regular";
    }
  };
});