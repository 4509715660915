define("discourse/plugins/discourse-global-communities/discourse/templates/components/gc-aux-login", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <p class="gc-aux-login-text">
    <DiscourseLinkedText
      @action={{this.toggleForm}}
      @text="discourse_gc.login.aux"
    />
  </p>
  
  {{#if this.showingForm}}
    <div class="gc-aux-login">
      <Input
        name="username"
        placeholder={{i18n "user.username.title"}}
        disabled={{this.loggingIn}}
        @value={{this.username}}
      />
      <Input
        name="password"
        placeholder={{i18n "user.password.title"}}
        disabled={{this.loggingIn}}
        @type="password"
        @value={{this.password}}
      />
      <DButton
        @action={{this.login}}
        @label="login.title"
        @disabled={{this.buttonDisabled}}
      />
    </div>
  {{/if}}
  */
  {
    "id": "wpHw0Tcs",
    "block": "[[[10,2],[14,0,\"gc-aux-login-text\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@action\",\"@text\"],[[30,0,[\"toggleForm\"]],\"discourse_gc.login.aux\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showingForm\"]],[[[1,\"  \"],[10,0],[14,0,\"gc-aux-login\"],[12],[1,\"\\n    \"],[8,[39,2],[[24,3,\"username\"],[16,\"placeholder\",[28,[37,3],[\"user.username.title\"],null]],[16,\"disabled\",[30,0,[\"loggingIn\"]]]],[[\"@value\"],[[30,0,[\"username\"]]]],null],[1,\"\\n    \"],[8,[39,2],[[24,3,\"password\"],[16,\"placeholder\",[28,[37,3],[\"user.password.title\"],null]],[16,\"disabled\",[30,0,[\"loggingIn\"]]]],[[\"@type\",\"@value\"],[\"password\",[30,0,[\"password\"]]]],null],[1,\"\\n    \"],[8,[39,4],null,[[\"@action\",\"@label\",\"@disabled\"],[[30,0,[\"login\"]],\"login.title\",[30,0,[\"buttonDisabled\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"discourse-linked-text\",\"if\",\"input\",\"i18n\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/components/gc-aux-login.hbs",
    "isStrictMode": false
  });
});