define("discourse/plugins/discourse-global-communities/discourse/connectors/user-notifications-above-filter/notifications-title", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AGCNotificationsTitle extends _component.default {
    static shouldRender(args, context) {
      const isFeatureEnabled = context.currentUser?.agcFeatureFlags?.enhanced_notifications_enabled;
      return isFeatureEnabled;
    }
  }
  _exports.default = AGCNotificationsTitle;
});