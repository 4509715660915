define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/above-main-container/group-analytics", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! Used to insert click handlers on various elements }}
  */
  {
    "id": "gtFj9olG",
    "block": "[[],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/above-main-container/group-analytics.hbs",
    "isStrictMode": false
  });
});