define("discourse/plugins/discourse-global-communities/discourse/feature-flags/ai-custom-summarization-allowed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiCustomSummarizationAllowedFeatureFlag {
    static currentUserEnabledKey = "can_summarize";
    static className = "ai-custom-summarization-allowed";
  }
  _exports.default = AiCustomSummarizationAllowedFeatureFlag;
});