define("discourse/plugins/discourse-global-communities/discourse/routes/idf-library", ["exports", "@ember/runloop", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _runloop, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    queryParams: {
      category: {
        refreshModel: true
      },
      type: {
        refreshModel: true
      },
      topic: {
        refreshModel: true
      }
    },
    model(params) {
      return (0, _ajax.ajax)("/idf/library.json", {
        data: params
      });
    },
    setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties({
        searchTerm: controller.term,
        loadingModel: false
      });
    },
    activate() {
      (0, _runloop.schedule)("afterRender", () => document.querySelector("html").classList.add("idf", "events-library"));
    },
    deactivate() {
      document.querySelector("html").classList.remove("idf", "events-library");
    }
  });
});