define("discourse/plugins/discourse-global-communities/discourse/connectors/composer-fields-below/file-upload", ["exports", "@ember/controller", "@ember/object/computed"], function (_exports, _controller, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent() {
      this.reopen({
        composerController: (0, _controller.inject)("composer"),
        allowUpload: (0, _computed.reads)("composerController.allowUpload"),
        isUploading: (0, _computed.reads)("composerController.isUploading")
      });
    }
  };
});