define("discourse/plugins/discourse-global-communities/discourse/connectors/below-login/login-customizations", ["exports", "@glimmer/component", "@ember/object", "@ember/owner", "@ember/service", "discourse/models/login-method"], function (_exports, _component, _object, _owner, _service, _loginMethod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LoginCustomizations extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "capabilities", [_service.service]))();
    #capabilities = (() => (dt7948.i(this, "capabilities"), void 0))();
    get inviteRequiredError() {
      const lastAuthResult = document.getElementById("data-authentication")?.dataset?.authenticationData;
      if (!lastAuthResult) {
        return false;
      }
      try {
        const json = JSON.parse(lastAuthResult);
        if (!json.requires_invite?.hasOwnProperty) {
          return false;
        }
        return json.requires_invite;
      } catch (error) {
        return false;
      }
    }
    get loginProviders() {
      return (0, _loginMethod.findAll)();
    }
    get showAuxLoginLink() {
      return this.siteSettings.gc_aux_login_enabled && this.capabilities.isAppWebview;
    }
    get showSignupButton() {
      return (0, _owner.getOwner)(this).lookup("controller:application").canSignUp;
    }
    externalLogin(provider) {
      provider.doLogin({
        signup: true
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "externalLogin", [_object.action]))();
  }
  _exports.default = LoginCustomizations;
});