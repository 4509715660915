define("discourse/plugins/discourse-global-communities/discourse/helpers/urlstrip", ["exports", "@ember/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.strip = strip;
  function strip(str) {
    let {
      safeString = true,
      allowPng = false
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let clean;
    if (allowPng) {
      clean = str.replace(/(?:https?):\/\/[\n\S]+/gim, url => {
        if (url.match(/(.png)(\'?|\?[\w=]+\'?)/i)) {
          return url;
        } else {
          return "";
        }
      });
    } else {
      clean = str ? str.replace(/(?:https?):\/\/[\n\S]+/gim, "") : "";
    }
    if (safeString) {
      return (0, _template.htmlSafe)(clean);
    } else {
      return clean;
    }
  }
});