define("discourse/plugins/discourse-global-communities/discourse/helpers/sort-user-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(opts) {
    let exc = {
      "N/A": 1,
      Other: 2
    };
    return opts.sort(function (a, b) {
      const aName = a.name ? a.name : a,
        bName = b.name ? b.name : b;
      if (exc[aName] && exc[bName]) {
        return exc[aName] - exc[bName];
      } else if (exc[aName]) {
        return 1;
      } else if (exc[bName]) {
        return -1;
      } else {
        return aName.localeCompare(bName);
      }
    });
  }
});