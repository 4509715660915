define("discourse/plugins/discourse-global-communities/discourse/components/search-menu-result-types/topic", ["exports", "@glimmer/component", "@ember/service", "discourse/components/search-menu/highlighted-search", "discourse/components/search-menu/results/blurb", "discourse/components/topic-status", "discourse/helpers/category-link", "discourse/helpers/discourse-tags", "discourse/helpers/replace-emoji", "truth-helpers/helpers/and", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _highlightedSearch, _blurb, _topicStatus, _categoryLink, _discourseTags, _replaceEmoji, _and, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SearchMenuResultTopic extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <span class="topic">
          <span class="first-line">
            <TopicStatus @topic={{@result.topic}} @disableActions={{true}} />
            <span class="topic-title" data-topic-id={{@result.topic.id}}>
              {{#if
                (and
                  this.siteSettings.use_pg_headlines_for_excerpt
                  @result.topic_title_headline
                )
              }}
                <span>{{replaceEmoji @result.topic_title_headline}}</span>
              {{else}}
                <SearchMenuHighlightedSearch @string={{@result.topic.fancyTitle}} />
              {{/if}}
            </span>
          </span>
    
          <SearchMenuResultsBlurb @result={{@result}} />
    
          <span class="second-line">
            {{categoryLink @result.topic.category link=false}}
            {{#if this.siteSettings.tagging_enabled}}
              {{discourseTags @result.topic tagName="span"}}
            {{/if}}
          </span>
        </span>
      
    */
    {
      "id": "UOXEL+qg",
      "block": "[[[1,\"\\n    \"],[10,1],[14,0,\"topic\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"first-line\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@topic\",\"@disableActions\"],[[30,1,[\"topic\"]],true]],null],[1,\"\\n        \"],[10,1],[14,0,\"topic-title\"],[15,\"data-topic-id\",[30,1,[\"topic\",\"id\"]]],[12],[1,\"\\n\"],[41,[28,[32,1],[[30,0,[\"siteSettings\",\"use_pg_headlines_for_excerpt\"]],[30,1,[\"topic_title_headline\"]]],null],[[[1,\"            \"],[10,1],[12],[1,[28,[32,2],[[30,1,[\"topic_title_headline\"]]],null]],[13],[1,\"\\n\"]],[]],[[[1,\"            \"],[8,[32,3],null,[[\"@string\"],[[30,1,[\"topic\",\"fancyTitle\"]]]],null],[1,\"\\n\"]],[]]],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[8,[32,4],null,[[\"@result\"],[[30,1]]],null],[1,\"\\n\\n      \"],[10,1],[14,0,\"second-line\"],[12],[1,\"\\n        \"],[1,[28,[32,5],[[30,1,[\"topic\",\"category\"]]],[[\"link\"],[false]]]],[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"tagging_enabled\"]],[[[1,\"          \"],[1,[28,[32,6],[[30,1,[\"topic\"]]],[[\"tagName\"],[\"span\"]]]],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@result\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/search-menu-result-types/topic.js",
      "scope": () => [_topicStatus.default, _and.default, _replaceEmoji.default, _highlightedSearch.default, _blurb.default, _categoryLink.default, _discourseTags.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SearchMenuResultTopic;
});