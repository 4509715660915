define("discourse/plugins/discourse-global-communities/discourse/services/agc-header", ["exports", "@glimmer/tracking", "@ember/object", "@ember/service"], function (_exports, _tracking, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcHeaderService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "standaloneUserMenuVisible", [_tracking.tracked], function () {
      return false;
    }))();
    #standaloneUserMenuVisible = (() => (dt7948.i(this, "standaloneUserMenuVisible"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "standaloneNotificationsVisible", [_tracking.tracked], function () {
      return false;
    }))();
    #standaloneNotificationsVisible = (() => (dt7948.i(this, "standaloneNotificationsVisible"), void 0))();
    get splitUserMenuAndNotificationsEnabled() {
      return this.siteSettings.chat_enabled;
    }
    toggleStandaloneUserMenu() {
      this.standaloneUserMenuVisible = !this.standaloneUserMenuVisible;
    }
    static #_5 = (() => dt7948.n(this.prototype, "toggleStandaloneUserMenu", [_object.action]))();
    toggleStandaloneNotifications() {
      this.appEvents.trigger("agc-custom:enhanced_notifications_btn_click");
      this.standaloneNotificationsVisible = !this.standaloneNotificationsVisible;
    }
    static #_6 = (() => dt7948.n(this.prototype, "toggleStandaloneNotifications", [_object.action]))();
  }
  _exports.default = AgcHeaderService;
});