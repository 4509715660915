define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/user-messages-above-navigation/agc-activity-nav", ["exports", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="agc-activity-archived-messages user-secondary-navigation">
    <Agc::UserProfile::ActivityNav @model={{@model}} />
  </div>
  <div class="messages-disabled-notice">
    <div class="row">
      <div class="alert alert-info {{notice.id}}">
        {{html-safe
          (i18n "agc.user.messages_disabled_notice" chatUrl=(get-url "/chat"))
        }}
      </div>
    </div>
  </div>
  */
  {
    "id": "4ZDfjEGN",
    "block": "[[[10,0],[14,0,\"agc-activity-archived-messages user-secondary-navigation\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@model\"],[[30,1]]],null],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"messages-disabled-notice\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[44,[[28,[37,2],null,[[\"notice\"],[[28,[32,0],[\"notice\"],null]]]]],[[[10,0],[15,0,[29,[\"alert alert-info \",[52,[30,2,[\"notice\"]],[28,[30,2,[\"notice\"]],null,null],[28,[32,1],[[30,0],\"notice.id\",\"[\\\"The `notice` property path was used in the `discourse/plugins/discourse-global-communities/discourse/templates/connectors/user-messages-above-navigation/agc-activity-nav.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.notice}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]]],[12],[1,\"\\n      \"],[1,[28,[35,4],[[28,[37,5],[\"agc.user.messages_disabled_notice\"],[[\"chatUrl\"],[[28,[37,6],[\"/chat\"],null]]]]],null]],[1,\"\\n    \"],[13]],[2]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@model\",\"maybeHelpers\"],false,[\"agc/user-profile/activity-nav\",\"let\",\"hash\",\"if\",\"html-safe\",\"i18n\",\"get-url\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/user-messages-above-navigation/agc-activity-nav.hbs",
    "scope": () => [_tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
});