define("discourse/plugins/discourse-global-communities/discourse/pre-initializers/agc-analytics-plugin-api", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-global-communities/discourse/lib/analytics-collector"], function (_exports, _pluginApi, _analyticsCollector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Class exposing the javascript API available to plugins and themes.
   * @class PluginApi
   */
  /**
   * Register a click interceptor to trigger collect analytics data
   *
   * @memberof PluginApi
   * @instance
   * @function registerClickAnalyticsCollector
   * @param {string} selector - The dom selector to intercept element clicks
   * @param {Object} analyticsDef - The analytics definition
   * @example
   *
   * api.agcAnalytics.registerClickAnalyticsCollector({
   *   selector: ".foo",
   *   eventData(element) {
   *     return this.site.mobileView && this.canAttachUploads;
   *   }
   * });
   */
  var _default = _exports.default = {
    name: "agc-analytics-plugin-api",
    after: "inject-discourse-objects",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.2.0", api => {
        const apiPrototype = Object.getPrototypeOf(api);
        if (!apiPrototype.hasOwnProperty("agcAnalytics")) {
          Object.defineProperty(apiPrototype, "agcAnalytics", {
            configurable: false,
            writable: false,
            value: Object.freeze({
              registerClickAnalytics(selector, analyticsDef) {
                (0, _analyticsCollector.registerClickAnalyticsDefinition)({
                  selector,
                  analyticsDef
                });
              }
            })
          });
        }
      });
    },
    teardown() {
      (0, _analyticsCollector.resetClickAnalyticsDefinitions)();
    }
  };
});