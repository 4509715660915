define("discourse/plugins/discourse-global-communities/discourse/components/agc/search/category-experts-options", ["exports", "@glimmer/component", "@ember/component", "@ember/helper", "@ember/modifier", "@ember/object", "discourse-common/helpers/i18n", "@ember/template-factory"], function (_exports, _component, _component2, _helper, _modifier, _object, _i18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcSearchCategoryExpertsOptions extends _component.default {
    static shouldRender(args, context) {
      return context.siteSettings.enable_category_experts;
    }
    get onChangeSearchedTermField() {
      return this.args.outletArgs.onChangeSearchedTermField;
    }
    onChangeCheckBox(path, callback, event) {
      this.onChangeSearchedTermField(path, callback, event.target.checked);
    }
    static #_ = (() => dt7948.n(this.prototype, "onChangeCheckBox", [_object.action]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <section class="field with-guide-response-field">
          <label>
            <Input
              class="with-guide-response"
              @type="checkbox"
              @checked={{@outletArgs.searchedTerms.withGuideResponse}}
              {{on
                "click"
                (fn
                  this.onChangeCheckBox
                  "withGuideResponse"
                  "updateWithGuideResponse"
                )
              }}
            />
            {{i18n "agc.with_guide_response"}}
          </label>
        </section>
    
        <section class="field with-amazon-response-field">
          <label>
            <Input
              class="with-amazon-response"
              @type="checkbox"
              @checked={{@outletArgs.searchedTerms.withAmazonResponse}}
              {{on
                "click"
                (fn
                  this.onChangeCheckBox
                  "withAmazonResponse"
                  "updateWithAmazonResponse"
                )
              }}
            />
            {{i18n "agc.with_amazon_response"}}
          </label>
        </section>
      
    */
    {
      "id": "gv8vC96Y",
      "block": "[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"field with-guide-response-field\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,\"\\n        \"],[8,[32,0],[[24,0,\"with-guide-response\"],[4,[32,1],[\"click\",[28,[32,2],[[30,0,[\"onChangeCheckBox\"]],\"withGuideResponse\",\"updateWithGuideResponse\"],null]],null]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,1,[\"searchedTerms\",\"withGuideResponse\"]]]],null],[1,\"\\n        \"],[1,[28,[32,3],[\"agc.with_guide_response\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"section\"],[14,0,\"field with-amazon-response-field\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,\"\\n        \"],[8,[32,0],[[24,0,\"with-amazon-response\"],[4,[32,1],[\"click\",[28,[32,2],[[30,0,[\"onChangeCheckBox\"]],\"withAmazonResponse\",\"updateWithAmazonResponse\"],null]],null]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,1,[\"searchedTerms\",\"withAmazonResponse\"]]]],null],[1,\"\\n        \"],[1,[28,[32,3],[\"agc.with_amazon_response\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@outletArgs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/search/category-experts-options.js",
      "scope": () => [_component2.Input, _modifier.on, _helper.fn, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcSearchCategoryExpertsOptions;
});