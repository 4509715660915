define("discourse/plugins/discourse-global-communities/discourse/helpers/agc-cw-events-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formattedError = formattedError;
  _exports.recordEventError = recordEventError;
  _exports.recordEventLatency = recordEventLatency;
  function recordEventLatency(eventType, latency, metadata) {
    if (!window.cwr) {
      return;
    }
    window.cwr("recordEvent", {
      type: eventType,
      data: {
        latency,
        ...metadata
      }
    });
  }
  function recordEventError(eventType, error, metadata) {
    if (!window.cwr) {
      return;
    }
    window.cwr("recordEvent", {
      type: eventType,
      data: {
        error_code: formattedError(error).errorCode,
        error_message: formattedError(error).errorMessage,
        ...metadata
      }
    });
  }
  function formattedError(error) {
    return {
      errorCode: error?.jqXHR?.status,
      errorMessage: error?.jqXHR?.statusText
    };
  }
});