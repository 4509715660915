define("discourse/plugins/discourse-global-communities/discourse/components/topic-category-expert-question-banner", ["exports", "@ember/component", "@ember/object", "discourse-common/utils/decorators"], function (_exports, _component, _object, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    show(topic, isQuestion, categoryAllowingQuestions) {
      return categoryAllowingQuestions && this.currentUser && this.currentUser.id === topic.user_id && !isQuestion && moment().diff(topic.created_at, "minutes") < 4;
    },
    openComposer() {
      this.editFirstPost();
    }
  }, [["method", "show", [(0, _decorators.default)("topic", "topic.is_category_expert_question", "topic.category.allowingCategoryExpertQuestions")]], ["method", "openComposer", [_object.action]]]));
});