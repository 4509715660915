define("discourse/plugins/discourse-global-communities/discourse/components/agc-quick-search-menu-see-all-results", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/render-modifiers/modifiers/did-update", "@ember/service", "discourse/components/d-button", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _didUpdate, _service, _dButton, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcQuickSearchMenuSeeAllResults extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "search", [_service.service]))();
    #search = (() => (dt7948.i(this, "search"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "show", [_tracking.tracked]))();
    #show = (() => (dt7948.i(this, "show"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "noResults", [_tracking.tracked]))();
    #noResults = (() => (dt7948.i(this, "noResults"), void 0))();
    constructor() {
      super(...arguments);
      this.appEvents.on("agc-quick-search-menu:loaded", this, this.recalcShouldShow);
      this._lastSearchResult = this.search.activeGlobalSearchTerm;
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off("agc-quick-search-menu:loaded", this, this.recalcShouldShow);
    }
    searchTermUpdated() {
      // Hide right away if search term is updated, and let the quick search results component
      // fire an appEvent to show again. This is called if you click, on the search menu, which
      // is why we need to check if the search term was actually updated
      if (this._lastSearchResult !== this.search.activeGlobalSearchTerm) {
        this.show = false;
        this._lastSearchResult = this.search.activeGlobalSearchTerm;
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "searchTermUpdated", [_object.action]))();
    recalcShouldShow(appEventArgs) {
      this.show = appEventArgs.show && !this.args.inTopicContext;
      this.noResults = appEventArgs.noResults;
    }
    static #_7 = (() => dt7948.n(this.prototype, "recalcShouldShow", [_object.action]))();
    navigateToAdvancedSearch(e) {
      e?.preventDefault();
      this.args.closeSearchMenu();
      this.router.transitionTo("full-page-search", {
        queryParams: {
          q: this.search.activeGlobalSearchTerm
        }
      });
      const searchMenuInput = document.getElementById("search-term");
      if (searchMenuInput) {
        searchMenuInput.value = this.search.activeGlobalSearchTerm;
      }
      return false;
    }
    static #_8 = (() => dt7948.n(this.prototype, "navigateToAdvancedSearch", [_object.action]))();
    static #_9 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div {{didUpdate this.searchTermUpdated @searchTerm}}>
          {{#if this.show}}
            {{#if this.noResults}}
              <label class="no-results-label">
                {{i18n "agc.quick_search.no_results"}}
              </label>
            {{else}}
              <DButton
                @action={{this.navigateToAdvancedSearch}}
                @translatedLabel={{i18n "agc.quick_search.see_all_results"}}
                class="btn-primary see-all-results-btn"
              />
            {{/if}}
          {{/if}}
        </div>
      
    */
    {
      "id": "VYlc/QFr",
      "block": "[[[1,\"\\n    \"],[11,0],[4,[32,0],[[30,0,[\"searchTermUpdated\"]],[30,1]],null],[12],[1,\"\\n\"],[41,[30,0,[\"show\"]],[[[41,[30,0,[\"noResults\"]],[[[1,\"          \"],[10,\"label\"],[14,0,\"no-results-label\"],[12],[1,\"\\n            \"],[1,[28,[32,1],[\"agc.quick_search.no_results\"],null]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,2],[[24,0,\"btn-primary see-all-results-btn\"]],[[\"@action\",\"@translatedLabel\"],[[30,0,[\"navigateToAdvancedSearch\"]],[28,[32,1],[\"agc.quick_search.see_all_results\"],null]]],null],[1,\"\\n\"]],[]]]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@searchTerm\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc-quick-search-menu-see-all-results.js",
      "scope": () => [_didUpdate.default, _i18n.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcQuickSearchMenuSeeAllResults;
});