define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/above-user-profile/preferences", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h1 class="user-preferences-title">
    {{i18n "notifications.preferences.title"}}
  </h1>
  */
  {
    "id": "Kj8WoSvC",
    "block": "[[[10,\"h1\"],[14,0,\"user-preferences-title\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"notifications.preferences.title\"],null]],[1,\"\\n\"],[13]],[],false,[\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/above-user-profile/preferences.hbs",
    "isStrictMode": false
  });
});