define("discourse/plugins/discourse-global-communities/discourse/routes/program-pillars-base", ["exports", "@ember/service", "discourse/routes/discourse"], function (_exports, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ProgramPillarsRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "agcProgramPillars", [_service.service]))();
    #agcProgramPillars = (() => (dt7948.i(this, "agcProgramPillars"), void 0))();
    beforeModel() {
      if (!this.agcProgramPillars.hasAny) {
        return this.router.replaceWith("/404");
      }
      return this.router.replaceWith(`/program-pillars/${this.agcProgramPillars.firstAvailable.region}`);
    }
  }
  _exports.default = ProgramPillarsRoute;
});