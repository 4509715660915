define("discourse/plugins/discourse-global-communities/discourse/routes/explore", ["exports", "discourse/models/category-list", "discourse/routes/discourse"], function (_exports, _categoryList, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      return _categoryList.default.list(this.store);
    },
    titleToken() {
      return "Explore";
    }
  });
});