define("discourse/plugins/discourse-global-communities/discourse/routes/tv-tag", ["exports", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model(params) {
      return (0, _ajax.ajax)(`/tv/${params.tag}.json`);
    },
    titleToken() {
      return this.controller.title;
    },
    setupController(controller, model) {
      this._super(controller, model);
      window.scrollTo(0, 0);
    }
  });
});