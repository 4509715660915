define("discourse/plugins/discourse-global-communities/discourse/config/carousel-v2-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.carouselV2Config = void 0;
  /*
   * Config values for Carousel
   */

  const carouselV2Config = _exports.carouselV2Config = {
    // Fallback value for number of carousel tiles per page
    defaultTilesPerPage: 1.2,
    // Fallback value for spacing, in pixels, between two carousel tiles
    defaultTileSpacing: 8,
    // Animation duration, in milliseconds, for carousel pagination
    paginationAnimationDuration: 800,
    // Debounce spacing/delay, in milliseconds, for carousel events (mobile only)
    debounceDelay: 50,
    // Threshold, in pixels, for adding fade to carousel on swipe (mobile only)
    fadeEdgeThreshold: 10,
    // Pagination should start from new tile
    startPagewithNewTile: true
  };
});