define("discourse/plugins/discourse-global-communities/discourse/components/agc/header/profile-menu", ["exports", "@glimmer/tracking", "@ember/helper", "@ember/render-modifiers/modifiers/did-insert", "discourse/components/user-menu/menu", "discourse/components/user-menu/profile-tab-content", "discourse/components/user-profile-avatar", "discourse/components/user-status-message", "discourse/helpers/user-status", "discourse/lib/utilities", "@ember/component", "@ember/template-factory"], function (_exports, _tracking, _helper, _didInsert, _menu, _profileTabContent, _userProfileAvatar, _userStatusMessage, _userStatus, _utilities, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcHeaderProfileMenu extends _menu.default {
    static #_ = (() => dt7948.g(this.prototype, "currentTabId", [_tracking.tracked], function () {
      return "profile";
    }))();
    #currentTabId = (() => (dt7948.i(this, "currentTabId"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentPanelComponent", [_tracking.tracked], function () {
      return _profileTabContent.default;
    }))();
    #currentPanelComponent = (() => (dt7948.i(this, "currentPanelComponent"), void 0))();
    get isUserInfoVisible() {
      return this.site.mobileView;
    }
    static #_3 = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class="user-menu revamped menu-panel drop-down standalone-user-menu"
          data-max-width="320"
          {{didInsert this.triggerRenderedAppEvent}}
        >
          {{#if this.isUserInfoVisible}}
            <div class="user-info">
              <UserProfileAvatar @user={{this.currentUser}} @tagName="" />
              <div class="user-profile-names">
                <div
                  class="{{if this.nameFirst 'full-name' 'username'}}
                    user-profile-names__primary"
                >
                  {{if
                    this.nameFirst
                    this.currentUser.name
                    (formatUsername this.currentUser.username)
                  }}
                  {{userStatus this.currentUser currentUser=this.currentUser}}
                  {{#if this.currentUser.status}}
                    <UserStatusMessage @status={{this.currentUser.status}} />
                  {{/if}}
                </div>
              </div>
            </div>
          {{/if}}
          <div class="panel-body">
            <div class="panel-body-contents">
              <div
                id={{concat "quick-access-" this.currentTabId}}
                class="quick-access-panel"
                tabindex="-1"
              >
                <this.currentPanelComponent
                  @closeUserMenu={{@closeUserMenu}}
                  @filterByTypes={{this.currentNotificationTypes}}
                  @ariaLabelledby={{concat "user-menu-button-" this.currentTabId}}
                />
              </div>
            </div>
          </div>
        </div>
      
    */
    {
      "id": "nN7kqXL8",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"user-menu revamped menu-panel drop-down standalone-user-menu\"],[24,\"data-max-width\",\"320\"],[4,[32,0],[[30,0,[\"triggerRenderedAppEvent\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"isUserInfoVisible\"]],[[[1,\"        \"],[10,0],[14,0,\"user-info\"],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@user\",\"@tagName\"],[[30,0,[\"currentUser\"]],\"\"]],null],[1,\"\\n          \"],[10,0],[14,0,\"user-profile-names\"],[12],[1,\"\\n            \"],[10,0],[15,0,[29,[[52,[30,0,[\"nameFirst\"]],\"full-name\",\"username\"],\"\\n                user-profile-names__primary\"]]],[12],[1,\"\\n              \"],[1,[52,[30,0,[\"nameFirst\"]],[30,0,[\"currentUser\",\"name\"]],[28,[32,2],[[30,0,[\"currentUser\",\"username\"]]],null]]],[1,\"\\n              \"],[1,[28,[32,3],[[30,0,[\"currentUser\"]]],[[\"currentUser\"],[[30,0,[\"currentUser\"]]]]]],[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"status\"]],[[[1,\"                \"],[8,[32,4],null,[[\"@status\"],[[30,0,[\"currentUser\",\"status\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[10,0],[14,0,\"panel-body\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"panel-body-contents\"],[12],[1,\"\\n          \"],[10,0],[15,1,[28,[32,5],[\"quick-access-\",[30,0,[\"currentTabId\"]]],null]],[14,0,\"quick-access-panel\"],[14,\"tabindex\",\"-1\"],[12],[1,\"\\n            \"],[8,[30,0,[\"currentPanelComponent\"]],null,[[\"@closeUserMenu\",\"@filterByTypes\",\"@ariaLabelledby\"],[[30,1],[30,0,[\"currentNotificationTypes\"]],[28,[32,5],[\"user-menu-button-\",[30,0,[\"currentTabId\"]]],null]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@closeUserMenu\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/header/profile-menu.js",
      "scope": () => [_didInsert.default, _userProfileAvatar.default, _utilities.formatUsername, _userStatus.default, _userStatusMessage.default, _helper.concat],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcHeaderProfileMenu;
});