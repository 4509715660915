define("discourse/plugins/discourse-global-communities/discourse/components/gc-circles-section", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="circles-section {{concat 'circles-section-' @model.name}}">
    <h3 class="circles-section-title">{{@title}}</h3>
    {{#if @model.circles.content}}
      <div class="circles-section-cards circles-boxes">
        {{#each @model.circles.content as |circle|}}
          <Circles::Card @model={{circle}} @showMembershipButton={{true}} />
        {{/each}}
      </div>
      <div class="circles-section-actions">
        {{#if this.showViewAll}}
          <DButton
            @action={{@onViewAll}}
            @label="discourse_circles.view_all"
            class="btn-default circles-view-all"
          />
        {{/if}}
      </div>
    {{else}}
      {{#if @filter}}
        <div class="circles-empty circles-not-found">
          <p>{{i18n "discourse_circles.index.not_found"}}</p>
        </div>
      {{else}}
        <div class="circles-empty">
          {{#if (has-block "empty")}}
            {{yield to="empty"}}
          {{else}}
            <p>{{i18n "discourse_circles.index.empty"}}</p>
          {{/if}}
        </div>
      {{/if}}
    {{/if}}
  </section>
  */
  {
    "id": "AYLwV913",
    "block": "[[[10,\"section\"],[15,0,[29,[\"circles-section \",[28,[37,0],[\"circles-section-\",[30,1,[\"name\"]]],null]]]],[12],[1,\"\\n  \"],[10,\"h3\"],[14,0,\"circles-section-title\"],[12],[1,[30,2]],[13],[1,\"\\n\"],[41,[30,1,[\"circles\",\"content\"]],[[[1,\"    \"],[10,0],[14,0,\"circles-section-cards circles-boxes\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,1,[\"circles\",\"content\"]]],null]],null],null,[[[1,\"        \"],[8,[39,4],null,[[\"@model\",\"@showMembershipButton\"],[[30,3],true]],null],[1,\"\\n\"]],[3]],null],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"circles-section-actions\"],[12],[1,\"\\n\"],[41,[30,0,[\"showViewAll\"]],[[[1,\"        \"],[8,[39,5],[[24,0,\"btn-default circles-view-all\"]],[[\"@action\",\"@label\"],[[30,4],\"discourse_circles.view_all\"]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[41,[30,5],[[[1,\"      \"],[10,0],[14,0,\"circles-empty circles-not-found\"],[12],[1,\"\\n        \"],[10,2],[12],[1,[28,[35,6],[\"discourse_circles.index.not_found\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"circles-empty\"],[12],[1,\"\\n\"],[41,[48,[30,6]],[[[1,\"          \"],[18,6,null],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,2],[12],[1,[28,[35,6],[\"discourse_circles.index.empty\"],null]],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[]]]],[]]],[13]],[\"@model\",\"@title\",\"circle\",\"@onViewAll\",\"@filter\",\"&empty\"],false,[\"concat\",\"if\",\"each\",\"-track-array\",\"circles/card\",\"d-button\",\"i18n\",\"has-block\",\"yield\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/gc-circles-section.hbs",
    "isStrictMode": false
  });
  class GcCirclesSection extends _component2.default {
    get showViewAll() {
      const circles = this.args.model.circles;
      return circles.content.length < circles.totalRows;
    }
  }
  _exports.default = GcCirclesSection;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GcCirclesSection);
});