define("discourse/plugins/discourse-global-communities/discourse/initializers/agc-user-options", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_SORTING = "agc_default_home_feed_sorting";
  const ALLOW_PUSH_NOTIFICATIONS = "allow_push_notifications";
  const ALLOW_AMAZON_POSTS_PUSH_NOTIFICATIONS = "allow_amazon_posts_push_notifications";
  const ALLOW_AMAZON_POSTS_BROWSER_NOTIFICATIONS = "allow_amazon_posts_browser_notifications";
  const ALLOW_CHAT_PUSH_NOTIFICATIONS = "allow_chat_push_notifications";
  const ALLOW_CHAT_BROWSER_NOTIFICATIONS = "allow_chat_browser_notifications";
  const ALLOW_GROUP_MENTION_PUSH_NOTIFICATIONS = "allow_group_mention_push_notifications";
  const ALLOW_GROUP_MENTION_BROWSER_NOTIFICATIONS = "allow_group_mention_browser_notifications";
  const ALLOW_MENTION_PUSH_NOTIFICATIONS = "allow_mention_push_notifications";
  const ALLOW_MENTION_BROWSER_NOTIFICATIONS = "allow_mention_browser_notifications";
  const ALLOW_REPLY_PUSH_NOTIFICATIONS = "allow_reply_push_notifications";
  const ALLOW_REPLY_BROWSER_NOTIFICATIONS = "allow_reply_browser_notifications";
  const ALLOW_WATCHED_PUSH_NOTIFICATIONS = "allow_watched_push_notifications";
  const ALLOW_WATCHED_BROWSER_NOTIFICATIONS = "allow_watched_browser_notifications";
  const AMAZON_POSTS_EMAIL_NOTIFICATION_LEVEL = "amazon_posts_email_notification_level";
  const CHAT_SOUND = "chat_sound";
  const EMAIL_LEVEL = "email_level";
  const GROUP_MENTION_EMAIL_NOTIFICATION_LEVEL = "group_mention_email_notification_level";
  const MENTION_EMAIL_NOTIFICATION_LEVEL = "mention_email_notification_level";
  const NOTIFICATION_LEVEL_WHEN_REPLYING = "notification_level_when_replying";
  const REPLY_EMAIL_NOTIFICATION_LEVEL = "reply_email_notification_level";
  const WATCHED_EMAIL_NOTIFICATION_LEVEL = "watched_email_notification_level";
  var _default = _exports.default = {
    name: "agc-user-options",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.11.0", api => {
        api.addSaveableUserOptionField(DEFAULT_SORTING);
        api.addSaveableUserOptionField(ALLOW_PUSH_NOTIFICATIONS);
        api.addSaveableUserOptionField(ALLOW_AMAZON_POSTS_PUSH_NOTIFICATIONS);
        api.addSaveableUserOptionField(ALLOW_AMAZON_POSTS_BROWSER_NOTIFICATIONS);
        api.addSaveableUserOptionField(ALLOW_CHAT_PUSH_NOTIFICATIONS);
        api.addSaveableUserOptionField(ALLOW_CHAT_BROWSER_NOTIFICATIONS);
        api.addSaveableUserOptionField(ALLOW_GROUP_MENTION_PUSH_NOTIFICATIONS);
        api.addSaveableUserOptionField(ALLOW_GROUP_MENTION_BROWSER_NOTIFICATIONS);
        api.addSaveableUserOptionField(ALLOW_MENTION_PUSH_NOTIFICATIONS);
        api.addSaveableUserOptionField(ALLOW_MENTION_BROWSER_NOTIFICATIONS);
        api.addSaveableUserOptionField(ALLOW_REPLY_PUSH_NOTIFICATIONS);
        api.addSaveableUserOptionField(ALLOW_REPLY_BROWSER_NOTIFICATIONS);
        api.addSaveableUserOptionField(ALLOW_WATCHED_PUSH_NOTIFICATIONS);
        api.addSaveableUserOptionField(ALLOW_WATCHED_BROWSER_NOTIFICATIONS);
        api.addSaveableUserOptionField(AMAZON_POSTS_EMAIL_NOTIFICATION_LEVEL);
        api.addSaveableUserOptionField(CHAT_SOUND);
        api.addSaveableUserOptionField(EMAIL_LEVEL);
        api.addSaveableUserOptionField(GROUP_MENTION_EMAIL_NOTIFICATION_LEVEL);
        api.addSaveableUserOptionField(MENTION_EMAIL_NOTIFICATION_LEVEL);
        api.addSaveableUserOptionField(NOTIFICATION_LEVEL_WHEN_REPLYING);
        api.addSaveableUserOptionField(REPLY_EMAIL_NOTIFICATION_LEVEL);
        api.addSaveableUserOptionField(WATCHED_EMAIL_NOTIFICATION_LEVEL);
      });
    }
  };
});