define("discourse/plugins/discourse-global-communities/discourse/components/post-tagging", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _ajax, _ajaxError, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="post-tagging-container">
    {{#if this.renderTagChooser}}
      <div class="post-tagging-tag-chooser-container">
        <MiniTagChooser
          @class="post-tagging-tag-chooser"
          @value={{this.tagChooserValue}}
          @onChange={{this.onTagChooserChange}}
          @useAgcCustomSearch={{true}}
          @options={{hash
            allowAny=false
            useHeaderFilter=true
            everyTag=true
            filterPlaceholder="posts.tags.filter_placeholder"
          }}
        />
        <DButton
          @class="post-tagging-tag-chooser-button submit-tag-selection"
          @action={{this.confirmTagSelection}}
          @icon="check"
        />
        <DButton
          @class="post-tagging-tag-chooser-button close-tag-chooser"
          @action={{this.submitOrCancelTagSelection}}
          @icon="times"
        />
      </div>
    {{else}}
      <DButton
        @class="post-tagging-pencil"
        @action={{this.toggleRenderTagChooser}}
        @icon="pencil-alt"
      />
    {{/if}}
  </div>
  */
  {
    "id": "7GIkdqui",
    "block": "[[[10,0],[14,0,\"post-tagging-container\"],[12],[1,\"\\n\"],[41,[30,0,[\"renderTagChooser\"]],[[[1,\"    \"],[10,0],[14,0,\"post-tagging-tag-chooser-container\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@class\",\"@value\",\"@onChange\",\"@useAgcCustomSearch\",\"@options\"],[\"post-tagging-tag-chooser\",[30,0,[\"tagChooserValue\"]],[30,0,[\"onTagChooserChange\"]],true,[28,[37,2],null,[[\"allowAny\",\"useHeaderFilter\",\"everyTag\",\"filterPlaceholder\"],[false,true,true,\"posts.tags.filter_placeholder\"]]]]],null],[1,\"\\n      \"],[8,[39,3],null,[[\"@class\",\"@action\",\"@icon\"],[\"post-tagging-tag-chooser-button submit-tag-selection\",[30,0,[\"confirmTagSelection\"]],\"check\"]],null],[1,\"\\n      \"],[8,[39,3],null,[[\"@class\",\"@action\",\"@icon\"],[\"post-tagging-tag-chooser-button close-tag-chooser\",[30,0,[\"submitOrCancelTagSelection\"]],\"times\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,3],null,[[\"@class\",\"@action\",\"@icon\"],[\"post-tagging-pencil\",[30,0,[\"toggleRenderTagChooser\"]],\"pencil-alt\"]],null],[1,\"\\n\"]],[]]],[13]],[],false,[\"if\",\"mini-tag-chooser\",\"hash\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/post-tagging.hbs",
    "isStrictMode": false
  });
  class PostTagging extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "renderTagChooser", [_tracking.tracked], function () {
      return false;
    }))();
    #renderTagChooser = (() => (dt7948.i(this, "renderTagChooser"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "tagChooserValue", [_tracking.tracked], function () {
      return [];
    }))();
    #tagChooserValue = (() => (dt7948.i(this, "tagChooserValue"), void 0))();
    postId;
    constructor() {
      super(...arguments);
      this.postId = this.args?.postId;
      this.postTags = this.args?.postTags ?? [];
      this.tagChooserValue = this.postTags;
      this.appEvents.on(`display-post-tags-${this.postId}`, this.handleDisplayPostTags);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off(`display-post-tags-${this.postId}`, this.handleDisplayPostTags);
    }
    handleDisplayPostTags(event) {
      if (event?.success_post) {
        this.postTags = event.success_post.post_tags;
        this.tagChooserValue = this.postTags;
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "handleDisplayPostTags", [_decorators.bind]))();
    toggleRenderTagChooser() {
      this.renderTagChooser = !this.renderTagChooser;
    }
    static #_7 = (() => dt7948.n(this.prototype, "toggleRenderTagChooser", [_object.action]))();
    onTagChooserChange(value) {
      this.tagChooserValue = value;
    }
    static #_8 = (() => dt7948.n(this.prototype, "onTagChooserChange", [_object.action]))();
    async confirmTagSelection() {
      const options = {
        data: {
          post_ids: [this.postId],
          tag_names: this.tagChooserValue
        },
        type: "PUT"
      };
      try {
        const result = await (0, _ajax.ajax)(`/admin/plugins/agc/posts/tags.json`, options);
        this.appEvents.trigger(`display-post-tags-${this.postId}`, {
          success_post: result?.success_posts?.[0],
          error_post: result?.error_posts?.[0]
        });
        this.postTags = this.tagChooserValue;
        this.submitOrCancelTagSelection();
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
    }
    static #_9 = (() => dt7948.n(this.prototype, "confirmTagSelection", [_object.action]))();
    submitOrCancelTagSelection() {
      this.toggleRenderTagChooser();
      this.tagChooserValue = this.postTags;
    }
    static #_10 = (() => dt7948.n(this.prototype, "submitOrCancelTagSelection", [_object.action]))();
  }
  _exports.default = PostTagging;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PostTagging);
});