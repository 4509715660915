define("discourse/plugins/discourse-global-communities/discourse/widgets/comments-filter-widget", ["virtual-dom", "discourse/lib/url", "discourse/widgets/widget", "discourse-i18n"], function (_virtualDom, _url, _widget, _discourseI18n) {
  "use strict";

  (0, _widget.createWidget)("comments-filter", {
    tagName: "",
    content(topic) {
      const groups = topic.expert_post_group_names || [];
      let options = [{
        id: "all",
        translatedLabel: _discourseI18n.default.t("agc.responses_filter.all_comments")
      }, {
        id: "amazon_responses",
        translatedLabel: _discourseI18n.default.t("agc.responses_filter.amazon_responses"),
        disabled: !groups.some(group => group === this.siteSettings.amazonian_group_name)
      }, {
        id: "guided_responses",
        translatedLabel: _discourseI18n.default.t("agc.responses_filter.guided_responses"),
        disabled: !groups.some(group => group !== this.siteSettings.amazonian_group_name)
      }];

      // removes disabled options if the component setting to hide the options
      // when there are no available responses from the group is true
      if (this.siteSettings.hide_comments_filter_option_no_responses) {
        return options.rejectBy("disabled");
      }
      return options;
    },
    buildKey: attrs => `comments-filter-${attrs.topic.id}`,
    label(topic) {
      const label = this.content(topic).find(option => option.id === (topic.filter ? topic.filter : "all")).translatedLabel;
      return label;
    },
    clickRef(id) {
      switch (id) {
        case "all":
          return "filter_all_comments";
        case "guided_responses":
          return "filter_guide_responses";
        case "amazon_responses":
          return "filter_amazon_responses";
      }
    },
    visible(topic, dropdownDisabled) {
      if (!this.siteSettings.enable_category_experts) {
        return false;
      }
      // hides the filter if there are no experts options to be selected because
      // this.siteSettings.hide_comments_filter_option_no_responses == true
      if (this.siteSettings.hide_comments_filter_option_no_responses && dropdownDisabled) {
        return false;
      }
      // displays the filter if there are experts groups assigned in the category
      if (topic?.category?.custom_fields?.category_expert_group_ids) {
        return true;
      }
      // since at this point there are experts groups assigned in the category
      // displays the filter only if the setting to hide if there are no groups
      // assigned if false
      return !this.siteSettings.hide_comments_filter_category_experts_not_assigned;
    },
    html(_ref) {
      let {
        topic
      } = _ref;
      const params = new URLSearchParams(window.location.search);
      if (params.has("filter")) {
        _url.default.jumpToPost("1", {
          anchor: "comments-container"
        });
        if (!topic.filter) {
          topic.postStream.refresh({
            filter: params.get("filter")
          });
        }
      }
      const dropdownDisabled = this.content(topic).length === 1;
      const dropdownAttrs = {
        id: `comments-filter-${topic.id}`,
        translatedLabel: this.label(topic),
        content: this.content(topic),
        onChange: _ref2 => {
          let {
            id
          } = _ref2;
          const postStream = this.attrs.topic.postStream;
          postStream.cancelFilter();
          postStream.refresh({
            filter: id
          }).then(() => {
            _url.default.jumpToPost("1", {
              anchor: "comments-container"
            });
            this.appEvents.trigger("enhanced_user_analytics:event", "responses_filter_change", {}, {
              clickRef: this.clickRef(id)
            });
          });
        },
        options: {
          caret: true,
          disabled: dropdownDisabled
        }
      };
      if (this.visible(topic, dropdownDisabled)) {
        return (0, _virtualDom.h)("div", [(0, _virtualDom.h)("span.filter-label", _discourseI18n.default.t("agc.responses_filter.label")), this.attach("widget-dropdown", dropdownAttrs)]);
      }
    }
  });
});