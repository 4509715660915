define("discourse/plugins/discourse-global-communities/discourse/components/gc-share-your-expertise", ["exports", "@ember/template", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _template, _i18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#if @gcShareYourExpertise.length}}
      <div class="unanswered-sidebar">
        <h3 class="cell-title">
          {{i18n "agc.homepage.sidebar.unanswered.headline"}}
        </h3>
        <div class="unanswered-topics">
          {{#each @gcShareYourExpertise as |topic|}}
            <div class="unanswered-topic">
              <a href={{topic.url}}>
                <h4>{{htmlSafe topic.fancy_title}}</h4>
                {{#if topic.excerpt}}
                  <p>{{htmlSafe topic.excerpt}}</p>
                {{/if}}
              </a>
            </div>
          {{/each}}
        </div>
      </div>
    {{/if}}
  
  */
  {
    "id": "de2FnSDL",
    "block": "[[[1,\"\\n\"],[41,[30,1,[\"length\"]],[[[1,\"    \"],[10,0],[14,0,\"unanswered-sidebar\"],[12],[1,\"\\n      \"],[10,\"h3\"],[14,0,\"cell-title\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"agc.homepage.sidebar.unanswered.headline\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"unanswered-topics\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,1]],null]],null],null,[[[1,\"          \"],[10,0],[14,0,\"unanswered-topic\"],[12],[1,\"\\n            \"],[10,3],[15,6,[30,2,[\"url\"]]],[12],[1,\"\\n              \"],[10,\"h4\"],[12],[1,[28,[32,1],[[30,2,[\"fancy_title\"]]],null]],[13],[1,\"\\n\"],[41,[30,2,[\"excerpt\"]],[[[1,\"                \"],[10,2],[12],[1,[28,[32,1],[[30,2,[\"excerpt\"]]],null]],[13],[1,\"\\n\"]],[]],null],[1,\"            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@gcShareYourExpertise\",\"topic\"],false,[\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/gc-share-your-expertise.js",
    "scope": () => [_i18n.default, _template.htmlSafe],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "gc-share-your-expertise"));
});