define("discourse/plugins/discourse-global-communities/discourse/routes/idf-index", ["exports", "@ember/object", "@ember/runloop", "discourse/lib/ajax", "discourse/models/topic", "discourse/routes/discourse"], function (_exports, _object, _runloop, _ajax, _topic, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend(dt7948.p({
    model() {
      return (0, _ajax.ajax)("/idf.json").then(result => {
        const topicsById = {};
        result.topics = result.topics.map(topic => {
          topic = _topic.default.create(topic);
          topicsById[topic.id] = topic;
          return topic;
        });
        result.events = result.events.map(event => {
          event.topic = topicsById[event.post.topic.id];
          return _object.default.create(event);
        });
        return _object.default.create(result);
      });
    },
    setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties({
        loadingTopic: false,
        disabled: false
      });
    },
    activate() {
      (0, _runloop.schedule)("afterRender", () => document.querySelector("html").classList.add("idf", "idf-index"));
    },
    deactivate() {
      document.querySelector("html").classList.remove("idf", "idf-index");
    },
    refreshModel() {
      this.refresh();
    }
  }, [["method", "refreshModel", [_object.action]]]));
});