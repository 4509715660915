define("discourse/plugins/discourse-global-communities/discourse/connectors/topic-above-post-stream/idf-content", ["exports", "discourse/lib/utilities", "discourse-common/utils/decorators"], function (_exports, _utilities, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent() {
      if ((0, _utilities.defaultHomepage)() !== "idf") {
        return;
      }
      this.reopen(dt7948.p({
        _updateIdfClasses() {
          const categories = this.siteSettings.idf_category.split("|").map(i => parseInt(i, 10));
          if (!categories.includes(this.model.category_id)) {
            document.documentElement.classList.remove("idf");
            document.documentElement.classList.remove("idf-topic");
            return;
          }
          document.documentElement.classList.add("idf");
          document.documentElement.classList.add("idf-topic");
        }
      }, [["method", "_updateIdfClasses", [(0, _decorators.observes)("model.category_id")]]]));
      this._updateIdfClasses();
    },
    teardownComponent() {
      document.documentElement.classList.remove("idf");
      document.documentElement.classList.remove("idf-topic");
    }
  };
});