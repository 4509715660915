define("discourse/plugins/discourse-global-communities/discourse/lib/agc-notification", ["exports", "discourse/lib/formatter", "discourse/lib/notification-types/base"], function (_exports, _formatter, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _base.default {
    get description() {
      return (0, _formatter.relativeAge)(new Date(this.notification.created_at));
    }
  }
  _exports.default = _default;
});