define("discourse/plugins/discourse-global-communities/discourse/connectors/panel-body-bottom/agc-panel-bottom-buttons", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/plugins/discourse-global-communities/discourse/lib/analytics-annotator"], function (_exports, _component, _object, _service, _analyticsAnnotator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AGCPanelBodyBottom extends _component.default {
    static shouldRender(args, context) {
      return context.currentUser?.agcFeatureFlags?.enhanced_notifications_enabled;
    }
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    get showAllHref() {
      return `${this.currentUser.path}/notifications`;
    }
    onClick() {
      (0, _analyticsAnnotator.triggerEnhancedNotificationsAnalytics)("btn_click", this.appEvents, {
        button: "notif_center_see_more"
      });
      this.router.transitionTo(this.showAllHref);
    }
    static #_4 = (() => dt7948.n(this.prototype, "onClick", [_object.action]))();
  }
  _exports.default = AGCPanelBodyBottom;
});