define("discourse/plugins/discourse-global-communities/discourse/services/agc-api", ["exports", "@ember/service", "discourse/plugins/discourse-global-communities/discourse/controllers/preferences-agc-home-feed"], function (_exports, _service, _preferencesAgcHomeFeed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcApiService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "agcAnalyticsEvents", [_service.service]))();
    #agcAnalyticsEvents = (() => (dt7948.i(this, "agcAnalyticsEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    async saveCurrentUserDefaultHomeFeedSorting(value, analyticsOrigin) {
      if (!_preferencesAgcHomeFeed.DEFAULT_SORTING_OPTIONS.includes(value)) {
        throw new Error("Invalid value: " + value);
      }
      const model = this.currentUser;
      model.set("user_option.agc_default_home_feed_sorting", value);
      const result = await model.save([_preferencesAgcHomeFeed.AGC_DEFAULT_HOME_FEED_SORTING]);

      // analytics
      this.agcAnalyticsEvents.logUserDefaultHomeFeedSorting(model, value, analyticsOrigin);
      return result;
    }
  }
  _exports.default = AgcApiService;
});