define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/menu-item-end/agc-notifications-secondary-dropdown", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <span class="agc-secondary-actions-dropdown">
    <DMenu
      @icon="ellipsis-h"
      @identifier="agc-secondary-actions-dropdown"
      @placement="bottom-end"
    >
      <:content as |args|>
        <NotificationsActionsDropdown
          @close={{args.close}}
          @notification={{outletArgs.notification}}
        />
      </:content>
    </DMenu>
  </span>
  */
  {
    "id": "e26Ej34q",
    "block": "[[[10,1],[14,0,\"agc-secondary-actions-dropdown\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@icon\",\"@identifier\",\"@placement\"],[\"ellipsis-h\",\"agc-secondary-actions-dropdown\",\"bottom-end\"]],[[\"content\"],[[[[1,\"\\n      \"],[8,[39,1],null,[[\"@close\",\"@notification\"],[[30,1,[\"close\"]],[30,0,[\"outletArgs\",\"notification\"]]]],null],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `outletArgs` property path was used in the `discourse/plugins/discourse-global-communities/discourse/templates/connectors/menu-item-end/agc-notifications-secondary-dropdown.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.outletArgs}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"args\"],false,[\"d-menu\",\"notifications-actions-dropdown\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/menu-item-end/agc-notifications-secondary-dropdown.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});