define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/topic-above-post-stream/idf-content", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! Used by `idf-content` initializer }}
  */
  {
    "id": "sgnAtaM4",
    "block": "[[],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/topic-above-post-stream/idf-content.hbs",
    "isStrictMode": false
  });
});