define("discourse/plugins/discourse-global-communities/discourse/components/program-pillars-side-widget-footer", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/components/d-button", "discourse/plugins/discourse-global-communities/discourse/components/program-pillars/side-widget-topic-link", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _dButton, _sideWidgetTopicLink, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ProgramPillarsSideWidgetPostBadge extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "expanded", [_tracking.tracked], function () {
      return false;
    }))();
    #expanded = (() => (dt7948.i(this, "expanded"), void 0))();
    toggleExpanded() {
      this.expanded = !this.expanded;
    }
    static #_2 = (() => dt7948.n(this.prototype, "toggleExpanded", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="program-pillars-side-widget-footer-content">
          {{#if this.expanded}}
            {{#each @topics as |topic|}}
              <SideWidgetTopicLink @topic={{topic}} @tagName={{@tagName}} />
            {{/each}}
          {{/if}}
    
          <DButton
            class="btn-transparent program-pillars-side-widget-footer-btn"
            data-expanded={{this.expanded}}
            @action={{this.toggleExpanded}}
            @label={{if
              this.expanded
              "program_pillars.see_less"
              "program_pillars.see_all"
            }}
          />
        </div>
      
    */
    {
      "id": "IdtOcBIA",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"program-pillars-side-widget-footer-content\"],[12],[1,\"\\n\"],[41,[30,0,[\"expanded\"]],[[[42,[28,[31,2],[[28,[31,2],[[30,1]],null]],null],null,[[[1,\"          \"],[8,[32,0],null,[[\"@topic\",\"@tagName\"],[[30,2],[30,3]]],null],[1,\"\\n\"]],[2]],null]],[]],null],[1,\"\\n      \"],[8,[32,1],[[24,0,\"btn-transparent program-pillars-side-widget-footer-btn\"],[16,\"data-expanded\",[30,0,[\"expanded\"]]]],[[\"@action\",\"@label\"],[[30,0,[\"toggleExpanded\"]],[52,[30,0,[\"expanded\"]],\"program_pillars.see_less\",\"program_pillars.see_all\"]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@topics\",\"topic\",\"@tagName\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/program-pillars-side-widget-footer.js",
      "scope": () => [_sideWidgetTopicLink.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ProgramPillarsSideWidgetPostBadge;
});