define("discourse/plugins/discourse-global-communities/discourse/services/agc-toasts", ["exports", "@ember/service", "discourse/plugins/discourse-global-communities/discourse/components/agc-toasts/discourse-notifications-disabled-toast", "discourse/plugins/discourse-global-communities/discourse/components/agc-toasts/notification-preferences-os-toast", "discourse/plugins/discourse-global-communities/discourse/components/agc-toasts/notified-post-toast", "discourse/plugins/discourse-global-communities/discourse/components/agc-toasts/os-and-discourse-notifications-disabled-toast", "discourse/plugins/discourse-global-communities/discourse/components/agc-toasts/os-notifications-disabled-toast"], function (_exports, _service, _discourseNotificationsDisabledToast, _notificationPreferencesOsToast, _notifiedPostToast, _osAndDiscourseNotificationsDisabledToast, _osNotificationsDisabledToast) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_TOAST_ARGS = {
    duration: 6000,
    autoClose: true,
    forceAutoClose: true,
    class: "agc-toast"
  };
  class AgcToastsService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "toasts", [_service.service]))();
    #toasts = (() => (dt7948.i(this, "toasts"), void 0))();
    _activeToast;
    toastArgs(args) {
      return Object.assign({}, DEFAULT_TOAST_ARGS, args);
    }
    showToast(args) {
      if (this._activeToast) {
        this.toasts.close(this._activeToast);
      }
      this._activeToast = this.toasts.show(this.toastArgs(args));
    }
    showSuccessTopicListNotifyToggleToast(opts) {
      this.showToast({
        class: `agc-toast notified-post-toast ${opts.notified ? "-success" : "-info"}`,
        component: _notifiedPostToast.default,
        data: {
          notified: opts.notified
        }
      });
    }
    showOsAndDiscourseNotificationsDisabledToast() {
      if (this.toastSnoozed(_osAndDiscourseNotificationsDisabledToast.SNOOZE_KEY)) {
        return this.showSuccessTopicListNotifyToggleToast({
          notified: true
        });
      }
      this.showToast({
        class: "agc-toast os-and-notifications-disabled-toast -info",
        component: _osAndDiscourseNotificationsDisabledToast.default
      });
    }
    showDiscourseNotificationsDisabledToast() {
      if (this.toastSnoozed(_discourseNotificationsDisabledToast.SNOOZE_KEY)) {
        return this.showSuccessTopicListNotifyToggleToast({
          notified: true
        });
      }
      this.showToast({
        class: "agc-toast discourse-notifications-disabled-toast -info",
        component: _discourseNotificationsDisabledToast.default
      });
    }
    showOsNotificationsDisabledToast() {
      if (this.toastSnoozed(_osNotificationsDisabledToast.SNOOZE_KEY)) {
        return this.showSuccessTopicListNotifyToggleToast({
          notified: true
        });
      }
      this.showToast({
        class: "agc-toast os-notifications-disabled-toast -info",
        component: _osNotificationsDisabledToast.default
      });
    }
    showNotificationPreferencesOsToast() {
      this.showToast({
        class: "agc-toast notification-preferences-os-toast -info",
        component: _notificationPreferencesOsToast.default
      });
    }
    toastSnoozed(key) {
      let value = localStorage.getItem(key);

      // If date is not stored, we are not snoozed
      if (!value) {
        return false;
      }

      // Parse date from localStorage and add 30 days
      let date = new Date(Date.parse(value));
      date.setDate(date.getDate() + 30);

      // If snooze date until date is in the future, we are snoozed!
      return date > new Date();
    }
  }
  _exports.default = AgcToastsService;
});