define("discourse/plugins/discourse-global-communities/discourse/connectors/above-user-profile/user-analytics", ["exports", "discourse/plugins/discourse-global-communities/discourse/lib/analytics-annotator"], function (_exports, _analyticsAnnotator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      component.setProperties({
        didInsertElement() {
          this._super(...arguments);
          document.querySelectorAll(".user-nav li a").forEach(link => {
            const navName = link.href.match(/([^\/]*?)\/?(?:\?.*)?$/)[1];
            (0, _analyticsAnnotator.setAnalyticsRef)(link, `user_nav_${navName}`);
          });
        }
      });
    }
  };
});