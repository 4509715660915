define("discourse/plugins/discourse-global-communities/discourse/components/agc/user-profile/activity-nav", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <MobileNav
    @desktopClass="action-list activity-list nav-stacked"
    @currentPath={{@currentPath}}
    class="activity-nav"
  >
    <li>
      <LinkTo @route="userActivity.index">
        {{i18n "user.filters.all"}}
      </LinkTo>
    </li>
  
    <li>
      <LinkTo @route="userActivity.topics">
        {{i18n "user_action_groups.4"}}
      </LinkTo>
    </li>
  
    <li>
      <LinkTo @route="userActivity.replies">
        {{i18n "user_action_groups.5"}}
      </LinkTo>
    </li>
  
    {{#if @model.showDrafts}}
      <li>
        <LinkTo @route="userActivity.drafts">
          {{i18n "user_action_groups.15"}}
        </LinkTo>
      </li>
    {{/if}}
  
    <li>
      <LinkTo @route="userActivity.likesGiven">
        {{i18n "user_action_groups.1"}}
      </LinkTo>
    </li>
  
    {{#if @model.showBookmarks}}
      <li>
        <LinkTo @route="userActivity.bookmarks">
          {{i18n "user_action_groups.3"}}
        </LinkTo>
      </li>
    {{/if}}
  
    <PluginOutlet
      @name="user-activity-bottom"
      @connectorTagName="li"
      @outletArgs={{hash model=@model}}
    />
  </MobileNav>
  */
  {
    "id": "2ajSivRw",
    "block": "[[[8,[39,0],[[24,0,\"activity-nav\"]],[[\"@desktopClass\",\"@currentPath\"],[\"action-list activity-list nav-stacked\",[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[10,\"li\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@route\"],[\"userActivity.index\"]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,2],[\"user.filters.all\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"li\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@route\"],[\"userActivity.topics\"]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,2],[\"user_action_groups.4\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"li\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@route\"],[\"userActivity.replies\"]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,2],[\"user_action_groups.5\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,2,[\"showDrafts\"]],[[[1,\"    \"],[10,\"li\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@route\"],[\"userActivity.drafts\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,2],[\"user_action_groups.15\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,\"li\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@route\"],[\"userActivity.likesGiven\"]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,2],[\"user_action_groups.1\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,2,[\"showBookmarks\"]],[[[1,\"    \"],[10,\"li\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@route\"],[\"userActivity.bookmarks\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,2],[\"user_action_groups.3\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[8,[39,4],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\"],[\"user-activity-bottom\",\"li\",[28,[37,5],null,[[\"model\"],[[30,2]]]]]],null],[1,\"\\n\"]],[]]]]]],[\"@currentPath\",\"@model\"],false,[\"mobile-nav\",\"link-to\",\"i18n\",\"if\",\"plugin-outlet\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/agc/user-profile/activity-nav.hbs",
    "isStrictMode": false
  });
  class AgcUserProfileActivityNav extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    get currentPath() {
      return this.router._router.currentPath;
    }
  }
  _exports.default = AgcUserProfileActivityNav;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AgcUserProfileActivityNav);
});