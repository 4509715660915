define("discourse/plugins/discourse-global-communities/discourse/components/circles/simplified-navigation-header", ["exports", "@glimmer/component", "@ember/helper", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse-common/helpers/i18n", "discourse-i18n", "discourse/plugins/discourse-circles/discourse/components/circles/search-fields/text", "discourse/plugins/discourse-global-communities/discourse/components/gc-input-with-icon", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _object, _service, _dButton, _i18n, _discourseI18n, _text, _gcInputWithIcon, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GcCircleConnectActions extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "circles", [_service.service]))();
    #circles = (() => (dt7948.i(this, "circles"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    get controller() {
      return this.args.controller || this.args.outletArgs.controller;
    }
    get title() {
      if (this.router.currentRouteName === "connect" || this.isUserProfile) {
        return _discourseI18n.default.t("discourse_circles.index.title");
      } else if (this.type) {
        return _discourseI18n.default.t(`discourse_circles.index.types.${this.type}`);
      } else {
        return _discourseI18n.default.t("discourse_circles.index.all");
      }
    }
    get isUserProfile() {
      return !!this.args.outletArgs?.isUserProfile;
    }
    get isCreateNewCircleBtnVisible() {
      return this.circles.userCanCreateCircles;
    }
    get type() {
      return this.controller?.type;
    }
    newCircle() {
      this.appEvents.trigger("enhanced_user_analytics:event", "circles_new_button_click");
      this.router.transitionTo("circles.new");
    }
    static #_4 = (() => dt7948.n(this.prototype, "newCircle", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#unless this.isUserProfile}}
          <div class="connect-wrapper-controls directory-wrapper">
            <div class="heading">
              <h2 class="circles-section-title">
                {{this.title}}
              </h2>
              {{#if this.isCreateNewCircleBtnVisible}}
                <DButton
                  @action={{this.newCircle}}
                  @icon="plus"
                  @label="discourse_circles.new.title"
                  class="circles-new-button btn-default"
                />
              {{/if}}
            </div>
            <div class="controls">
              <GcInputWithIcon @icon="search">
                <TextSearchField
                  class="circles-search-filter-name filter"
                  @placeholder={{i18n "discourse_circles.index.filter"}}
                  @controller={{this.controller}}
                  @filter={{hash name="filter" queryParameter="filter" type="text"}}
                  @value={{this.controller.filter}}
                />
              </GcInputWithIcon>
            </div>
          </div>
        {{/unless}}
      
    */
    {
      "id": "6viu1Mms",
      "block": "[[[1,\"\\n\"],[41,[51,[30,0,[\"isUserProfile\"]]],[[[1,\"      \"],[10,0],[14,0,\"connect-wrapper-controls directory-wrapper\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"heading\"],[12],[1,\"\\n          \"],[10,\"h2\"],[14,0,\"circles-section-title\"],[12],[1,\"\\n            \"],[1,[30,0,[\"title\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"],[41,[30,0,[\"isCreateNewCircleBtnVisible\"]],[[[1,\"            \"],[8,[32,0],[[24,0,\"circles-new-button btn-default\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"newCircle\"]],\"plus\",\"discourse_circles.new.title\"]],null],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@icon\"],[\"search\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[32,2],[[24,0,\"circles-search-filter-name filter\"]],[[\"@placeholder\",\"@controller\",\"@filter\",\"@value\"],[[28,[32,3],[\"discourse_circles.index.filter\"],null],[30,0,[\"controller\"]],[28,[32,4],null,[[\"name\",\"queryParameter\",\"type\"],[\"filter\",\"filter\",\"text\"]]],[30,0,[\"controller\",\"filter\"]]]],null],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"unless\",\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/circles/simplified-navigation-header.js",
      "scope": () => [_dButton.default, _gcInputWithIcon.default, _text.default, _i18n.default, _helper.hash],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = GcCircleConnectActions;
});