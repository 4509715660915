define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/above-topic-list/wywa-carousel", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="wywa-carousel" {{did-insert this.loadTopics}}>
    {{#if this.visible}}
      <div class="carousel-title">
        {{i18n this.carouselTitleKey count=this.topicsSinceDays}}
      </div>
  
      <Agc::Carousel
        @loading={{this.loading}}
        @items={{this.topics}}
        @tileType="topic"
        @registerAnalytics={{true}}
      />
    {{/if}}
  </div>
  */
  {
    "id": "tM0ezBCL",
    "block": "[[[11,0],[24,0,\"wywa-carousel\"],[4,[38,0],[[30,0,[\"loadTopics\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"visible\"]],[[[1,\"    \"],[10,0],[14,0,\"carousel-title\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[[30,0,[\"carouselTitleKey\"]]],[[\"count\"],[[30,0,[\"topicsSinceDays\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@loading\",\"@items\",\"@tileType\",\"@registerAnalytics\"],[[30,0,[\"loading\"]],[30,0,[\"topics\"]],\"topic\",true]],null],[1,\"\\n\"]],[]],null],[13]],[],false,[\"did-insert\",\"if\",\"i18n\",\"agc/carousel\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/above-topic-list/wywa-carousel.hbs",
    "isStrictMode": false
  });
});