define("discourse/plugins/discourse-global-communities/discourse/controllers/user-amazon-contacts", ["exports", "@ember/controller", "discourse-common/lib/avatar-utils"], function (_exports, _controller, _avatarUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserAmazonContacts extends _controller.default {
    get availableGroups() {
      if (!this.model.groups) {
        return [];
      }
      return Object.entries(this.model.groups).map(_ref => {
        let [id, items] = _ref;
        return {
          id,
          items: items.map(item => ({
            ...item,
            avatar: this.#contactAvatar(item)
          }))
        };
      });
    }
    get primaryGroup() {
      return this.model.primary_group;
    }
    #contactAvatar(item) {
      if (!item.email) {
        return null;
      }
      const avatarTemplate = this.model.avatar_templates?.[item.email.toLowerCase()];
      if (!avatarTemplate) {
        return null;
      }
      return (0, _avatarUtils.avatarImg)({
        avatarTemplate,
        size: "medium"
      });
    }
  }
  _exports.default = UserAmazonContacts;
});