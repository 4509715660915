define("discourse/plugins/discourse-global-communities/discourse/components/filter-select", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/modifier", "@ember/object", "@ember/service", "truth-helpers", "discourse/helpers/concat-class", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _modifier, _object, _service, _truthHelpers, _concatClass, _dIcon, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CONTAINER_CLASS = "filter-select-container";
  const OPTION_PREFIX = "filter-select-option";
  const SCROLL_BEHAVIOR = {
    behavior: "smooth",
    block: "nearest",
    inline: "center"
  };
  class FilterSelect extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "selected", [_tracking.tracked], function () {
      return this.args.defaultOption || "";
    }))();
    #selected = (() => (dt7948.i(this, "selected"), void 0))();
    selectOption(id) {
      const newTagId = this.selected === id ? this.args.defaultOption || "" : id;
      this.selected = newTagId;
      this.appEvents.trigger("agc-custom:filter-select", {
        scope: this.args.scope,
        selected: newTagId
      });
      if (this.site.mobileView) {
        this.handleScroll(newTagId);
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "selectOption", [_object.action]))();
    handleScroll(newTagId) {
      const selected = newTagId || this.args.defaultOption;
      if (selected) {
        return document.getElementById(`${OPTION_PREFIX}-${selected}`)?.scrollIntoView(SCROLL_BEHAVIOR);
      }
      // Same filter was selected but no default - scroll all the way to the left
      document.querySelector(`.${CONTAINER_CLASS}`).scrollLeft = 0;
    }
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class={{CONTAINER_CLASS}}>
          {{#each @options as |option|}}
            <button
              id={{concat OPTION_PREFIX "-" option.id}}
              class={{concatClass
                OPTION_PREFIX
                (if (eq this.selected option.id) "selected")
              }}
              {{on "click" (fn this.selectOption option.id)}}
              type="button"
            >
              {{#if (eq this.selected option.id)}}
                {{icon "check"}}
              {{/if}}
              <p>{{option.label}}</p>
              {{#if @showCount}}
                {{#if (gt option.count 100)}}
                  <p>(100+)</p>
                {{else}}
                  <p>({{option.count}})</p>
                {{/if}}
              {{/if}}
            </button>
          {{/each}}
        </div>
      
    */
    {
      "id": "fJmBP0Up",
      "block": "[[[1,\"\\n    \"],[10,0],[15,0,[32,0]],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1]],null]],null],null,[[[1,\"        \"],[11,\"button\"],[16,1,[28,[32,1],[[32,2],\"-\",[30,2,[\"id\"]]],null]],[16,0,[28,[32,3],[[32,2],[52,[28,[32,4],[[30,0,[\"selected\"]],[30,2,[\"id\"]]],null],\"selected\"]],null]],[24,4,\"button\"],[4,[32,5],[\"click\",[28,[32,6],[[30,0,[\"selectOption\"]],[30,2,[\"id\"]]],null]],null],[12],[1,\"\\n\"],[41,[28,[32,4],[[30,0,[\"selected\"]],[30,2,[\"id\"]]],null],[[[1,\"            \"],[1,[28,[32,7],[\"check\"],null]],[1,\"\\n\"]],[]],null],[1,\"          \"],[10,2],[12],[1,[30,2,[\"label\"]]],[13],[1,\"\\n\"],[41,[30,3],[[[41,[28,[32,8],[[30,2,[\"count\"]],100],null],[[[1,\"              \"],[10,2],[12],[1,\"(100+)\"],[13],[1,\"\\n\"]],[]],[[[1,\"              \"],[10,2],[12],[1,\"(\"],[1,[30,2,[\"count\"]]],[1,\")\"],[13],[1,\"\\n\"]],[]]]],[]],null],[1,\"        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@options\",\"option\",\"@showCount\"],false,[\"each\",\"-track-array\",\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/filter-select.js",
      "scope": () => [CONTAINER_CLASS, _helper.concat, OPTION_PREFIX, _concatClass.default, _truthHelpers.eq, _modifier.on, _helper.fn, _dIcon.default, _truthHelpers.gt],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = FilterSelect;
});