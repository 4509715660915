define("discourse/plugins/discourse-global-communities/discourse/connectors/user-preferences-nav/agc-home-feed-nav", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserNavPreferencesAgcHomeFeed extends _component.default {
    static shouldRender(args, context, owner) {
      return owner.lookup("service:current-user")?.agcFeatureFlags["enable_user_option_default_sorting"];
    }
  }
  _exports.default = UserNavPreferencesAgcHomeFeed;
});