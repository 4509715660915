define("discourse/plugins/discourse-global-communities/discourse/components/notification-preferences-toggle", ["exports", "@ember/component", "@ember/service", "discourse/lib/notification-levels", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _service, _notificationLevels, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TOGGLE_TYPE_CONFIGS = _exports.PARENT_MOBILE_PUSH_USER_OPTION = _exports.PARENT_EMAIL_USER_OPTION = _exports.NOTIFICATIONS_PREFERENCE_EVENT_TYPE = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#unless @hidePushRow}}
    <PreferenceToggle
      @icon={{this.pushIcon}}
      @label={{this.pushLabel}}
      @subtitle={{this.pushSubtitle}}
      @shouldShowSubtitle={{true}}
      @shouldShowSubToggle={{this.showSound}}
      @hideRadioButtons={{true}}
      @outletArgs={{this.outletArgs}}
      @subToggleLabel={{this.subToggleLabel}}
      @subToggleSubtitle={{this.subToggleSubtitle}}
      @userOptionField={{this.pushUserOptionField}}
      @subToggleUserOptionField={{this.subToggleUserOptionField}}
      @subToggleOptions={{this.chatSoundOptions}}
      @toggleConfig={{this.pushToggleConfig}}
      @subToggleConfig={{this.soundToggleConfig}}
      @eventType={{this.eventType}}
      @isDisabled={{this.isPushDisabled}}
      @toggleState={{this.pushToggleState}}
      @isParentToggle={{this.isParentToggle}}
      @appEvent="push"
      @overrideAction={{this.overridePushAction}}
      @afterToggleOn={{@afterTogglePushOn}}
    />
  {{/unless}}
  <PreferenceToggle
    @subtitleClass={{this.subtitleClass}}
    @icon={{this.emailIcon}}
    @label={{i18n "notifications.preferences.email"}}
    @subtitle={{this.emailSubtitle}}
    @shouldShowSubtitle={{this.shouldShowSubtitle}}
    @outletArgs={{this.outletArgs}}
    @hideRadioButtons={{this.hideFrequency}}
    @frequencyLabel="notifications.preferences.email_frequency"
    @userOptionField={{this.emailUserOptionField}}
    @options={{this.frequencyOptions}}
    @toggleConfig={{this.emailToggleConfig}}
    @eventType={{this.eventType}}
    @isDisabled={{this.isEmailDisabled}}
    @toggleState={{this.emailToggleState}}
    @isParentToggle={{this.isParentToggle}}
    @appEvent="email"
  />
  */
  {
    "id": "AW95lpJ7",
    "block": "[[[41,[51,[30,1]],[[[1,\"  \"],[8,[39,1],null,[[\"@icon\",\"@label\",\"@subtitle\",\"@shouldShowSubtitle\",\"@shouldShowSubToggle\",\"@hideRadioButtons\",\"@outletArgs\",\"@subToggleLabel\",\"@subToggleSubtitle\",\"@userOptionField\",\"@subToggleUserOptionField\",\"@subToggleOptions\",\"@toggleConfig\",\"@subToggleConfig\",\"@eventType\",\"@isDisabled\",\"@toggleState\",\"@isParentToggle\",\"@appEvent\",\"@overrideAction\",\"@afterToggleOn\"],[[30,0,[\"pushIcon\"]],[30,0,[\"pushLabel\"]],[30,0,[\"pushSubtitle\"]],true,[30,0,[\"showSound\"]],true,[30,0,[\"outletArgs\"]],[30,0,[\"subToggleLabel\"]],[30,0,[\"subToggleSubtitle\"]],[30,0,[\"pushUserOptionField\"]],[30,0,[\"subToggleUserOptionField\"]],[30,0,[\"chatSoundOptions\"]],[30,0,[\"pushToggleConfig\"]],[30,0,[\"soundToggleConfig\"]],[30,0,[\"eventType\"]],[30,0,[\"isPushDisabled\"]],[30,0,[\"pushToggleState\"]],[30,0,[\"isParentToggle\"]],\"push\",[30,0,[\"overridePushAction\"]],[30,2]]],null],[1,\"\\n\"]],[]],null],[8,[39,1],null,[[\"@subtitleClass\",\"@icon\",\"@label\",\"@subtitle\",\"@shouldShowSubtitle\",\"@outletArgs\",\"@hideRadioButtons\",\"@frequencyLabel\",\"@userOptionField\",\"@options\",\"@toggleConfig\",\"@eventType\",\"@isDisabled\",\"@toggleState\",\"@isParentToggle\",\"@appEvent\"],[[30,0,[\"subtitleClass\"]],[30,0,[\"emailIcon\"]],[28,[37,2],[\"notifications.preferences.email\"],null],[30,0,[\"emailSubtitle\"]],[30,0,[\"shouldShowSubtitle\"]],[30,0,[\"outletArgs\"]],[30,0,[\"hideFrequency\"]],\"notifications.preferences.email_frequency\",[30,0,[\"emailUserOptionField\"]],[30,0,[\"frequencyOptions\"]],[30,0,[\"emailToggleConfig\"]],[30,0,[\"eventType\"]],[30,0,[\"isEmailDisabled\"]],[30,0,[\"emailToggleState\"]],[30,0,[\"isParentToggle\"]],\"email\"]],null]],[\"@hidePushRow\",\"@afterTogglePushOn\"],false,[\"unless\",\"preference-toggle\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/notification-preferences-toggle.hbs",
    "isStrictMode": false
  });
  const NOTIFICATION_WATCHING_LEVEL = _notificationLevels.topicLevels.find(tl => tl.key === "watching")?.id;
  const NOTIFICATION_REGULAR_LEVEL = _notificationLevels.topicLevels.find(tl => tl.key === "regular")?.id;
  const EMAIL_LEVEL_ALWAYS = 0;
  const EMAIL_LEVEL_NEVER = 2;
  const EMAIL_FREQUENCY_NEVER = "never";
  const EMAIL_FREQUENCY_WHEN_AWAY = "when_away";
  const EMAIL_FREQUENCY_ALWAYS = "always";
  const EMAIL_FREQUENCY_WEEKLY = "weekly";
  const CHAT_SOUND_BELL = "bell";
  const CHAT_SOUND_DING = "ding";
  const CHAT_SOUND_NONE = "";
  const TOGGLE_TYPE_CONFIGS = _exports.TOGGLE_TYPE_CONFIGS = {
    push: {
      enabled: true,
      disabled: false,
      values: [true, false]
    },
    email: {
      enabled: EMAIL_FREQUENCY_ALWAYS,
      disabled: EMAIL_FREQUENCY_NEVER,
      values: [EMAIL_FREQUENCY_ALWAYS, EMAIL_FREQUENCY_WEEKLY, EMAIL_LEVEL_NEVER]
    },
    email_general: {
      enabled: EMAIL_LEVEL_ALWAYS,
      disabled: EMAIL_LEVEL_NEVER,
      values: [EMAIL_LEVEL_ALWAYS, EMAIL_LEVEL_NEVER]
    },
    chat_email: {
      enabled: EMAIL_FREQUENCY_WHEN_AWAY,
      disabled: EMAIL_FREQUENCY_NEVER,
      values: [EMAIL_FREQUENCY_WHEN_AWAY, EMAIL_FREQUENCY_NEVER]
    },
    sound: {
      enabled: CHAT_SOUND_BELL,
      disabled: CHAT_SOUND_NONE,
      values: [CHAT_SOUND_DING, CHAT_SOUND_NONE, CHAT_SOUND_BELL]
    },
    follow: {
      enabled: NOTIFICATION_WATCHING_LEVEL,
      disabled: NOTIFICATION_REGULAR_LEVEL,
      values: [NOTIFICATION_WATCHING_LEVEL, NOTIFICATION_REGULAR_LEVEL]
    }
  };
  const PARENT_EMAIL_USER_OPTION = _exports.PARENT_EMAIL_USER_OPTION = "email_level";
  const PARENT_MOBILE_PUSH_USER_OPTION = _exports.PARENT_MOBILE_PUSH_USER_OPTION = "allow_push_notifications";
  const NOTIFICATIONS_PREFERENCE_EVENT_TYPE = _exports.NOTIFICATIONS_PREFERENCE_EVENT_TYPE = "notifications_preference_change";
  class NotificationPreferencesToggle extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    pushToggleConfig = (() => TOGGLE_TYPE_CONFIGS.push)();
    soundToggleConfig = (() => TOGGLE_TYPE_CONFIGS.sound)();
    eventType = (() => NOTIFICATIONS_PREFERENCE_EVENT_TYPE)();
    frequencyOptions = (() => [{
      id: EMAIL_FREQUENCY_ALWAYS,
      label: _discourseI18n.default.t("notifications.preferences.individual")
    }, {
      id: EMAIL_FREQUENCY_WEEKLY,
      label: _discourseI18n.default.t("notifications.preferences.weekly")
    }])();
    chatSoundOptions = (() => [{
      id: CHAT_SOUND_BELL,
      label: _discourseI18n.default.t("notifications.preferences.bell")
    }, {
      id: CHAT_SOUND_DING,
      label: _discourseI18n.default.t("notifications.preferences.ding")
    }])();
    get pushUserOptionField() {
      if (!this.notificationType) {
        return `allow_${this.site.desktopView ? "browser" : "push"}_notifications`;
      }
      return `allow_${this.notificationType}_${this.site.desktopView ? "browser" : "push"}_notifications`;
    }
    get emailUserOptionField() {
      if (this.notificationType === "chat") {
        return "chat_email_frequency";
      } else if (!this.notificationType) {
        return "email_level";
      }
      return `${this.notificationType}_email_notification_level`;
    }
    get emailToggleConfig() {
      if (this.notificationType === "chat") {
        return TOGGLE_TYPE_CONFIGS.chat_email;
      } else if (!this.notificationType) {
        return TOGGLE_TYPE_CONFIGS.email_general;
      }
      return TOGGLE_TYPE_CONFIGS.email;
    }
  }
  _exports.default = NotificationPreferencesToggle;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, NotificationPreferencesToggle);
});