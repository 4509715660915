define("discourse/plugins/discourse-global-communities/discourse/models/email-preview-notifications-summary", ["exports", "@ember/object", "discourse/lib/ajax"], function (_exports, _object, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.oneWeekAgo = oneWeekAgo;
  class EmailPreviewNotificationsSummary extends _object.default {
    static findNotificationsSummary(username, lastSeenAt, lastAttemptedAt, frequency) {
      return (0, _ajax.ajax)("/admin/plugins/agc/emails/notifications-summary.json", {
        data: {
          last_seen_at: lastSeenAt || oneWeekAgo(),
          last_attempted_at: lastAttemptedAt || oneWeekAgo(),
          frequency: frequency || "weekly",
          username
        }
      }).then(result => EmailPreviewNotificationsSummary.create(result));
    }
    static sendNotificationsSummary(username, lastSeenAt, lastAttemptedAt, frequency, email) {
      return (0, _ajax.ajax)("/admin/plugins/agc/emails/notifications-summary.json", {
        type: "POST",
        data: {
          last_seen_at: lastSeenAt || oneWeekAgo(),
          last_attempted_at: lastAttemptedAt || oneWeekAgo(),
          frequency: frequency || "weekly",
          username,
          email
        }
      });
    }
  }
  _exports.default = EmailPreviewNotificationsSummary;
  function oneWeekAgo() {
    return moment().locale("en").subtract(7, "days").format("YYYY-MM-DD");
  }
});