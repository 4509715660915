define("discourse/plugins/discourse-global-communities/discourse/routes/user-amazon-contacts", ["exports", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserAmazonContacts extends _discourse.default {
    model() {
      const user = this.modelFor("user");
      return (0, _ajax.ajax)(`/u/${user.username}/amazon-contacts.json`);
    }
    setupController(controller, model) {
      controller.setProperties({
        model
      });
    }
  }
  _exports.default = UserAmazonContacts;
});