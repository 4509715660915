define("discourse/plugins/discourse-global-communities/discourse/components/agc/carousel/pagination", ["exports", "@ember/modifier", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _modifier, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Pagination = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="carousel-pagination" ...attributes>
      <button
        {{on "click" @action}}
        type="button"
        disabled={{@disabled}}
        class="control {{if @disabled 'disabled'}}"
      >
        <i class="control-icon"></i>
      </button>
    </div>
  
  */
  {
    "id": "ddcqEtIz",
    "block": "[[[1,\"\\n  \"],[11,0],[24,0,\"carousel-pagination\"],[17,1],[12],[1,\"\\n    \"],[11,\"button\"],[16,\"disabled\",[30,2]],[16,0,[29,[\"control \",[52,[30,2],\"disabled\"]]]],[24,4,\"button\"],[4,[32,0],[\"click\",[30,3]],null],[12],[1,\"\\n      \"],[10,\"i\"],[14,0,\"control-icon\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"&attrs\",\"@disabled\",\"@action\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/carousel/pagination.js",
    "scope": () => [_modifier.on],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "pagination:Pagination"));
  var _default = _exports.default = Pagination;
});