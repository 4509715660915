define("discourse/plugins/discourse-global-communities/discourse/components/gc-aux-login", ["exports", "@ember/component", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/url", "discourse-common/utils/decorators"], function (_exports, _component, _object, _ajax, _ajaxError, _url, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: "gc-aux-login-wrapper",
    showingForm: false,
    loggingIn: false,
    async login() {
      this.set("loggingIn", true);
      try {
        await (0, _ajax.ajax)("/aux-login", {
          type: "POST",
          data: {
            username: this.username,
            password: this.password
          }
        });
        _url.default.redirectTo("/");
      } catch (e) {
        this.set("loggingIn", false);
        (0, _ajaxError.popupAjaxError)(e);
      }
    },
    toggleForm() {
      this.set("showingForm", !this.showingForm);
    },
    keyDown(event) {
      if (event.key === "Enter" && !this.buttonDisabled) {
        this.send("login");
        return false;
      }
    },
    buttonDisabled() {
      return this.loggingIn || !(this.username && this.password);
    }
  }, [["method", "login", [_object.action]], ["method", "toggleForm", [_object.action]], ["method", "keyDown", [_decorators.bind]], ["method", "buttonDisabled", [(0, _decorators.default)("loggingIn", "username", "password")]]]));
});