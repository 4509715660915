define("discourse/plugins/discourse-global-communities/discourse/templates/components/user-fields/dropdown", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <label class="control-label" for={{concat "user-" this.elementId}}>
    {{html-safe this.translatedName}}
    {{#if this.field.required}}
      <span class="required">*</span>
    {{/if}}
  </label>
  
  <div class="controls">
    <ComboBox
      @id={{concat "user-" this.elementId}}
      @content={{this.sortedOptions}}
      @valueProperty={{null}}
      @nameProperty={{null}}
      @value={{this.value}}
      @onChange={{action (mut this.value)}}
      @options={{hash none=this.noneLabel}}
    />
    <div class="instructions">{{html-safe this.translatedDescription}}</div>
  </div>
  */
  {
    "id": "QDwD349w",
    "block": "[[[10,\"label\"],[14,0,\"control-label\"],[15,\"for\",[28,[37,0],[\"user-\",[30,0,[\"elementId\"]]],null]],[12],[1,\"\\n  \"],[1,[28,[35,1],[[30,0,[\"translatedName\"]]],null]],[1,\"\\n\"],[41,[30,0,[\"field\",\"required\"]],[[[1,\"    \"],[10,1],[14,0,\"required\"],[12],[1,\"*\"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n  \"],[8,[39,3],null,[[\"@id\",\"@content\",\"@valueProperty\",\"@nameProperty\",\"@value\",\"@onChange\",\"@options\"],[[28,[37,0],[\"user-\",[30,0,[\"elementId\"]]],null],[30,0,[\"sortedOptions\"]],null,null,[30,0,[\"value\"]],[28,[37,4],[[30,0],[28,[37,5],[[30,0,[\"value\"]]],null]],null],[28,[37,6],null,[[\"none\"],[[30,0,[\"noneLabel\"]]]]]]],null],[1,\"\\n  \"],[10,0],[14,0,\"instructions\"],[12],[1,[28,[35,1],[[30,0,[\"translatedDescription\"]]],null]],[13],[1,\"\\n\"],[13]],[],false,[\"concat\",\"html-safe\",\"if\",\"combo-box\",\"action\",\"mut\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/components/user-fields/dropdown.hbs",
    "isStrictMode": false
  });
});