define("discourse/plugins/discourse-global-communities/discourse/routes/start-chatting", ["exports", "@ember/service", "discourse/routes/discourse", "discourse/plugins/discourse-global-communities/discourse/components/agc/chat/pages/chat-disabled", "discourse/plugins/discourse-global-communities/discourse/components/agc/chat/pages/start-chatting"], function (_exports, _service, _discourse, _chatDisabled, _startChatting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class StartChattingRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "chatChannelsManager", [_service.service]))();
    #chatChannelsManager = (() => (dt7948.i(this, "chatChannelsManager"), void 0))();
    beforeModel() {
      this.appEvents.trigger("chat:toggle-close");
    }
    model() {
      if (!this.currentUser.has_chat_enabled) {
        return {
          pageComponent: _chatDisabled.default
        };
      }
      if (this.chatChannelsManager.channels.length === 0) {
        return {
          pageComponent: _startChatting.default
        };
      }
    }
  }
  _exports.default = StartChattingRoute;
});