define("discourse/plugins/discourse-global-communities/discourse/admin-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: "admin.adminPlugins",
    path: "/plugins",
    map() {
      this.route("agc", function () {
        this.route("program-pillars");
        this.route("contacts");
        this.route("emails", function () {
          this.route("notificationsSummary", {
            path: "/notifications-summary"
          });
        });
      });
    }
  };
});