define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/composer-fields-below/file-upload", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="composer-upload">
    {{#if allowUpload}}
      {{d-button
        class="mobile-file-upload"
        icon="upload-squid"
        label="composer.upload"
        ariaLabel="composer.upload_action"
        disabled=isUploading
        action=(route-action "showUploadSelector")
      }}
    {{/if}}
  </div>
  */
  {
    "id": "2NOpdlTg",
    "block": "[[[10,0],[14,0,\"composer-upload\"],[12],[1,\"\\n\"],[41,[30,0,[\"allowUpload\"]],[[[1,\"    \"],[1,[28,[35,1],null,[[\"class\",\"icon\",\"label\",\"ariaLabel\",\"disabled\",\"action\"],[\"mobile-file-upload\",\"upload-squid\",\"composer.upload\",\"composer.upload_action\",[30,0,[\"isUploading\"]],[28,[37,2],[\"showUploadSelector\"],null]]]]],[1,\"\\n\"]],[]],null],[13],[1,[28,[32,0],[\"[[\\\"The `allowUpload` property path was used in the `discourse/plugins/discourse-global-communities/discourse/templates/connectors/composer-fields-below/file-upload.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.allowUpload}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `isUploading` property path was used in the `discourse/plugins/discourse-global-communities/discourse/templates/connectors/composer-fields-below/file-upload.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.isUploading}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"d-button\",\"route-action\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/composer-fields-below/file-upload.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});