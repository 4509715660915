define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/user-preferences-notifications/notifications", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <AgcNotificationsPreferences @outletArgs={{@outletArgs}} />
  */
  {
    "id": "U1EQ9849",
    "block": "[[[8,[39,0],null,[[\"@outletArgs\"],[[30,1]]],null]],[\"@outletArgs\"],false,[\"agc-notifications-preferences\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/user-preferences-notifications/notifications.hbs",
    "isStrictMode": false
  });
});