define("discourse/plugins/discourse-global-communities/discourse/templates/components/categories-topic-list", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if topics}}
    {{#each topics as |t|}}
      {{topic-list-item topic=t}}
    {{/each}}
  {{else}}
    <div class="no-topics">
      <h3>{{i18n "agc.topic-list.no-topics"}}</h3>
    </div>
  {{/if}}
  */
  {
    "id": "bcB7sdvv",
    "block": "[[[41,[30,0,[\"topics\"]],[[[42,[28,[37,2],[[28,[37,2],[[30,0,[\"topics\"]]],null]],null],null,[[[1,\"    \"],[1,[28,[35,3],null,[[\"topic\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null]],[]],[[[1,\"  \"],[10,0],[14,0,\"no-topics\"],[12],[1,\"\\n    \"],[10,\"h3\"],[12],[1,[28,[35,4],[\"agc.topic-list.no-topics\"],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]],[1,[28,[32,0],[\"[[\\\"The `topics` property path was used in the `discourse/plugins/discourse-global-communities/discourse/templates/components/categories-topic-list.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.topics}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `topics` property path was used in the `discourse/plugins/discourse-global-communities/discourse/templates/components/categories-topic-list.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.topics}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"t\"],false,[\"if\",\"each\",\"-track-array\",\"topic-list-item\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/components/categories-topic-list.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});