define("discourse/plugins/discourse-global-communities/discourse/routes/admin-plugins-agc-contacts", ["exports", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsAgcContactsRoute extends _discourse.default {
    queryParams = {
      groupName: {
        refreshModel: true
      }
    };
    model(params) {
      if (params.groupName) {
        return (0, _ajax.ajax)(`/admin/plugins/agc/contacts.json`, {
          data: {
            group_name: params.groupName
          }
        });
      }
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.setProperties(model);
      controller.loading = false;
    }
  }
  _exports.default = AdminPluginsAgcContactsRoute;
});