define("discourse/plugins/discourse-global-communities/discourse/components/bulk-change-tags", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "truth-helpers", "discourse/components/d-button", "select-kit/components/tag-chooser", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _truthHelpers, _dButton, _tagChooser, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BulkChangeTags extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "tags", [_tracking.tracked], function () {
      return [];
    }))();
    #tags = (() => (dt7948.i(this, "tags"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="bulk-change-tags">
          <p>{{@options.messageLabel}}</p>
    
          <p><TagChooser
              @tags={{this.tags}}
              @everyTag={{true}}
              @useAgcCustomSearch={{true}}
            /></p>
    
          <DButton
            class="confirm-btn"
            @action={{fn @options.action (hash tags=this.tags)}}
            @disabled={{not this.tags}}
            @label={{@options.buttonLabel}}
          />
        </div>
      
    */
    {
      "id": "tuZNO2n0",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"bulk-change-tags\"],[12],[1,\"\\n      \"],[10,2],[12],[1,[30,1,[\"messageLabel\"]]],[13],[1,\"\\n\\n      \"],[10,2],[12],[8,[32,0],null,[[\"@tags\",\"@everyTag\",\"@useAgcCustomSearch\"],[[30,0,[\"tags\"]],true,true]],null],[13],[1,\"\\n\\n      \"],[8,[32,1],[[24,0,\"confirm-btn\"]],[[\"@action\",\"@disabled\",\"@label\"],[[28,[32,2],[[30,1,[\"action\"]],[28,[32,3],null,[[\"tags\"],[[30,0,[\"tags\"]]]]]],null],[28,[32,4],[[30,0,[\"tags\"]]],null],[30,1,[\"buttonLabel\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@options\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/bulk-change-tags.js",
      "scope": () => [_tagChooser.default, _dButton.default, _helper.fn, _helper.hash, _truthHelpers.not],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = BulkChangeTags;
});