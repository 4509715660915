define("discourse/plugins/discourse-global-communities/discourse/templates/components/idf-session-watched", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if watched}}
    <span class="session-watched">
      {{d-icon "far-check-circle"}}
      <span class="watched-info">
        {{html-safe
          (i18n
            "agc.idf.session_watched"
            date=(format-date watched format="medium-with-ago" noTitle=true)
          )
        }}
      </span>
    </span>
  {{else}}
    <span class="unwatched">{{i18n "agc.idf.unwatched"}}</span>
  {{/if}}
  */
  {
    "id": "NfsUvE5p",
    "block": "[[[41,[30,0,[\"watched\"]],[[[1,\"  \"],[10,1],[14,0,\"session-watched\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"far-check-circle\"],null]],[1,\"\\n    \"],[10,1],[14,0,\"watched-info\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[[28,[37,3],[\"agc.idf.session_watched\"],[[\"date\"],[[28,[37,4],[[30,0,[\"watched\"]]],[[\"format\",\"noTitle\"],[\"medium-with-ago\",true]]]]]]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,1],[14,0,\"unwatched\"],[12],[1,[28,[35,3],[\"agc.idf.unwatched\"],null]],[13],[1,\"\\n\"]],[]]],[1,[28,[32,0],[\"[[\\\"The `watched` property path was used in the `discourse/plugins/discourse-global-communities/discourse/templates/components/idf-session-watched.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.watched}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `watched` property path was used in the `discourse/plugins/discourse-global-communities/discourse/templates/components/idf-session-watched.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.watched}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"d-icon\",\"html-safe\",\"i18n\",\"format-date\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/components/idf-session-watched.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});