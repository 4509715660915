define("discourse/plugins/discourse-global-communities/discourse/components/agc/topic-show-more/search-result-entry-expandable-blurb", ["exports", "@ember/modifier", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/will-destroy", "@ember/service", "@ember/template", "discourse/components/highlight-search", "discourse-common/helpers/i18n", "discourse-common/utils/decorators", "discourse/plugins/discourse-global-communities/discourse/components/agc/topic-show-more/topic-list-item-expandable-excerpt", "@ember/component", "@ember/template-factory"], function (_exports, _modifier, _didInsert, _willDestroy, _service, _template, _highlightSearch, _i18n, _decorators, _topicListItemExpandableExcerpt, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SearchResultEntryExpandableBlurb extends _topicListItemExpandableExcerpt.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get isExpandButtonVisible() {
      const featureEnabled = this.currentUser.agcFeatureFlags?.topic_show_more_enabled;
      return featureEnabled && this.args.post.post_cooked_text?.length > 0;
    }
    logToggleExpandedAnalytics(expanded) {
      this.appEvents.trigger("agc-analytics:log-event", "show-more:toggled-expanded", {
        expanded,
        source: "search-results",
        post_id: this.args.post.id
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "logToggleExpandedAnalytics", [_decorators.bind]))();
    logViewportVisibilityAnalytics(state) {
      this.appEvents.trigger("agc-analytics:log-event", "show-more:viewport-tracking", {
        state,
        source: "search-results",
        post_id: this.args.post.id
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "logViewportVisibilityAnalytics", [_decorators.bind]))();
    static #_4 = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{! template-lint-disable modifier-name-case }}
        {{#if this.expanded}}
          <HighlightSearch @highlight={{@highlightQuery}}>
            <article
              data-topic-id={{@post.topic_id}}
              data-post-id={{@post.id}}
              class="topic-show-more__expanded-cooked-text"
              {{didInsert this.postProcessCookedText}}
              {{didInsert this.trackExpandedAnalytics}}
              {{willDestroy this.stopTrackingExpandedAnalytics}}
            >
              {{htmlSafe @post.post_cooked_text}}
            </article>
          </HighlightSearch>
        {{else}}
          {{#if this.siteSettings.use_pg_headlines_for_excerpt}}
            {{htmlSafe @post.blurb}}
          {{else}}
            <HighlightSearch @highlight={{@highlightQuery}}>
              {{htmlSafe @post.blurb}}
            </HighlightSearch>
          {{/if}}
        {{/if}}
        {{#if this.isExpandButtonVisible}}
          <a
            href
            class="topic-show-more__btn {{if this.expanded '--expanded'}}"
            {{on "click" this.toggleExpanded}}
          >
            {{#if this.expanded}}
              {{i18n "discourse_gc.show_less"}}
            {{else}}
              {{i18n "discourse_gc.show_more"}}
            {{/if}}
          </a>
        {{/if}}
      
    */
    {
      "id": "YOVjWx2x",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"expanded\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@highlight\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[11,\"article\"],[16,\"data-topic-id\",[30,2,[\"topic_id\"]]],[16,\"data-post-id\",[30,2,[\"id\"]]],[24,0,\"topic-show-more__expanded-cooked-text\"],[4,[32,1],[[30,0,[\"postProcessCookedText\"]]],null],[4,[32,1],[[30,0,[\"trackExpandedAnalytics\"]]],null],[4,[32,2],[[30,0,[\"stopTrackingExpandedAnalytics\"]]],null],[12],[1,\"\\n          \"],[1,[28,[32,3],[[30,2,[\"post_cooked_text\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"siteSettings\",\"use_pg_headlines_for_excerpt\"]],[[[1,\"        \"],[1,[28,[32,3],[[30,2,[\"blurb\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[32,0],null,[[\"@highlight\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[32,3],[[30,2,[\"blurb\"]]],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]]]],[]]],[41,[30,0,[\"isExpandButtonVisible\"]],[[[1,\"      \"],[11,3],[24,6,\"\"],[16,0,[29,[\"topic-show-more__btn \",[52,[30,0,[\"expanded\"]],\"--expanded\"]]]],[4,[32,4],[\"click\",[30,0,[\"toggleExpanded\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"expanded\"]],[[[1,\"          \"],[1,[28,[32,5],[\"discourse_gc.show_less\"],null]],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[28,[32,5],[\"discourse_gc.show_more\"],null]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@highlightQuery\",\"@post\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/topic-show-more/search-result-entry-expandable-blurb.js",
      "scope": () => [_highlightSearch.default, _didInsert.default, _willDestroy.default, _template.htmlSafe, _modifier.on, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SearchResultEntryExpandableBlurb;
});