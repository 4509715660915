define("discourse/plugins/discourse-global-communities/discourse/connectors/user-notifications-list-bottom/load-more-btn", ["exports", "@glimmer/component", "@ember/object", "discourse/components/d-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _dButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LoadMoreBtn extends _component.default {
    static shouldRender(args, container) {
      return container.currentUser.agcFeatureFlags.enhanced_notifications_enabled && !args.controller.loadMorePressed && args.controller.model.canLoadMore;
    }
    loadMore() {
      this.args.outletArgs.controller.set("loadMorePressed", true);
      this.args.outletArgs.controller.loadMore();
    }
    static #_ = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="load-more-notifications">
          <DButton
            @label="discourse_gc.load_more"
            @action={{this.loadMore}}
            class="btn-primary load-more-notifications__btn"
          />
        </div>
      
    */
    {
      "id": "DaTnRncP",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"load-more-notifications\"],[12],[1,\"\\n      \"],[8,[32,0],[[24,0,\"btn-primary load-more-notifications__btn\"]],[[\"@label\",\"@action\"],[\"discourse_gc.load_more\",[30,0,[\"loadMore\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/connectors/user-notifications-list-bottom/load-more-btn.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = LoadMoreBtn;
});