define("discourse/plugins/discourse-global-communities/discourse/raw-templates/list/gc-byline", ["exports", "discourse-common/lib/raw-handlebars", "discourse-common/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let template = (0, _rawHandlebars.template)({
    "1": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        alias3 = container.hooks.helperMissing,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "  <div class='byline'>\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "topic.circle", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(2, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 3,
            "column": 4
          },
          "end": {
            "line": 5,
            "column": 11
          }
        }
      })) != null ? stack1 : "") + "    <a class=\"topic-list-avatar\" href=\"/u/" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.posters.0.user.username", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 6,
            "column": 42
          },
          "end": {
            "line": 6,
            "column": 77
          }
        }
      })) + "\"\n       data-user-card=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.posters.0.user.username", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 7,
            "column": 23
          },
          "end": {
            "line": 7,
            "column": 58
          }
        }
      })) + "\">\n      " + alias2((lookupProperty(helpers, "avatar") || depth0 && lookupProperty(depth0, "avatar") || alias3).call(alias1, "topic.posters.0.user", {
        "name": "avatar",
        "hash": {
          "imageSize": "small"
        },
        "hashTypes": {
          "imageSize": "StringLiteral"
        },
        "hashContexts": {
          "imageSize": depth0
        },
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 8,
            "column": 6
          },
          "end": {
            "line": 8,
            "column": 57
          }
        }
      })) + "\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "avatarFlair", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(4, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 9,
            "column": 6
          },
          "end": {
            "line": 11,
            "column": 13
          }
        }
      })) != null ? stack1 : "") + "    </a>\n\n    <div>\n      <a class=\"topic-list-user-name\" href=\"/u/" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.posters.0.user.username", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 15,
            "column": 47
          },
          "end": {
            "line": 15,
            "column": 82
          }
        }
      })) + "\"\n         data-user-card=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.posters.0.user.username", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 16,
            "column": 25
          },
          "end": {
            "line": 16,
            "column": 60
          }
        }
      })) + "\">\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "topic.posters.0.user.name", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(6, data, 0),
        "inverse": container.program(8, data, 0),
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 17,
            "column": 8
          },
          "end": {
            "line": 21,
            "column": 15
          }
        }
      })) != null ? stack1 : "") + ((stack1 = lookupProperty(helpers, "if").call(alias1, "isAmazonian", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(10, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 22,
            "column": 8
          },
          "end": {
            "line": 26,
            "column": 15
          }
        }
      })) != null ? stack1 : "") + "      </a>\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "topic.category", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(12, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 28,
            "column": 6
          },
          "end": {
            "line": 30,
            "column": 13
          }
        }
      })) != null ? stack1 : "") + "      <div>\n        <span class=\"divider-text\">" + alias2((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias3).call(alias1, "agc.topic-list.posted-on", {
        "name": "i18n",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 32,
            "column": 35
          },
          "end": {
            "line": 32,
            "column": 70
          }
        }
      })) + "</span> <span class=\"created-at\">\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "relativeCreatedAt", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(14, data, 0),
        "inverse": container.program(16, data, 0),
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 33,
            "column": 8
          },
          "end": {
            "line": 37,
            "column": 15
          }
        }
      })) != null ? stack1 : "") + "      </span>\n      </div>\n    </div>\n    <div class=\"topic-list-item-top-right-wrapper\">\n      " + alias2((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias3).call(alias1, "topic-post-badges", {
        "name": "raw",
        "hash": {
          "url": "topic.lastUnreadUrl",
          "unseen": "topic.unseen",
          "unreadPosts": "topic.unread_posts"
        },
        "hashTypes": {
          "url": "PathExpression",
          "unseen": "PathExpression",
          "unreadPosts": "PathExpression"
        },
        "hashContexts": {
          "url": depth0,
          "unseen": depth0,
          "unreadPosts": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 42,
            "column": 6
          },
          "end": {
            "line": 42,
            "column": 108
          }
        }
      })) + "\n      " + alias2((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias3).call(alias1, "topic-list-options-popup-wrapper", {
        "name": "raw",
        "hash": {
          "topic": "topic"
        },
        "hashTypes": {
          "topic": "PathExpression"
        },
        "hashContexts": {
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 43,
            "column": 6
          },
          "end": {
            "line": 43,
            "column": 60
          }
        }
      })) + "\n    </div>\n  </div>\n";
    },
    "2": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "      " + container.escapeExpression((lookupProperty(helpers, "topic-circle-logo") || depth0 && lookupProperty(depth0, "topic-circle-logo") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "topic", {
        "name": "topic-circle-logo",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 4,
            "column": 6
          },
          "end": {
            "line": 4,
            "column": 33
          }
        }
      })) + "\n";
    },
    "4": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "        <img src=\"" + container.escapeExpression(lookupProperty(helpers, "get").call(depth0 != null ? depth0 : container.nullContext || {}, "avatarFlair", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 10,
            "column": 18
          },
          "end": {
            "line": 10,
            "column": 33
          }
        }
      })) + "\" class=\"agc-avatar-flair\" />\n";
    },
    "6": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "          " + container.escapeExpression(lookupProperty(helpers, "get").call(depth0 != null ? depth0 : container.nullContext || {}, "topic.posters.0.user.name", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 18,
            "column": 10
          },
          "end": {
            "line": 18,
            "column": 41
          }
        }
      })) + "\n";
    },
    "8": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "          " + container.escapeExpression(lookupProperty(helpers, "get").call(depth0 != null ? depth0 : container.nullContext || {}, "topic.posters.0.user.username", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 20,
            "column": 10
          },
          "end": {
            "line": 20,
            "column": 45
          }
        }
      })) + "\n";
    },
    "10": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "          <span class=\"agc-amazonian-check\">\n            " + container.escapeExpression((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "check", {
        "name": "d-icon",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 24,
            "column": 12
          },
          "end": {
            "line": 24,
            "column": 30
          }
        }
      })) + "\n          </span>\n";
    },
    "12": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "        <span class=\"divider-text\">" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, "agc.topic-list.posted-in", {
        "name": "i18n",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 29,
            "column": 35
          },
          "end": {
            "line": 29,
            "column": 70
          }
        }
      })) + "</span> " + alias3((lookupProperty(helpers, "category-link") || depth0 && lookupProperty(depth0, "category-link") || alias2).call(alias1, "topic.category", {
        "name": "category-link",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 29,
            "column": 78
          },
          "end": {
            "line": 29,
            "column": 110
          }
        }
      })) + "\n";
    },
    "14": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "          " + container.escapeExpression(lookupProperty(helpers, "get").call(depth0 != null ? depth0 : container.nullContext || {}, "relativeCreatedAt", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 34,
            "column": 10
          },
          "end": {
            "line": 34,
            "column": 31
          }
        }
      })) + "\n";
    },
    "16": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "          " + container.escapeExpression((lookupProperty(helpers, "formatted") || depth0 && lookupProperty(depth0, "formatted") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "topic.createdAt", {
        "name": "formatted",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 36,
            "column": 10
          },
          "end": {
            "line": 36,
            "column": 39
          }
        }
      })) + "\n";
    },
    "18": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "  <div class='byline circles-byline'>\n    <div class=\"circles-byline-logo\">\n      " + alias3((lookupProperty(helpers, "topic-circle-logo") || depth0 && lookupProperty(depth0, "topic-circle-logo") || alias2).call(alias1, "topic", {
        "name": "topic-circle-logo",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 49,
            "column": 6
          },
          "end": {
            "line": 49,
            "column": 33
          }
        }
      })) + "\n      <a class=\"topic-list-avatar\" href=\"/u/" + alias3(lookupProperty(helpers, "get").call(alias1, "topic.posters.0.user.username", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 50,
            "column": 44
          },
          "end": {
            "line": 50,
            "column": 79
          }
        }
      })) + "\"\n         data-user-card=\"" + alias3(lookupProperty(helpers, "get").call(alias1, "topic.posters.0.user.username", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 51,
            "column": 25
          },
          "end": {
            "line": 51,
            "column": 60
          }
        }
      })) + "\">\n        " + alias3((lookupProperty(helpers, "avatar") || depth0 && lookupProperty(depth0, "avatar") || alias2).call(alias1, "topic.posters.0.user", {
        "name": "avatar",
        "hash": {
          "imageSize": "small"
        },
        "hashTypes": {
          "imageSize": "StringLiteral"
        },
        "hashContexts": {
          "imageSize": depth0
        },
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 52,
            "column": 8
          },
          "end": {
            "line": 52,
            "column": 59
          }
        }
      })) + "\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "avatarFlair", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(19, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 53,
            "column": 8
          },
          "end": {
            "line": 55,
            "column": 15
          }
        }
      })) != null ? stack1 : "") + "      </a>\n    </div>\n    <div>\n      <a class=\"topic-list-circle-name\" href=\"/circles/" + alias3(lookupProperty(helpers, "get").call(alias1, "topic.circle.slug", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 59,
            "column": 55
          },
          "end": {
            "line": 59,
            "column": 76
          }
        }
      })) + "/" + alias3(lookupProperty(helpers, "get").call(alias1, "topic.circle.category_id", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 59,
            "column": 77
          },
          "end": {
            "line": 59,
            "column": 105
          }
        }
      })) + "\">\n        " + alias3(lookupProperty(helpers, "get").call(alias1, "topic.circle.name", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 60,
            "column": 8
          },
          "end": {
            "line": 60,
            "column": 29
          }
        }
      })) + "\n      </a>\n      <div>\n        <a class=\"topic-list-user-name\" href=\"/u/" + alias3(lookupProperty(helpers, "get").call(alias1, "topic.posters.0.user.username", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 63,
            "column": 49
          },
          "end": {
            "line": 63,
            "column": 84
          }
        }
      })) + "\"\n           data-user-card=\"" + alias3(lookupProperty(helpers, "get").call(alias1, "topic.posters.0.user.username", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 64,
            "column": 27
          },
          "end": {
            "line": 64,
            "column": 62
          }
        }
      })) + "\">\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "topic.posters.0.user.name", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(21, data, 0),
        "inverse": container.program(23, data, 0),
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 65,
            "column": 10
          },
          "end": {
            "line": 69,
            "column": 17
          }
        }
      })) != null ? stack1 : "") + ((stack1 = lookupProperty(helpers, "if").call(alias1, "avatarFlair", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(25, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 70,
            "column": 10
          },
          "end": {
            "line": 74,
            "column": 17
          }
        }
      })) != null ? stack1 : "") + "        </a>\n        <span class=\"divider-text\">" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, "agc.topic-list.circle-posted-on", {
        "name": "i18n",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 76,
            "column": 35
          },
          "end": {
            "line": 76,
            "column": 77
          }
        }
      })) + "</span>\n        <span class=\"created-at\">\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "relativeCreatedAt", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(27, data, 0),
        "inverse": container.program(29, data, 0),
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 78,
            "column": 10
          },
          "end": {
            "line": 82,
            "column": 17
          }
        }
      })) != null ? stack1 : "") + "        </span>\n      </div>\n    </div>\n    <div class=\"topic-list-item-top-right-wrapper\">\n      " + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "topic-post-badges", {
        "name": "raw",
        "hash": {
          "url": "topic.lastUnreadUrl",
          "unseen": "topic.unseen",
          "unreadPosts": "topic.unread_posts"
        },
        "hashTypes": {
          "url": "PathExpression",
          "unseen": "PathExpression",
          "unreadPosts": "PathExpression"
        },
        "hashContexts": {
          "url": depth0,
          "unseen": depth0,
          "unreadPosts": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 87,
            "column": 6
          },
          "end": {
            "line": 87,
            "column": 108
          }
        }
      })) + "\n    </div>\n  </div>\n";
    },
    "19": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "          <img src=\"" + container.escapeExpression(lookupProperty(helpers, "get").call(depth0 != null ? depth0 : container.nullContext || {}, "avatarFlair", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 54,
            "column": 20
          },
          "end": {
            "line": 54,
            "column": 35
          }
        }
      })) + "\" class=\"agc-avatar-flair\" />\n";
    },
    "21": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "            " + container.escapeExpression(lookupProperty(helpers, "get").call(depth0 != null ? depth0 : container.nullContext || {}, "topic.posters.0.user.name", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 66,
            "column": 12
          },
          "end": {
            "line": 66,
            "column": 43
          }
        }
      })) + "\n";
    },
    "23": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "            " + container.escapeExpression(lookupProperty(helpers, "get").call(depth0 != null ? depth0 : container.nullContext || {}, "topic.posters.0.user.username", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 68,
            "column": 12
          },
          "end": {
            "line": 68,
            "column": 47
          }
        }
      })) + "\n";
    },
    "25": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "            <span class=\"agc-amazonian-check\">\n              " + container.escapeExpression((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "check", {
        "name": "d-icon",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 72,
            "column": 14
          },
          "end": {
            "line": 72,
            "column": 32
          }
        }
      })) + "\n            </span>\n";
    },
    "27": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "            " + container.escapeExpression(lookupProperty(helpers, "get").call(depth0 != null ? depth0 : container.nullContext || {}, "relativeCreatedAt", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 79,
            "column": 12
          },
          "end": {
            "line": 79,
            "column": 33
          }
        }
      })) + "\n";
    },
    "29": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "            " + container.escapeExpression((lookupProperty(helpers, "formatted") || depth0 && lookupProperty(depth0, "formatted") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "topic.createdAt", {
        "name": "formatted",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 81,
            "column": 12
          },
          "end": {
            "line": 81,
            "column": 41
          }
        }
      })) + "\n";
    },
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return (stack1 = lookupProperty(helpers, "unless").call(depth0 != null ? depth0 : container.nullContext || {}, "topic.circle", {
        "name": "unless",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(1, data, 0),
        "inverse": container.program(18, data, 0),
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 90,
            "column": 11
          }
        }
      })) != null ? stack1 : "";
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("javascripts/list/gc-byline", template, {
    core: true
  });
  var _default = _exports.default = template;
});