define("discourse/plugins/discourse-global-communities/discourse/initializers/generic-video-js", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-global-communities/discourse/helpers/videojs-initializer"], function (_exports, _pluginApi, _videojsInitializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "generic-videojs",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.8.36", api => {
        const appEvents = container.lookup("service:app-events");
        const transformVideos = function (parentEl) {
          if (!parentEl) {
            parentEl = document;
          }
          let videos = parentEl.querySelectorAll("div[data-theme-video='videojs'] video");
          if (!videos.length) {
            return;
          }
          videos.forEach(videoTag => {
            if (!videoTag.classList.contains("video-js") && videoTag.parentElement.dataset.tvVideo === undefined && !videoTag.parentElement.classList.contains("video-js")) {
              (0, _videojsInitializer.default)(videoTag, appEvents);
            }
          });
        };
        transformVideos(document);
        api.decorateCookedElement(element => {
          transformVideos(element);
        }, {
          id: "generic-videojs"
        });
        api.onPageChange(() => {
          transformVideos(document);
        });
      });
    }
  };
});