define("discourse/plugins/discourse-global-communities/discourse/components/agc-toasts/os-notifications-disabled-toast", ["exports", "@ember/object", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/components/agc-toast"], function (_exports, _object, _discourseI18n, _agcToast) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SNOOZE_KEY = void 0;
  const SNOOZE_KEY = _exports.SNOOZE_KEY = "os_notifications_disabled_toast";
  class OsNotificationsDisabledToast extends _agcToast.default {
    get icon() {
      return "info";
    }
    get message() {
      return _discourseI18n.default.t("os_notifications_disabled_toast.message");
    }
    onClickLink(e) {
      e.preventDefault();
      this.args.close();
      window.ReactNativeWebView.postMessage(JSON.stringify({
        goToSettings: true
      }));
      return false;
    }
    static #_ = (() => dt7948.n(this.prototype, "onClickLink", [_object.action]))();
    get showLink() {
      return true;
    }
    get linkHref() {
      return "/my/preferences/notifications";
    }
    get linkText() {
      return _discourseI18n.default.t("os_notifications_disabled_toast.link");
    }
    get afterLinkText() {
      return _discourseI18n.default.t("os_notifications_disabled_toast.after_link");
    }
    get snoozeKey() {
      return SNOOZE_KEY;
    }
  }
  _exports.default = OsNotificationsDisabledToast;
});