define("discourse/plugins/discourse-global-communities/discourse/initializers/idf", ["exports", "discourse/lib/url", "discourse/lib/utilities", "discourse/plugins/discourse-global-communities/discourse/lib/timeout"], function (_exports, _url, _utilities, _timeout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "idf",
    setHomepage(router, homepage) {
      (0, _utilities.setDefaultHomepage)(homepage);
      if (router.currentURL === "/") {
        _url.default.redirectTo("/");
      }
    },
    initialize(container) {
      const currentUser = container.lookup("service:current-user");
      if (!currentUser || !currentUser.can_see_idf) {
        return;
      }
      const siteSettings = container.lookup("service:site-settings");
      const startDate = moment.utc(siteSettings.idf_event_start_date).valueOf();
      const endDate = moment.utc(siteSettings.idf_event_end_date).valueOf();
      const now = moment().valueOf();
      const homepage = (0, _utilities.defaultHomepage)();
      const router = container.lookup("service:router");
      if (startDate > now) {
        (0, _timeout.setTimeoutEx)({
          handler: () => this.setHomepage(router, "idf"),
          timeout: startDate - now
        });
        (0, _timeout.setTimeoutEx)({
          handler: () => this.setHomepage(router, homepage),
          timeout: endDate - now
        });
      } else if (startDate <= now && now <= endDate) {
        (0, _utilities.setDefaultHomepage)("idf");
        (0, _timeout.setTimeoutEx)({
          handler: () => this.setHomepage(router, homepage),
          timeout: endDate - now
        });
      }
    }
  };
});