define("discourse/plugins/discourse-global-communities/discourse/controllers/admin-plugins-agc-program-pillars", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _tracking, _controller, _object, _service, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminProgramPillarsController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "creatingNew", [_tracking.tracked], function () {
      return false;
    }))();
    #creatingNew = (() => (dt7948.i(this, "creatingNew"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "modelToEdit", [_tracking.tracked], function () {
      return null;
    }))();
    #modelToEdit = (() => (dt7948.i(this, "modelToEdit"), void 0))();
    get programPillarsTabs() {
      return this.model.map(programPillar => {
        const {
          id,
          enabled,
          parent_tag
        } = programPillar;
        return {
          id,
          enabled,
          name: parent_tag?.name
        };
      });
    }
    updateModelToEdit(id) {
      this.modelToEdit = id;
      this.creatingNew = false;
    }
    static #_4 = (() => dt7948.n(this.prototype, "updateModelToEdit", [_object.action]))();
    removeModelToEdit() {
      this.modelToEdit = null;
    }
    static #_5 = (() => dt7948.n(this.prototype, "removeModelToEdit", [_object.action]))();
    toggleCreating() {
      this.creatingNew = !this.creatingNew;
    }
    static #_6 = (() => dt7948.n(this.prototype, "toggleCreating", [_object.action]))();
    async saveEdit(attributes) {
      try {
        const updated = await (0, _ajax.ajax)(`/admin/plugins/agc/program_pillars/${attributes.id}.json`, {
          method: "PUT",
          data: attributes
        });
        await this.store.createRecord("admin-program-pillar", updated);
        return true;
      } catch (e) {
        (0, _ajaxError.popupAjaxError)(e);
        return false;
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "saveEdit", [_object.action]))();
    async createNew(attributes) {
      try {
        const pillar = await (0, _ajax.ajax)("/admin/plugins/agc/program_pillars.json", {
          method: "POST",
          data: attributes
        });
        const record = await this.store.createRecord("admin-program-pillar", pillar);
        this.model.pushObject(record);
        this.toggleCreating();
      } catch (e) {
        (0, _ajaxError.popupAjaxError)(e);
      }
    }
    static #_8 = (() => dt7948.n(this.prototype, "createNew", [_object.action]))();
  }
  _exports.default = AdminProgramPillarsController;
});