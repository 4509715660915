define("discourse/plugins/discourse-global-communities/discourse/components/manage-program-pillars-translations-link", ["exports", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _dIcon, _i18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <a
      href="/admin/site_settings/category/all_results?filter=program_pillar_translations"
      target="_blank"
      rel="noopener noreferrer"
    >
      {{icon "link"}}
      {{i18n "admin.agc.program_pillars.child_tags.manage_translations"}}
    </a>
  
  */
  {
    "id": "EVe3hs59",
    "block": "[[[1,\"\\n  \"],[10,3],[14,6,\"/admin/site_settings/category/all_results?filter=program_pillar_translations\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n    \"],[1,[28,[32,0],[\"link\"],null]],[1,\"\\n    \"],[1,[28,[32,1],[\"admin.agc.program_pillars.child_tags.manage_translations\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/manage-program-pillars-translations-link.js",
    "scope": () => [_dIcon.default, _i18n.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "manage-program-pillars-translations-link"));
});