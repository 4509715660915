define("discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs/user-menu-analytics", ["exports", "@ember/runloop"], function (_exports, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserMenuAnalyticsCustomizations {
    static apply(api) {
      const container = api.container;
      const site = container.lookup("service:site");
      const appEvents = container.lookup("service:app-events");
      const router = container.lookup("service:router");

      // See comment below inside `onUserMenuTab` for reason for the function
      const addDataAttrsToQuickAccessProfileItems = () => {
        document.querySelectorAll("#quick-access-profile ul li").forEach(item => {
          const element = item.querySelector("a, button");
          if (element) {
            element.dataset.agcAnalyticsRef = `user_menu_tab_profile_${item.classList[0]}`;
          }
        });
      };

      // Listen for user menu tab changes, and fire custom app events for analytics
      const onUserMenuTabClick = tab => {
        appEvents.trigger("enhanced_user_analytics:event", "user_menu_tab_click", {
          tab
        });
        if (tab === "profile") {
          // If we've switched to the profile tab, add the `data-agc-analytics-ref` data attr
          // to each of the quick access links we care about. The `next` is necessary because
          // the appEvent fires before the items are rendered. It's ugly, but it works.
          (0, _runloop.next)(addDataAttrsToQuickAccessProfileItems);
        }
      };
      appEvents.on("user-menu:tab-click", this, onUserMenuTabClick);

      // Listen for user menu opens, and fire custom app events for analytics
      const onUserMenuOpen = () => {
        appEvents.trigger("enhanced_user_analytics:event", "nav_user_menu_click");
      };
      appEvents.on("user-menu:rendered", this, onUserMenuOpen);

      // Listen for user menu opens, and fire custom app events for analytics
      const onUserMenuNotificationClick = data => {
        const notificationName = site.notificationLookup[data.notification.notification_type];
        const eventData = {
          notification_type: notificationName
        };
        localStorage.lastAgcAnalyticsEventData = JSON.stringify(eventData).replace(/'/g, "\\'");
        localStorage.lastAgcAnalyticsRef = "user_menu_tab_notification";

        // Log a separate analytics event for which notification was clicked
        appEvents.trigger("agc-custom:notification-card-clicked", {
          url: data.href,
          source: router.currentRoute === "userNotifications.index" ? "notification_center" : "panel"
        });
      };
      appEvents.on("user-menu:notification-click", this, onUserMenuNotificationClick);
    }
  }
  _exports.default = UserMenuAnalyticsCustomizations;
});