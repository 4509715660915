define("discourse/plugins/discourse-global-communities/discourse/initializers/idf-content", ["exports", "discourse/plugins/discourse-global-communities/discourse/vendor/iframeSizer", "jquery", "discourse/lib/ajax", "discourse/lib/plugin-api", "discourse/plugins/discourse-global-communities/discourse/helpers/videojs-initializer", "discourse/plugins/discourse-global-communities/discourse/lib/idf-events"], function (_exports, _iframeSizer, _jquery, _ajax, _pluginApi, _videojsInitializer, _idfEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EVIA_ORIGINS = ["https://platform.evia.events", "https://platformdev.evia.events", "https://platformstage.evia.events", "https://platform.eventcoreengage.com/", "https://platformstage.eventcoreengage.com/"];
  const EVIA_IFRAMES = EVIA_ORIGINS.map(origin => `iframe[src^='${origin}'],iframe[data-src^='${origin}']`).join(",");
  var _default = _exports.default = {
    name: "idf-content",
    _tokenPromise: null,
    _timer: null,
    refreshAt(time, fn) {
      if (this._timer) {
        clearTimeout(this._timer);
        this._timer = null;
      }
      time = time - +new Date();
      if (time <= 0) {
        return;
      }
      this._timer = setTimeout(fn, time);
    },
    getEviaTokenPromise() {
      if (this._tokenPromise) {
        return this._tokenPromise.then(data => {
          if (new Date(data.expires_at) < +new Date() + 30 * 1000) {
            this._tokenPromise = null;
            return this.getEviaTokenPromise();
          } else {
            return data;
          }
        });
      } else {
        this._tokenPromise = (0, _ajax.ajax)("/idf/token.json");
        return this._tokenPromise;
      }
    },
    addEviaTokenToIframe(iframe) {
      const src = iframe.src || iframe.dataset["src"];
      iframe.dataset["src"] = src;
      iframe.removeAttribute("src");
      this.getEviaTokenPromise().then(data => {
        const url = new URL(src);
        url.searchParams.set("token", data.token);
        url.searchParams.set("clientid", data.client_id);
        iframe.src = url;
        (0, _jquery.default)(iframe).iFrameResize({
          enablePublicMethods: true,
          heightCalculationMethod: "max",
          autoResize: true,
          bodyBackground: "transparent"
        });
      });
    },
    addEviaTokenToIframes(element) {
      element.querySelectorAll(EVIA_IFRAMES).forEach(iframe => this.addEviaTokenToIframe(iframe));
    },
    showIdfContent($dataIdfElement, model) {
      // Move content to a data field to prevent autoplay
      const content = $dataIdfElement.html() || $dataIdfElement.data("idf-content");
      $dataIdfElement.html("");
      $dataIdfElement.data("idf-content", content);
      if ((0, _idfEvents.isEventActive)(model.event)) {
        // The content is kept in a data field because it should not be updated
        // unless it changed. The HTML content cannot be checked because that one
        // will most probably change (adding Evia tokens).
        if ((0, _jquery.default)(".idf-content").data("content") !== content) {
          (0, _jquery.default)(".idf-content").data("content", content);
          (0, _jquery.default)(".idf-content").html(content);
          (0, _jquery.default)(".idf-content")[0].dataset.themeVideo = "videojs";
          let video = (0, _jquery.default)(".idf-content video")[0];
          if (video) {
            (0, _videojsInitializer.default)(video);
          }
          this.addEviaTokenToIframes((0, _jquery.default)(".idf-content")[0]);
        }
        this.refreshAt((0, _idfEvents.getEventEnd)(model.event), () => this.showIdfContent($dataIdfElement, model));
      } else {
        (0, _jquery.default)(".idf-content").html("");
        this.refreshAt((0, _idfEvents.getEventStart)(model.event), () => this.showIdfContent($dataIdfElement, model));
      }
    },
    initializeWithApi(api) {
      api.decorateCookedElement((element, decoratorHelper) => {
        // First, go over all Evia iframes and move their `src` to `data-src`
        // because they might be rendered for a moment after being moved.
        element.querySelectorAll(EVIA_IFRAMES).forEach(iframe => {
          iframe.dataset["src"] = iframe.src;
          iframe.removeAttribute("src");
        });
        const model = decoratorHelper ? decoratorHelper.getModel() : null;
        if (model && model.post_number === 1) {
          const $dataIdfElement = (0, _jquery.default)(element).find("div[data-idf-content]");
          if (model.event && $dataIdfElement.length > 0) {
            this.showIdfContent($dataIdfElement, model);
          } else {
            (0, _jquery.default)(".idf-content").html("");
          }
        }
        this.addEviaTokenToIframes(element);
      }, {
        id: "idf-content"
      });
    },
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.7", api => this.initializeWithApi(api));
    }
  };
});