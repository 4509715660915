define("discourse/plugins/discourse-global-communities/discourse/feature-flags/hide-user-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HideUserSummary {
    static enabledSetting = "hide_profile_summary";
    static className = "agc-hide-profile-summary";
  }
  _exports.default = HideUserSummary;
});