define("discourse/plugins/discourse-global-communities/discourse/routes/admin-plugins-agc", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TABS = void 0;
  const TABS = _exports.TABS = [{
    route: "adminPlugins.agc.contacts",
    label: "admin.agc.contacts"
  }, {
    route: "adminPlugins.agc.emails.notificationsSummary",
    label: "admin.agc.emails"
  }, {
    route: "adminPlugins.agc.program-pillars",
    label: "admin.agc.program_pillars.title"
  }];
  class AdminPluginsAgcRoute extends _discourse.default {
    model() {
      return TABS;
    }
  }
  _exports.default = AdminPluginsAgcRoute;
});