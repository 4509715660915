define("discourse/plugins/discourse-global-communities/discourse/components/agc/header/standalone-notifications-wrapper", ["exports", "@ember/helper", "@ember/service", "discourse/components/header/user-menu-wrapper", "discourse/components/user-menu/menu", "discourse/modifiers/close-on-click-outside", "@ember/component", "@ember/template-factory"], function (_exports, _helper, _service, _userMenuWrapper, _menu, _closeOnClickOutside, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class StandaloneNotificationsWrapper extends _userMenuWrapper.default {
    static #_ = (() => dt7948.g(this.prototype, "agcHeader", [_service.service]))();
    #agcHeader = (() => (dt7948.i(this, "agcHeader"), void 0))();
    static #_2 = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.agcHeader.standaloneNotificationsVisible}}
          <div
            class="standalone-notifications-dropdown-wrapper"
            {{closeOnClickOutside
              this.clickOutside
              (hash
                targetSelector=".agc-user-notifications-menu"
                secondaryTargetSelector=".agc-user-notifications-menu"
              )
            }}
          >
            <UserMenu @closeUserMenu={{@toggleUserMenu}} />
          </div>
        {{/if}}
      
    */
    {
      "id": "lN5cYNnH",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"agcHeader\",\"standaloneNotificationsVisible\"]],[[[1,\"      \"],[11,0],[24,0,\"standalone-notifications-dropdown-wrapper\"],[4,[32,0],[[30,0,[\"clickOutside\"]],[28,[32,1],null,[[\"targetSelector\",\"secondaryTargetSelector\"],[\".agc-user-notifications-menu\",\".agc-user-notifications-menu\"]]]],null],[12],[1,\"\\n        \"],[8,[32,2],null,[[\"@closeUserMenu\"],[[30,1]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@toggleUserMenu\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/header/standalone-notifications-wrapper.js",
      "scope": () => [_closeOnClickOutside.default, _helper.hash, _menu.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = StandaloneNotificationsWrapper;
});