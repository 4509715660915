define("discourse/plugins/discourse-global-communities/discourse/initializers/track-tv-video-plays", ["exports", "discourse/lib/ajax", "discourse/lib/plugin-api"], function (_exports, _ajax, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "agc-track-video-plays",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", api => {
        api.decorateCooked(trackVideoPlays, {
          onlyStream: true,
          id: "discourse-track-video-plays"
        });
        function trackVideoPlays($elem, helper) {
          // only apply to 1) first post 2) containing video 3) in webinars

          if (helper.widget.attrs.firstPost === false) {
            return;
          }
          let v = $elem[0].querySelectorAll("video");
          if (v.length === 0) {
            return;
          }
          if (document.body.classList.contains("has-webinar")) {
            v.forEach(video => {
              video.addEventListener("play", () => {
                if (video.classList.contains("play-event-registered")) {
                  return;
                }
                video.classList.add("play-event-registered");
                const topicId = helper.widget.attrs.topicId;
                (0, _ajax.ajax)(`/t/${topicId}.json`).then(t => {
                  (0, _ajax.ajax)(`/zoom/webinars/${t.webinar.id}/attendees/${helper.widget.currentUser.username}/watch.json`, {
                    type: "PUT"
                  });
                });
              });
            });
          }
        }
      });
    }
  };
});