define("discourse/plugins/discourse-global-communities/discourse/services/agc-toolbar-distributor", ["exports", "@ember/service", "discourse/lib/plugin-api"], function (_exports, _service, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AGCToolbarDistributorService extends _service.default {
    toolbar;
    constructor() {
      super();
      this._initialize();
    }
    _initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", api => {
        api.onToolbarCreate(toolbar => {
          this.toolbar = toolbar;
        });
      });
    }
  }
  _exports.default = AGCToolbarDistributorService;
});