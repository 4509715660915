define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/user-notifications-above-filter/notifications-title", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h1 class="notifications-title">
    {{i18n "notifications.title"}}
  </h1>
  */
  {
    "id": "DAa/7XhZ",
    "block": "[[[10,\"h1\"],[14,0,\"notifications-title\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"notifications.title\"],null]],[1,\"\\n\"],[13]],[],false,[\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/user-notifications-above-filter/notifications-title.hbs",
    "isStrictMode": false
  });
});