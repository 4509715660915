define("discourse/plugins/discourse-global-communities/discourse/initializers/force-refresh-listener", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "force-refresh-listener",
    initialize(container) {
      const messageBus = container.lookup("service:message-bus");
      messageBus.subscribe("/force-refresh-channel", () => {
        location.reload();
      });
    }
  };
});