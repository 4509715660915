define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/before-docs-search/banner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="docs-banner">
    <img src="/images/docs-banner-logo.png" />
    <div>
      <strong>{{this.siteSettings.docs_banner_title}}</strong>
      <p>{{html-safe (this.siteSettings.docs_banner_description)}}</p>
    </div>
  </div>
  */
  {
    "id": "YJI6hafd",
    "block": "[[[10,0],[14,0,\"docs-banner\"],[12],[1,\"\\n  \"],[10,\"img\"],[14,\"src\",\"/images/docs-banner-logo.png\"],[12],[13],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[10,\"strong\"],[12],[1,[30,0,[\"siteSettings\",\"docs_banner_title\"]]],[13],[1,\"\\n    \"],[10,2],[12],[1,[28,[35,0],[[28,[30,0,[\"siteSettings\",\"docs_banner_description\"]],null,null]],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"html-safe\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/before-docs-search/banner.hbs",
    "isStrictMode": false
  });
});