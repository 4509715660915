define("discourse/plugins/discourse-global-communities/discourse/components/gc-sidebar-popular-tags", ["exports", "discourse/helpers/discourse-tag", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _discourseTag, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="homepage-hero-cell tag-sidebar">
      <h3 class="cell-title">{{@tagTitle}}</h3>
  
      <div class="tag-sidebar-tags">
        {{#each @tags as |tag|}}
          {{discourseTag tag includeAgcAnalyticsRef=true}}
        {{/each}}
      </div>
    </div>
  
  */
  {
    "id": "7erZX9ja",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"homepage-hero-cell tag-sidebar\"],[12],[1,\"\\n    \"],[10,\"h3\"],[14,0,\"cell-title\"],[12],[1,[30,1]],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"tag-sidebar-tags\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,2]],null]],null],null,[[[1,\"        \"],[1,[28,[32,0],[[30,3]],[[\"includeAgcAnalyticsRef\"],[true]]]],[1,\"\\n\"]],[3]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@tagTitle\",\"@tags\",\"tag\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/gc-sidebar-popular-tags.js",
    "scope": () => [_discourseTag.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "gc-sidebar-popular-tags"));
});