define("discourse/plugins/discourse-global-communities/discourse/initializers/discourse-global-communities", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/avatar-flair", "discourse/lib/plugin-api", "discourse/lib/url", "discourse/widgets/post", "discourse-common/lib/icon-library"], function (_exports, _ajax, _ajaxError, _avatarFlair, _pluginApi, _url, _post, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // https://github.com/discourse/discourse/blob/master/app/assets/javascripts/discourse/app/lib/plugin-api.js

  function initialize(api) {
    api.includePostAttributes("can_mark_helpful", "is_helpful", "can_mark_excluded_from_recommendations", "is_excluded_from_recommendations", "post_tags");
    api.addPostMenuButton("exclude", attrs => {
      if (!attrs.can_mark_excluded_from_recommendations) {
        return;
      }
      return {
        action: "toggleExcludePostFromRecommendations",
        icon: "ban",
        position: "first",
        title: attrs.is_excluded_from_recommendations ? "discourse_gc.post_recommend_excluded_tooltip" : "discourse_gc.post_recommend_included_tooltip",
        className: attrs.is_excluded_from_recommendations ? "is-excluded exclude-button" : "exclude-button"
      };
    });
    api.addPostMenuButton("helpful", attrs => {
      if (!attrs.can_mark_helpful) {
        if (attrs.is_helpful) {
          return {
            icon: "check-circle",
            position: "first",
            label: "discourse_gc.helpful_answer",
            className: "is-helpful disabled"
          };
        }
        return;
      }
      return {
        action: "toggleHelpfulAnswer",
        icon: "check-circle",
        position: "first",
        label: "discourse_gc.helpful_answer",
        className: attrs.is_helpful ? "is-helpful helpful-button" : "helpful-button"
      };
    });
    api.attachWidgetAction("post", "toggleHelpfulAnswer", function () {
      const post = this.model;
      if (post) {
        updateHelpfulPost(post);
      }
    });
    api.attachWidgetAction("post", "toggleExcludePostFromRecommendations", async function () {
      const post = this.model;
      if (post) {
        await updateExcludePost(post);
      }
    });
    api.modifyClass("controller:preferences/account", {
      pluginId: "discourse-global-communities",
      canEditName: false
    });
    api.modifyClass("component:user-card-contents", {
      pluginId: "discourse-global-communities",
      _show(_, target) {
        if (!this.siteSettings.enable_user_cards) {
          _url.default.routeTo(`${target.href}/summary`);
          return false;
        }
        this._super(...arguments);
      }
    });
    api.addBulkActionButton({
      id: "toggle-staged",
      label: "discourse_gc.toggle_staged",
      icon: "user-times",
      class: "btn-default",
      action: _ref => {
        let {
          performAndRefresh
        } = _ref;
        performAndRefresh({
          type: "toggle_staged"
        });
      },
      actionType: "performAndRefresh"
    });
    api.includePostAttributes("show_country_flair");
    api.includePostAttributes("primary_group_flair_url");
    api.decorateWidget("poster-name:after", dec => {
      if (dec.attrs.show_country_flair === true) {
        return dec.h("span.poster-icon", {}, dec.h("img", {
          attributes: {
            src: dec.attrs.primary_group_flair_url,
            class: "country-flair"
          }
        }));
      }
    });
    api.reopenWidget("post-avatar", {
      // This was copied from discourse core
      // The only change is not showing the avatar flair unless show_country_flair === null
      html(attrs) {
        let body;
        if (!attrs.user_id) {
          body = (0, _iconLibrary.iconNode)("far-trash-alt", {
            class: "deleted-user-avatar"
          });
        } else {
          body = _post.avatarFor.call(this, this.settings.size, {
            template: attrs.avatar_template,
            username: attrs.username,
            name: attrs.name,
            url: attrs.usernameUrl,
            className: "main-avatar",
            hideTitle: true
          });
        }
        const result = [body];
        if (attrs.primary_group_flair_url || attrs.primary_group_flair_bg_color) {
          if (attrs.show_country_flair === null) {
            result.push(this.attach("avatar-flair", attrs));
          }
        } else {
          const autoFlairAttrs = (0, _avatarFlair.default)(this.site, attrs);
          if (autoFlairAttrs) {
            result.push(this.attach("avatar-flair", autoFlairAttrs));
          }
        }
        result.push(api.h("div.poster-avatar-extra"));
        if (this.settings.displayPosterName) {
          result.push(this.attach("post-avatar-user-info", attrs));
        }
        return result;
      }
    });
  }
  function updateHelpfulPost(post) {
    const data = {
      post_id: post.id,
      make_helpful: !post.is_helpful
    };
    post.set("is_helpful", !post.is_helpful);
    (0, _ajax.ajax)(`/helpful-post.json`, {
      method: "PUT",
      data
    }).catch(_ajaxError.popupAjaxError);
  }
  async function updateExcludePost(post) {
    let previousExclusionState = post.is_excluded_from_recommendations;
    const data = {
      post_id: post.id,
      excluded: !previousExclusionState
    };
    post.set("is_excluded_from_recommendations", !previousExclusionState);
    await (0, _ajax.ajax)(`/exclude-post.json`, {
      method: "PUT",
      data
    }).catch(error => {
      post.set("is_excluded_from_recommendations", previousExclusionState);
      (0, _ajaxError.popupAjaxError)(error);
    });
  }
  var _default = _exports.default = {
    name: "discourse-global-communities",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", initialize);
    }
  };
});