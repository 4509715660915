define("discourse/plugins/discourse-global-communities/discourse/routes/admin-plugins-agc-program-pillars", ["exports", "@ember/service", "discourse/routes/discourse"], function (_exports, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminProgramPillarsRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    model() {
      return this.store.findAll("admin-program-pillar");
    }
  }
  _exports.default = AdminProgramPillarsRoute;
});