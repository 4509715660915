define("discourse/plugins/discourse-global-communities/discourse/services/agc-post-bulk-actions", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcPostBulkActions extends _service.default {
    renderPostSelector = false;
    selectedPostIds = (() => new Set([]))();
    setRenderPostSelector(newValue) {
      this.renderPostSelector = newValue;
    }
    toggleRenderPostSelector() {
      this.renderPostSelector = !this.renderPostSelector;
    }
    insertSelectedPostId(postId) {
      this.selectedPostIds.add(postId);
    }
    removeSelectedPostId(postId) {
      this.selectedPostIds.delete(postId);
    }
    removeAllSelectedPostIds() {
      this.selectedPostIds = new Set([]);
    }
    getSelectedPostIds() {
      return this.selectedPostIds;
    }
  }
  _exports.default = AgcPostBulkActions;
});