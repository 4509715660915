define("discourse/plugins/discourse-global-communities/discourse/lib/analytics-annotator", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addTopicListAgcAnalyticsRefs = addTopicListAgcAnalyticsRefs;
  _exports.setAnalyticsEventData = setAnalyticsEventData;
  _exports.setAnalyticsPageType = setAnalyticsPageType;
  _exports.setAnalyticsRef = setAnalyticsRef;
  _exports.setLastAnalyticsEventData = setLastAnalyticsEventData;
  _exports.setWYWATopicsAnalyticsData = setWYWATopicsAnalyticsData;
  _exports.triggerCarouselAnalytics = triggerCarouselAnalytics;
  _exports.triggerEnhancedNotificationsAnalytics = triggerEnhancedNotificationsAnalytics;
  _exports.triggerRenderedWYWATopicsAnalytics = triggerRenderedWYWATopicsAnalytics;
  function addTopicListAgcAnalyticsRefs() {
    (0, _jquery.default)("#list-area").find("a.discourse-tag").each(function () {
      let $this = (0, _jquery.default)(this);
      const tagName = $this.attr("data-tag-name")?.toLowerCase();
      if (tagName) {
        $this.attr("data-agc-analytics-ref", `topic_list_tag_${tagName}`);
      }
    });
    (0, _jquery.default)("#list-area").find("a.badge-category__wrapper").each(function () {
      let $this = (0, _jquery.default)(this);
      const categoryName = $this.find(".badge-category__name").text().toLowerCase().replace(" ", "_");
      $this.attr("data-agc-analytics-ref", `topic_list_category_${categoryName}`);
    });
  }
  function setAnalyticsRef(element, ref) {
    ref = ref.toLowerCase().replace(/-/g, "_");
    element.dataset.agcAnalyticsRef = ref;
  }
  function setAnalyticsPageType(element, type) {
    element.dataset.agcAnalyticsPageType = type;
  }
  function setAnalyticsEventData(element, eventData) {
    const jsonString = JSON.stringify(eventData).replace(/'/g, "\\'");
    element.dataset.agcAnalyticsEventData = jsonString;
  }
  function setLastAnalyticsEventData(eventData) {
    if (eventData) {
      localStorage.lastAgcAnalyticsEventData = JSON.stringify(eventData);
    } else {
      delete localStorage.lastAgcAnalyticsRef;
    }
  }
  function setWYWATopicsAnalyticsData(selector, eventData) {
    document.querySelectorAll(`${selector} tr`).forEach((element, index) => {
      element.querySelectorAll("a").forEach(e => {
        if (!e.getAttribute("data-agc-analytics-ref")) {
          e.setAttribute("data-agc-analytics-ref", "wywa_topic");
        }
        setAnalyticsEventData(e, {
          ...eventData,
          position: index + 1
        });
      });
    });
  }
  function triggerRenderedWYWATopicsAnalytics(topics, appEvents) {
    const homePageTopics = document.querySelectorAll(".loading-container tr");
    const eventData = {
      totalTopics: homePageTopics?.length + topics?.length,
      totalWYWATopics: topics?.length,
      WYWATopics: []
    };
    eventData.WYWATopics = topics.map((topic, index) => {
      return {
        topicId: topic.id,
        position: index + 1,
        label: topic.tileLabel.replace("agc.while_you_were_away.labels.", "")
      };
    });
    appEvents.trigger("agc-custom:agc-wywa-topics-loaded", eventData);
  }
  function triggerCarouselAnalytics(action, appEvents) {
    let eventData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    appEvents.trigger(`agc-custom:${action}`, eventData);
  }
  function triggerEnhancedNotificationsAnalytics(action, appEvents, eventData) {
    appEvents.trigger(`agc-custom:enhanced_notifications_${action}`, eventData);
  }
});