define("discourse/plugins/discourse-global-communities/discourse/initializers/search-menu", ["exports", "discourse/lib/plugin-api", "discourse/lib/search", "discourse-i18n"], function (_exports, _pluginApi, _search, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "global-communities-search-menu",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.20.0", api => {
        const siteSettings = api.container.lookup("service:site-settings");
        const searchService = api.container.lookup("service:search");
        const router = api.container.lookup("service:router");

        // Stop odd popout from right thing
        api.forceDropdownForMenuPanels("search-menu-panel");

        // Clear default quick tips and replace with custom ones
        api.removeDefaultQuickSearchRandomTips();

        // Add quick tips from settings
        siteSettings.new_search_menu_random_tips.split("|").forEach(tip => api.addQuickSearchRandomTip({
          label: tip,
          description: _discourseI18n.default.t("agc.quick_search.suggested_search"),
          clickable: true,
          showTopics: true
        }));

        // Make recent search assistant items NOT respect core searchTopics context.
        api.addSearchMenuAssistantSelectCallback(args => {
          if (args.usage === "recent-search") {
            args.searchTermChanged(args.updatedTerm);
            return false;
          }
          return true;
        });

        // If the search term is valid, Enter presses should go straight to full page search
        api.addSearchMenuOnKeyDownCallback((searchTerm, event) => {
          const searchInput = document.querySelector("#search-term");

          // If the keypress is not enter, or in the search input, or in topic context
          // return true so the event bubbles.
          if (event.key !== "Enter" || event.target !== searchInput || searchService.inTopicContext) {
            return true;
          }

          // If the search term is invalid, return early and return false so that the
          // event DOESN'T bubble.
          if (!(0, _search.isValidSearchTerm)(searchService.activeGlobalSearchTerm, siteSettings)) {
            return false;
          }

          // Here we know that the Enter key was pressed on the search input with valid
          // search input. Navigate to full search and fill the search input with term.
          searchTerm.args.closeSearchMenu();
          router.transitionTo("full-page-search", {
            queryParams: {
              q: searchService.activeGlobalSearchTerm
            }
          });

          // Fill in the search menu with the term again so that pressing `Enter` doesn't
          // clear it out.
          const searchMenuInput = document.getElementById("search-term");
          if (searchMenuInput) {
            searchMenuInput.value = searchService.activeGlobalSearchTerm;
          }
          return false;
        });
      });
    }
  };
});