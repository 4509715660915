define("discourse/plugins/discourse-global-communities/discourse/components/topic-list-options-popup", ["exports", "@glimmer/component", "@ember/service", "float-kit/components/d-menu", "discourse/plugins/discourse-global-communities/discourse/components/topic-list-options-content", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _dMenu, _topicListOptionsContent, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TopicListOptionsPopup extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DMenu
          @icon="ellipsis-h"
          @identifier="agc-topic-options-popup"
          @placement="bottom-end"
        >
          <:content as |args|>
            <TopicListOptionsContent @close={{args.close}} @topic={{@topic}} />
          </:content>
        </DMenu>
      
    */
    {
      "id": "1G2R1aie",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@icon\",\"@identifier\",\"@placement\"],[\"ellipsis-h\",\"agc-topic-options-popup\",\"bottom-end\"]],[[\"content\"],[[[[1,\"\\n        \"],[8,[32,1],null,[[\"@close\",\"@topic\"],[[30,1,[\"close\"]],[30,2]]],null],[1,\"\\n      \"]],[1]]]]],[1,\"\\n  \"]],[\"args\",\"@topic\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/topic-list-options-popup.js",
      "scope": () => [_dMenu.default, _topicListOptionsContent.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TopicListOptionsPopup;
});