define("discourse/plugins/discourse-global-communities/discourse/initializers/custom-advanced-search", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-global-communities/discourse/components/agc/search/custom-search-fields"], function (_exports, _pluginApi, _customSearchFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initialize(api) {
    const REGEXP_IN_POLLS_MATCH = /^in:polls/gi;
    const REGEXP_IN_QUESTIONS_MATCH = /^in:questions/gi;
    const REGEXP_IN_POST_TYPE_MATCH = /^in:(polls|questions)/gi;
    const REGEXP_IN_STAGED_CONTENT_MATCH = /^in:staged_content/gi;
    const REGEXP_IN_TV_MATCH = /^in:tv/gi;
    const REGEXP_WITH_AUDIO_MATCH = /^with:audio/gi;
    const REGEXP_WITH_VIDEO_MATCH = /^with:video/gi;
    const REGEXP_WITH_PHOTO_MATCH = /^with:photo/gi;
    const REGEXP_WITH_ATTACHMENT_MATCH = /^with:attachment/gi;
    api.modifyClass("component:search-advanced-options", {
      pluginId: "discourse-global-communities",
      init() {
        this._super(...arguments);
        this.set("searchedTerms.postType", null);
        this.set("searchedTerms.inStagedContent", null);
        this.set("searchedTerms.inTV", null);
        this.set("searchedTerms.withAudio", null);
        this.set("searchedTerms.withVideo", null);
      },
      didReceiveAttrs() {
        this._super(...arguments);
        this.updateCustom();
      },
      updateCustom() {
        const inPolls = this.filterBlocks(REGEXP_IN_POLLS_MATCH);
        if (inPolls.length !== 0) {
          this.set("searchedTerms.postType", "polls");
        }
        const inQuestions = this.filterBlocks(REGEXP_IN_QUESTIONS_MATCH);
        if (inQuestions.length !== 0) {
          this.set("searchedTerms.postType", "questions");
        }
        const inStagedContent = this.filterBlocks(REGEXP_IN_STAGED_CONTENT_MATCH);
        if (inStagedContent.length !== 0) {
          this.set("searchedTerms.inStagedContent", true);
        }
        const inTV = this.filterBlocks(REGEXP_IN_TV_MATCH);
        if (inTV.length !== 0) {
          this.set("searchedTerms.inTV", true);
        }
        const withAudio = this.filterBlocks(REGEXP_WITH_AUDIO_MATCH);
        if (withAudio.length !== 0) {
          this.set("searchedTerms.withAudio", true);
        }
        const withVideo = this.filterBlocks(REGEXP_WITH_VIDEO_MATCH);
        if (withVideo.length !== 0) {
          this.set("searchedTerms.withVideo", true);
        }
        const withPhoto = this.filterBlocks(REGEXP_WITH_PHOTO_MATCH);
        if (withPhoto.length !== 0) {
          this.set("searchedTerms.withPhoto", true);
        }
        const withAttachment = this.filterBlocks(REGEXP_WITH_ATTACHMENT_MATCH);
        if (withAttachment.length !== 0) {
          this.set("searchedTerms.withAttachment", true);
        }
      },
      _updatePostTypeOptions() {
        const match = this.filterBlocks(REGEXP_IN_POST_TYPE_MATCH);
        let searchTerm = this.searchTerm || "";
        if (match.length !== 0) {
          searchTerm = searchTerm.replace(match, "");
          searchTerm = searchTerm.trim();
        }
        if (this.searchedTerms.postType) {
          searchTerm += ` in:${this.searchedTerms.postType}`;
        }
        this._updateSearchTerm(searchTerm);
      },
      _updateStagedContent() {
        this._toggleFilter(this.searchedTerms.inStagedContent, "in:staged_content");
      },
      _updateTV() {
        this._toggleFilter(this.searchedTerms.inTV, "in:tv");
      },
      _updateAudio() {
        this._toggleFilter(this.searchedTerms.withAudio, "with:audio");
      },
      _updateVideo() {
        this._toggleFilter(this.searchedTerms.withVideo, "with:video");
      },
      _updatePhoto() {
        this._toggleFilter(this.searchedTerms.withPhoto, "with:photo");
      },
      _updateAttachment() {
        this._toggleFilter(this.searchedTerms.withAttachment, "with:attachment");
      },
      _toggleFilter(missingTerm, term) {
        let searchTerm = this.searchTerm || "";
        if (missingTerm) {
          searchTerm += ` ${term}`;
        } else {
          searchTerm = searchTerm.replace(term, "");
        }
        this._updateSearchTerm(searchTerm);
      }
    });
    api.renderInOutlet("advanced-search-options-below", _customSearchFields.default);
  }
  var _default = _exports.default = {
    name: "agc-custom-advanced-search",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", initialize);
    }
  };
});