define("discourse/plugins/discourse-global-communities/discourse/connectors/search-menu-initial-options/agc-search-menu-initial-options", ["exports", "@glimmer/component", "@ember/service", "discourse/components/search-menu/results/assistant-item", "discourse/lib/search", "discourse-i18n", "truth-helpers/helpers/or", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _assistantItem, _search3, _discourseI18n, _or, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcSearchMenuInitialOptions extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "search", [_service.service]))();
    #search = (() => (dt7948.i(this, "search"), void 0))();
    get text() {
      return _discourseI18n.default.t(`search_menu.types.${this.args.outletArgs.resultType.type}`);
    }
    get searchTermEmpty() {
      return this.search.activeGlobalSearchTerm.length === 0;
    }
    get searchTermInvalid() {
      return !(0, _search3.isValidSearchTerm)(this.search.activeGlobalSearchTerm, this.siteSettings);
    }
    get searchTermInvalidText() {
      return _discourseI18n.default.t("agc.quick_search.continue_typing", {
        count: this.siteSettings.min_search_term_length
      });
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if (or @outletArgs.termMatchesContextTypeKeyword this.searchTermEmpty)}}
          {{yield}}
        {{else if this.search.searchContext}}
          <@outletArgs.contextTypeComponent
            @slug={{@outletArgs.slug}}
            @suggestionKeyword={{@outletArgs.contextTypeKeyword}}
            @results={{@outletArgs.initialResults}}
            @withInLabel={{@outletArgs.withInLabel}}
            @suffix={{@outletArgs.suffix}}
            @label={{@outletArgs.label}}
            @closeSearchMenu={{@outletArgs.closeSearchMenu}}
            @searchTermChanged={{@outletArgs.searchTermChanged}}
          />
        {{else if this.searchTermInvalid}}
          <AssistantItem
            @suffix={{this.searchTermInvalidText}}
            @searchTermChanged={{@outletArgs.searchTermChanged}}
          />
        {{else}}
          <div class="hidden"></div>
        {{/if}}
      
    */
    {
      "id": "agqzeoew",
      "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,1,[\"termMatchesContextTypeKeyword\"]],[30,0,[\"searchTermEmpty\"]]],null],[[[1,\"      \"],[18,2,null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"search\",\"searchContext\"]],[[[1,\"      \"],[8,[30,1,[\"contextTypeComponent\"]],null,[[\"@slug\",\"@suggestionKeyword\",\"@results\",\"@withInLabel\",\"@suffix\",\"@label\",\"@closeSearchMenu\",\"@searchTermChanged\"],[[30,1,[\"slug\"]],[30,1,[\"contextTypeKeyword\"]],[30,1,[\"initialResults\"]],[30,1,[\"withInLabel\"]],[30,1,[\"suffix\"]],[30,1,[\"label\"]],[30,1,[\"closeSearchMenu\"]],[30,1,[\"searchTermChanged\"]]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"searchTermInvalid\"]],[[[1,\"      \"],[8,[32,1],null,[[\"@suffix\",\"@searchTermChanged\"],[[30,0,[\"searchTermInvalidText\"]],[30,1,[\"searchTermChanged\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"hidden\"],[12],[13],[1,\"\\n    \"]],[]]]],[]]]],[]]],[1,\"  \"]],[\"@outletArgs\",\"&default\"],false,[\"if\",\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/connectors/search-menu-initial-options/agc-search-menu-initial-options.js",
      "scope": () => [_or.default, _assistantItem.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcSearchMenuInitialOptions;
});