define("discourse/plugins/discourse-global-communities/discourse/agc-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("discovery", {
      path: "/",
      resetNamespace: true
    }, function () {
      this.route("program-pillars-base", {
        path: "/program-pillars",
        resetNamespace: true
      });
      this.route("program-pillars", {
        path: "/program-pillars/*region",
        resetNamespace: true
      });
    });
    this.route("connect");
    this.route("members");
    this.route("explore");
    this.route("tv", function () {
      this.route("index", {
        path: "/"
      });
      this.route("tag", {
        path: "/:tag"
      });
    });
    this.route("idf", function () {
      this.route("library");
    });
    this.route("start-chatting");
  }
});