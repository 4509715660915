define("discourse/plugins/discourse-global-communities/discourse/components/gc-mini-webinar", ["exports", "@glimmer/component", "discourse/helpers/avatar", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/helpers/formatted", "@ember/component", "@ember/template-factory"], function (_exports, _component, _avatar, _dIcon, _i18n, _discourseI18n, _formatted, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GcMiniWebinar extends _component.default {
    constructor() {
      super(...arguments);
      try {
        this.webinarRegister = require("discourse/plugins/discourse-zoom/components/webinar-register").default;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn("Could not find discourse-zoom/components/webinar-register component");
      }
    }
    get scheduled() {
      if (this.args.webinar === undefined) {
        return "";
      }
      if (this.args.webinar.ends_at === null) {
        return moment(this.args.webinar.starts_at).format(_discourseI18n.default.t("dates.full_with_year_no_time"));
      }
      return (0, _formatted.formattedSchedule)(this.args.webinar.starts_at, this.args.webinar.ends_at);
    }
    get includeWebinarRegister() {
      return moment(this.args.webinar.starts_at).isAfter(moment());
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @webinar.host}}
          <div class="mini-webinar-item">
            {{icon "member-squid"}}
            <span class="mini-webinar-label">{{i18n "zoom.hosted_by"}}</span>
            <a href="/u/{{@webinar.host.username}}">{{avatar
                @webinar.host
                imageSize="small"
              }}</a>
          </div>
        {{/if}}
    
        {{#if @webinar.panelists}}
          <div class="mini-webinar-item">
            {{icon "comment-squid"}}
            <span class="mini-webinar-label">{{i18n "zoom.panelists"}}</span>
            <span class="panelist-avatars">
              {{#each @webinar.panelists as |panelist|}}
                <a href="/u/{{panelist.username}}">{{avatar
                    panelist
                    imageSize="small"
                  }}</a>
              {{/each}}
            </span>
          </div>
        {{/if}}
    
        <div class="mini-webinar-item">
          {{icon "time-squid"}}
          <span class="mini-webinar-label">{{this.scheduled}}</span>
        </div>
    
        {{#if @includeWebinarRegister}}
          <this.webinarRegister @webinar={{@webinar}} />
        {{/if}}
      
    */
    {
      "id": "U81jMIFC",
      "block": "[[[1,\"\\n\"],[41,[30,1,[\"host\"]],[[[1,\"      \"],[10,0],[14,0,\"mini-webinar-item\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"member-squid\"],null]],[1,\"\\n        \"],[10,1],[14,0,\"mini-webinar-label\"],[12],[1,[28,[32,1],[\"zoom.hosted_by\"],null]],[13],[1,\"\\n        \"],[10,3],[15,6,[29,[\"/u/\",[30,1,[\"host\",\"username\"]]]]],[12],[1,[28,[32,2],[[30,1,[\"host\"]]],[[\"imageSize\"],[\"small\"]]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,1,[\"panelists\"]],[[[1,\"      \"],[10,0],[14,0,\"mini-webinar-item\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"comment-squid\"],null]],[1,\"\\n        \"],[10,1],[14,0,\"mini-webinar-label\"],[12],[1,[28,[32,1],[\"zoom.panelists\"],null]],[13],[1,\"\\n        \"],[10,1],[14,0,\"panelist-avatars\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,1,[\"panelists\"]]],null]],null],null,[[[1,\"            \"],[10,3],[15,6,[29,[\"/u/\",[30,2,[\"username\"]]]]],[12],[1,[28,[32,2],[[30,2]],[[\"imageSize\"],[\"small\"]]]],[13],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[14,0,\"mini-webinar-item\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[\"time-squid\"],null]],[1,\"\\n      \"],[10,1],[14,0,\"mini-webinar-label\"],[12],[1,[30,0,[\"scheduled\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,3],[[[1,\"      \"],[8,[30,0,[\"webinarRegister\"]],null,[[\"@webinar\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@webinar\",\"panelist\",\"@includeWebinarRegister\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/gc-mini-webinar.js",
      "scope": () => [_dIcon.default, _i18n.default, _avatar.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = GcMiniWebinar;
});