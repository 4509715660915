define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/user-preferences-nav/agc-home-feed-nav", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <li class="user-nav__preferences-agc-home-feed">
    <LinkTo @route="preferences.agc-home-feed">
      <span>{{i18n "user.agc_preferences.home_feed.title"}}</span>
    </LinkTo>
  </li>
  */
  {
    "id": "aglGnkMC",
    "block": "[[[10,\"li\"],[14,0,\"user-nav__preferences-agc-home-feed\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@route\"],[\"preferences.agc-home-feed\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,1],[12],[1,[28,[35,1],[\"user.agc_preferences.home_feed.title\"],null]],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"link-to\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/user-preferences-nav/agc-home-feed-nav.hbs",
    "isStrictMode": false
  });
});