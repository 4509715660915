define("discourse/plugins/discourse-global-communities/discourse/helpers/reload-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reloadPage = reloadPage;
  function reloadPage() {
    location.reload();
  }
});