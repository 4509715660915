define("discourse/plugins/discourse-global-communities/discourse/components/agc/chat/pages/call-to-action", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="chat-call-to-action-page {{@flavor}}">
    <div class="chat-call-to-action-page__content">
      <div class="chat-call-to-action-page__image"></div>
      <div class="chat-call-to-action-page__title">
        {{@title}}
      </div>
      <div class="chat-call-to-action-page__message">
        {{@message}}
      </div>
      <div>
        {{yield}}
      </div>
    </div>
  </div>
  */
  {
    "id": "1U96KiXa",
    "block": "[[[10,0],[15,0,[29,[\"chat-call-to-action-page \",[30,1]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"chat-call-to-action-page__content\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"chat-call-to-action-page__image\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"chat-call-to-action-page__title\"],[12],[1,\"\\n      \"],[1,[30,2]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"chat-call-to-action-page__message\"],[12],[1,\"\\n      \"],[1,[30,3]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[18,4,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@flavor\",\"@title\",\"@message\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/agc/chat/pages/call-to-action.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)(undefined, "call-to-action"));
});