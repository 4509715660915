define("discourse/plugins/discourse-global-communities/discourse/components/circles/featured-members", ["exports", "@glimmer/component", "@ember/routing", "@ember/template", "discourse/helpers/user-avatar", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _routing, _template, _userAvatar, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const avatar = user => {
    return (0, _template.htmlSafe)((0, _userAvatar.renderAvatar)(user, {
      imageSize: "large"
    }));
  };
  class FeaturedMembers extends _component.default {
    get featuredUsers() {
      return (this.args.users || []).slice(0, 6);
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class="circles-featured-members homepage-hero-secondary homepage-hero-cell"
        >
          <h3 class="cell-title">
            {{i18n "agc.circle.featured_members.headline"}}
          </h3>
          <div class="secondary-featured-users">
            {{#each this.featuredUsers as |user|}}
              <a
                href="/u/{{user.username}}"
                data-user-card={{user.username}}
                class="avatar-link"
              >
                {{avatar user}}
              </a>
            {{/each}}
          </div>
          <LinkTo class="btn btn-default" @route="circle.members">
            {{i18n "agc.circle.featured_members.view_all" count=@totalUsers}}
          </LinkTo>
        </div>
      
    */
    {
      "id": "kOKHBxvn",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"circles-featured-members homepage-hero-secondary homepage-hero-cell\"],[12],[1,\"\\n      \"],[10,\"h3\"],[14,0,\"cell-title\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"agc.circle.featured_members.headline\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"secondary-featured-users\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"featuredUsers\"]]],null]],null],null,[[[1,\"          \"],[10,3],[15,6,[29,[\"/u/\",[30,1,[\"username\"]]]]],[15,\"data-user-card\",[30,1,[\"username\"]]],[14,0,\"avatar-link\"],[12],[1,\"\\n            \"],[1,[28,[32,1],[[30,1]],null]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n      \"],[8,[32,2],[[24,0,\"btn btn-default\"]],[[\"@route\"],[\"circle.members\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[32,0],[\"agc.circle.featured_members.view_all\"],[[\"count\"],[[30,2]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"user\",\"@totalUsers\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/circles/featured-members.js",
      "scope": () => [_i18n.default, avatar, _routing.LinkTo],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = FeaturedMembers;
});