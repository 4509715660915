define("discourse/plugins/discourse-global-communities/discourse/templates/components/topic-category-expert-question-banner", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if show}}
    <div class="alert topic-category-expert-question-banner">
      {{i18n "agc.category_experts_question_banner.new"}}
      <a class="composer-link" href {{action "openComposer"}}>
        {{i18n
          "agc.category_experts_question_banner.ask_dsp_guide"
          categoryName=topic.category.name
        }}
      </a>
      {{i18n "agc.category_experts_question_banner.to_respond"}}
    </div>
  {{/if}}
  */
  {
    "id": "egaNyENZ",
    "block": "[[[41,[30,0,[\"show\"]],[[[1,\"  \"],[10,0],[14,0,\"alert topic-category-expert-question-banner\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"agc.category_experts_question_banner.new\"],null]],[1,\"\\n    \"],[11,3],[24,0,\"composer-link\"],[24,6,\"\"],[4,[38,2],[[30,0],\"openComposer\"],null],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"agc.category_experts_question_banner.ask_dsp_guide\"],[[\"categoryName\"],[[30,0,[\"topic\",\"category\",\"name\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[1,[28,[35,1],[\"agc.category_experts_question_banner.to_respond\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `show` property path was used in the `discourse/plugins/discourse-global-communities/discourse/templates/components/topic-category-expert-question-banner.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.show}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `topic` property path was used in the `discourse/plugins/discourse-global-communities/discourse/templates/components/topic-category-expert-question-banner.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.topic}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"i18n\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/components/topic-category-expert-question-banner.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});