define("discourse/plugins/discourse-global-communities/discourse/raw-templates/mobile/list/topic-list-item", ["exports", "discourse-common/lib/raw-handlebars", "discourse-common/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let template = (0, _rawHandlebars.template)({
    "1": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "  " + container.escapeExpression((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "list.mobile-topic-title", {
        "name": "raw",
        "hash": {
          "topic": "topic"
        },
        "hashTypes": {
          "topic": "PathExpression"
        },
        "hashContexts": {
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 2,
            "column": 2
          },
          "end": {
            "line": 2,
            "column": 47
          }
        }
      })) + "\n";
    },
    "3": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "  <a href=" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.lastUnreadUrl", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 6,
            "column": 10
          },
          "end": {
            "line": 6,
            "column": 33
          }
        }
      })) + " style=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.thumbnail_aspect_ratio", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 6,
            "column": 41
          },
          "end": {
            "line": 6,
            "column": 73
          }
        }
      })) + " background: url(" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.thumbnail_url", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 6,
            "column": 90
          },
          "end": {
            "line": 6,
            "column": 113
          }
        }
      })) + ")\"\n     class=\"preview-image\">\n  </a>\n";
    },
    "5": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "      " + container.escapeExpression((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "list.mobile-topic-title", {
        "name": "raw",
        "hash": {
          "topic": "topic"
        },
        "hashTypes": {
          "topic": "PathExpression"
        },
        "hashContexts": {
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 13,
            "column": 6
          },
          "end": {
            "line": 13,
            "column": 51
          }
        }
      })) + "\n";
    },
    "7": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "  " + container.escapeExpression((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "topic-list-item-expandable-excerpt", {
        "name": "raw",
        "hash": {
          "topic": "topic"
        },
        "hashTypes": {
          "topic": "PathExpression"
        },
        "hashContexts": {
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 18,
            "column": 2
          },
          "end": {
            "line": 18,
            "column": 58
          }
        }
      })) + "\n";
    },
    "9": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "  " + container.escapeExpression((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "webinar-list-item", {
        "name": "raw",
        "hash": {
          "webinarTime": "webinarTime",
          "registrationOpen": "registrationOpen",
          "isAttendee": "isAttendee",
          "canRegister": "canRegister",
          "webinarStarted": "webinarStarted",
          "webinarEnded": "webinarEnded",
          "webinar": "webinar"
        },
        "hashTypes": {
          "webinarTime": "PathExpression",
          "registrationOpen": "PathExpression",
          "isAttendee": "PathExpression",
          "canRegister": "PathExpression",
          "webinarStarted": "PathExpression",
          "webinarEnded": "PathExpression",
          "webinar": "PathExpression"
        },
        "hashContexts": {
          "webinarTime": depth0,
          "registrationOpen": depth0,
          "isAttendee": depth0,
          "canRegister": depth0,
          "webinarStarted": depth0,
          "webinarEnded": depth0,
          "webinar": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 22,
            "column": 2
          },
          "end": {
            "line": 23,
            "column": 113
          }
        }
      })) + "\n";
    },
    "11": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "  <div class=\"topic-list-featured-posters\">\n" + ((stack1 = lookupProperty(helpers, "each").call(depth0 != null ? depth0 : container.nullContext || {}, "poster", "in", "topic.featuredUsers", {
        "name": "each",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(12, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression", "CommentStatement", "PathExpression"],
        "contexts": [depth0, depth0, depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 28,
            "column": 4
          },
          "end": {
            "line": 37,
            "column": 13
          }
        }
      })) != null ? stack1 : "") + "  </div>\n";
    },
    "12": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return (stack1 = lookupProperty(helpers, "if").call(depth0 != null ? depth0 : container.nullContext || {}, "poster.moreCount", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(13, data, 0),
        "inverse": container.program(15, data, 0),
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 29,
            "column": 6
          },
          "end": {
            "line": 36,
            "column": 13
          }
        }
      })) != null ? stack1 : "";
    },
    "13": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "        <a class=\"posters-more-count\">" + container.escapeExpression(lookupProperty(helpers, "get").call(depth0 != null ? depth0 : container.nullContext || {}, "poster.moreCount", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 30,
            "column": 38
          },
          "end": {
            "line": 30,
            "column": 58
          }
        }
      })) + "</a>\n";
    },
    "15": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "        <a href=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "poster.user.path", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 32,
            "column": 17
          },
          "end": {
            "line": 32,
            "column": 37
          }
        }
      })) + "\" data-user-card=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "poster.user.username", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 32,
            "column": 55
          },
          "end": {
            "line": 32,
            "column": 79
          }
        }
      })) + "\"\n           class=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "poster.extraClasses", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 33,
            "column": 18
          },
          "end": {
            "line": 33,
            "column": 41
          }
        }
      })) + "\">" + alias2((lookupProperty(helpers, "avatar") || depth0 && lookupProperty(depth0, "avatar") || container.hooks.helperMissing).call(alias1, "poster", {
        "name": "avatar",
        "hash": {
          "imageSize": "small",
          "namePath": "user.name",
          "usernamePath": "user.username",
          "avatarTemplatePath": "user.avatar_template"
        },
        "hashTypes": {
          "imageSize": "StringLiteral",
          "namePath": "StringLiteral",
          "usernamePath": "StringLiteral",
          "avatarTemplatePath": "StringLiteral"
        },
        "hashContexts": {
          "imageSize": depth0,
          "namePath": depth0,
          "usernamePath": depth0,
          "avatarTemplatePath": depth0
        },
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 33,
            "column": 43
          },
          "end": {
            "line": 35,
            "column": 71
          }
        }
      })) + "</a>\n";
    },
    "17": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "    <div class=\"tag-wrapper\">\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "topic.expert_post_group_names", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(18, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 44,
            "column": 6
          },
          "end": {
            "line": 46,
            "column": 13
          }
        }
      })) != null ? stack1 : "") + "\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "topic.is_category_expert_question", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(20, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 48,
            "column": 6
          },
          "end": {
            "line": 58,
            "column": 13
          }
        }
      })) != null ? stack1 : "") + "    </div>\n";
    },
    "18": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "        " + container.escapeExpression((lookupProperty(helpers, "category-expert-topic-list-pills") || depth0 && lookupProperty(depth0, "category-expert-topic-list-pills") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "topic", "amazonianGroupName", {
        "name": "category-expert-topic-list-pills",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression", "PathExpression"],
        "contexts": [depth0, depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 45,
            "column": 8
          },
          "end": {
            "line": 45,
            "column": 69
          }
        }
      })) + "\n";
    },
    "20": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "        " + container.escapeExpression((lookupProperty(helpers, "agc-category-expert-question-indicator") || depth0 && lookupProperty(depth0, "agc-category-expert-question-indicator") || container.hooks.helperMissing).call(alias1, "topic", "currentUser", {
        "name": "agc-category-expert-question-indicator",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression", "PathExpression"],
        "contexts": [depth0, depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 49,
            "column": 8
          },
          "end": {
            "line": 49,
            "column": 68
          }
        }
      })) + "\n\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "currentUser.staff", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(21, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 51,
            "column": 8
          },
          "end": {
            "line": 57,
            "column": 15
          }
        }
      })) != null ? stack1 : "");
    },
    "21": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return (stack1 = lookupProperty(helpers, "if").call(depth0 != null ? depth0 : container.nullContext || {}, "topic.needs_category_expert_post_approval", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(22, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 52,
            "column": 10
          },
          "end": {
            "line": 56,
            "column": 17
          }
        }
      })) != null ? stack1 : "";
    },
    "22": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "            <a href=\"/search?q=with:unapproved_ce_post\" class=\"topic-list-category-expert-needs-approval\">\n              " + container.escapeExpression((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "category_experts.topic_list.needs_approval", {
        "name": "i18n",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 54,
            "column": 14
          },
          "end": {
            "line": 54,
            "column": 67
          }
        }
      })) + "\n            </a>\n";
    },
    "24": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return (stack1 = lookupProperty(helpers, "if").call(depth0 != null ? depth0 : container.nullContext || {}, "tagsOrContentType", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(25, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 60,
            "column": 2
          },
          "end": {
            "line": 71,
            "column": 2
          }
        }
      })) != null ? stack1 : "";
    },
    "25": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "    <div class=\"tag-wrapper\">\n      <span class=\"tag-title\">" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, "agc.topic-list.tags", {
        "name": "i18n",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 62,
            "column": 30
          },
          "end": {
            "line": 62,
            "column": 60
          }
        }
      })) + "</span>\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "topic.gc_content_type", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(26, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 63,
            "column": 6
          },
          "end": {
            "line": 67,
            "column": 13
          }
        }
      })) != null ? stack1 : "") + "\n      " + alias3((lookupProperty(helpers, "discourse-tags") || depth0 && lookupProperty(depth0, "discourse-tags") || alias2).call(alias1, "topic", {
        "name": "discourse-tags",
        "hash": {
          "mode": "list"
        },
        "hashTypes": {
          "mode": "StringLiteral"
        },
        "hashContexts": {
          "mode": depth0
        },
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 69,
            "column": 6
          },
          "end": {
            "line": 69,
            "column": 42
          }
        }
      })) + "\n    </div>\n  ";
    },
    "26": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "        <div class=\"gc-content-type\">\n          <a href=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "contentTypeSearchLink", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 65,
            "column": 19
          },
          "end": {
            "line": 65,
            "column": 44
          }
        }
      })) + "\">" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.gc_content_type", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 65,
            "column": 46
          },
          "end": {
            "line": 65,
            "column": 71
          }
        }
      })) + "</a>\n        </div>\n";
    },
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return ((stack1 = lookupProperty(helpers, "if").call(alias1, "titleAboveByline", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(1, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 7
          }
        }
      })) != null ? stack1 : "") + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "list.gc-byline", {
        "name": "raw",
        "hash": {
          "relativeCreatedAt": "relativeCreatedAt",
          "avatarFlair": "avatarFlair",
          "topic": "topic"
        },
        "hashTypes": {
          "relativeCreatedAt": "PathExpression",
          "avatarFlair": "PathExpression",
          "topic": "PathExpression"
        },
        "hashContexts": {
          "relativeCreatedAt": depth0,
          "avatarFlair": depth0,
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 4,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 96
          }
        }
      })) + "\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "topic.thumbnail_url", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(3, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 5,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 7
          }
        }
      })) != null ? stack1 : "") + "<div class='main-link'>\n  <div class='top-row'>\n" + ((stack1 = lookupProperty(helpers, "unless").call(alias1, "titleAboveByline", {
        "name": "unless",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(5, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 12,
            "column": 4
          },
          "end": {
            "line": 14,
            "column": 15
          }
        }
      })) != null ? stack1 : "") + "  </div>\n</div>\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "topic.escapedExcerpt", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(7, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 17,
            "column": 0
          },
          "end": {
            "line": 19,
            "column": 7
          }
        }
      })) != null ? stack1 : "") + "\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "webinar", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(9, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 21,
            "column": 0
          },
          "end": {
            "line": 24,
            "column": 7
          }
        }
      })) != null ? stack1 : "") + "\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "showAGCPosters", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(11, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 26,
            "column": 0
          },
          "end": {
            "line": 39,
            "column": 7
          }
        }
      })) != null ? stack1 : "") + "\n<div class=\"topic-meta\">\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "displayCategoryExpertFields", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(17, data, 0),
        "inverse": container.program(24, data, 0),
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 42,
            "column": 2
          },
          "end": {
            "line": 71,
            "column": 9
          }
        }
      })) != null ? stack1 : "") + "\n  " + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "agc-topic-list-actions", {
        "name": "raw",
        "hash": {
          "topic": "topic"
        },
        "hashTypes": {
          "topic": "PathExpression"
        },
        "hashContexts": {
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 73,
            "column": 2
          },
          "end": {
            "line": 73,
            "column": 46
          }
        }
      })) + "\n</div>\n";
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("javascripts/mobile/list/topic-list-item", template, {
    core: true
  });
  var _default = _exports.default = template;
});