define("discourse/plugins/discourse-global-communities/discourse/adapters/admin-program-pillar", ["exports", "discourse/adapters/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminProgramPillarAdapter extends _rest.default {
    basePath() {
      return "/admin/plugins/agc/";
    }
    apiNameFor() {
      return "program-pillar";
    }
  }
  _exports.default = AdminProgramPillarAdapter;
});