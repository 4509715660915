define("discourse/plugins/discourse-global-communities/discourse/lib/idf-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getEventEnd = getEventEnd;
  _exports.getEventStart = getEventStart;
  _exports.isEventActive = isEventActive;
  _exports.isEventNext = isEventNext;
  _exports.isEventPrev = isEventPrev;
  _exports.prePostPeriod = prePostPeriod;
  function prePostPeriod(event) {
    const minutes = parseInt(event.custom_fields.idf_pre_post_event_period_mins, 10) || 0;
    return minutes * 60 * 1000;
  }
  function getEventStart(event) {
    return +new Date(event.starts_at) - prePostPeriod(event);
  }
  function getEventEnd(event) {
    return +new Date(event.ends_at) + prePostPeriod(event);
  }
  function isEventPrev(event) {
    const now = +new Date();
    return getEventEnd(event) < now;
  }
  function isEventNext(event) {
    const now = +new Date();
    return now < getEventStart(event);
  }
  function isEventActive(event) {
    const now = +new Date();
    return getEventStart(event) <= now && now <= getEventEnd(event);
  }
});