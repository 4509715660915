define("discourse/plugins/discourse-global-communities/discourse/components/circles/logo-with-letter-fallback", ["exports", "discourse/plugins/discourse-global-communities/discourse/components/gc-circle-letter-logo", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _gcCircleLetterLogo, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#if @outletArgs.model.hasLogo}}
      {{yield}}
    {{else}}
      <GcCircleLetterLogo @model={{@outletArgs.model}} />
    {{/if}}
  
  */
  {
    "id": "rZNLmjNN",
    "block": "[[[1,\"\\n\"],[41,[30,1,[\"model\",\"hasLogo\"]],[[[1,\"    \"],[18,2,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[32,0],null,[[\"@model\"],[[30,1,[\"model\"]]]],null],[1,\"\\n\"]],[]]]],[\"@outletArgs\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/circles/logo-with-letter-fallback.js",
    "scope": () => [_gcCircleLetterLogo.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "logo-with-letter-fallback"));
});