define("discourse/plugins/discourse-global-communities/discourse/components/idf-timer", ["exports", "@ember/component", "@ember/runloop"], function (_exports, _component, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: "",
    delay: 950,
    didInsertElement() {
      this._super(...arguments);
      this.tick();
    },
    tick() {
      this.set("nextTick", (0, _runloop.later)(this, () => {
        this.tick();
        this.onTick();
      }, this.delay));
    },
    willDestroyElement() {
      this._super(...arguments);
      (0, _runloop.cancel)(this.nextTick);
    }
  });
});