define("discourse/plugins/discourse-global-communities/discourse/initializers/suggested-or-related", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    initialize() {
      (0, _pluginApi.withPluginApi)("1.37.2", api => {
        api.registerValueTransformer("more-topics-tabs", _ref => {
          let {
            value,
            context
          } = _ref;
          const {
            user
          } = context;
          if (!user?.agcFeatureFlags?.agc_related_suggested_topics_enabled) {
            return value;
          } else if (user.agcFeatureFlags?.agc_related_topics_enabled) {
            // Remove "suggested" topics
            return value.filter(tab => tab.id !== "suggested-topics");
          } else {
            // Remove "related" topics
            return value.filter(tab => tab.id !== "related-topics");
          }
        });
      });
    }
  };
});